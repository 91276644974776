import React from 'react';
import Icon from '../../Icon';

import {
  CheckBoxWrapper,
  StyledCheckBox,
  CheckBoxLabel,
  CircleBox,
  CircleWrapper,
  Overlay
} from './style';
const CheckBox = ({
  name,
  disabled,
  value,
  label,
  isChecked = false,
  style = null,
  ...restProps
}) => {
  return (
    <CheckBoxWrapper>
      <input
        {...restProps}
        type="checkbox"
        style={{
          display: 'none'
        }}
        id={value}
        name={name}
        value={value}
        checked={isChecked}
        disabled={disabled}
      />
      <StyledCheckBox htmlFor={!disabled ? value : ''}>
        <CircleWrapper className="circle_out">
          {!style && <Overlay className="overlay" />}
          <CircleBox style={style || {}} checked={isChecked}>
            {isChecked && <Icon data-value={value} name="fas fa-check" />}
          </CircleBox>
        </CircleWrapper>

        <CheckBoxLabel>{label}</CheckBoxLabel>
      </StyledCheckBox>
    </CheckBoxWrapper>
  );
};
export default CheckBox;
