import React, { useRef } from 'react';
import Icon from '../../Icon';

import styles from './Fallback.module.css';

const ChildFallback = () => {
  return (
    <div className={`${styles.child_fall_cont}`}>
      <Icon
        className={styles.error_icon_s}
        name="fas fa-exclamation-triangle"
      />
      <span className={styles.error_text_child}>Something went wrong !!!</span>
    </div>
  );
};

export default ChildFallback;
