import {
  CREATE_STUDENT,
  FETCH_STUDENTS,
  SEARCH_STUDENTS,
  UPDATE_STUDENT
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate
} from '../../Helper/reducer';

const initialState = createInitialStoreState({
  currentSearchBy: '',
  currentSearchValue: '',
  totalRegistrations: null,
  prevData: {}
});

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_STUDENTS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${SEARCH_STUDENTS}_SUCCESS`: {
      const { dataIds, data, relation, paginationData } = payload;

      let prevData = {};
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store };
      } else {
        prevData = { ...store.prevData };
        prevData.prevData = {};
      }

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
        prevData
      };
    }

    case 'RESET_TO_PREV_DATA': {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        currentSearchBy: '',
        currentSearchValue: ''
      };
    }

    case 'UPDATE_SEARCH_PARAMS': {
      const { searchBy, searchValue } = payload;

      return {
        ...store,
        currentSearchBy: searchBy,
        currentSearchValue: searchValue
      };
    }

    case `${UPDATE_STUDENT}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${CREATE_STUDENT}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    default:
      return store;
  }
};

export default view;
