import {
  FETCH_SUBSCRIPTION_DETAILS,
  RESET_TO_SUBSCRIPTION_DETAILS_PREV_DATA,
  SEARCH_SUBSCRIPTION_DETAILS,
  UPDATE_SUBSCRIPTION_DETAILS_SEARCH_PARAMS
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState();

const view = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_SUBSCRIPTION_DETAILS}_SUCCESS`:
    case `${SEARCH_SUBSCRIPTION_DETAILS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case UPDATE_SUBSCRIPTION_DETAILS_SEARCH_PARAMS: {
      const { fromDate, toDate, modeOfPayment } = payload;

      return {
        ...store,
        onceFetched: false,
        currentFromDate: fromDate,
        currentToDate: toDate,
        currentModeOfPayment: modeOfPayment
      };
    }

    case RESET_TO_SUBSCRIPTION_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentFromDate: '',
        currentToDate: '',
        currentModeOfPayment: ''
      };
    }

    default:
      return store;
  }
};
export default view;
