import ActionController from '../ActionController';

const initiateSuccessAction = (dispatch, actionType, payload) => {
  ActionController.removeAction(actionType);
  dispatch({ type: `${actionType}_SUCCESS`, payload });
};

const initiateCancelAction = (dispatch, actionType) => {
  dispatch({ type: `${actionType}_CANCELLED` });
};

const initiateFailedAction = (dispatch, actionType, error) => {
  ActionController.failAction(actionType, error);
  dispatch({ type: `${actionType}_FAILED`, ...error });
};

const initiateAction = (dispatch, actionType, payload) => {
  ActionController.addAction(actionType, payload);
  dispatch({ type: actionType, payload });
};

const GeneratorMiddleware = (store) => (next) => (actionCreator) => {
  const isFunction = typeof actionCreator === 'function';

  if (!isFunction) {
    return next(actionCreator);
  }

  const promiseFunction = (dispatch) => {
    const promise = new Promise(async (resolve, reject) => {
      const payloads = [];
      const errors = [];

      let prevData = {};

      const iterator = actionCreator();
      while (true) {
        const { done, value } = iterator.next(prevData);

        // checking if iterator is finished or not
        if (done) {
          break;
        }

        const {
          action,
          sideEffect,
          payload = {},
          track = true // used for automatically dispatching success action
        } = value;

        if (typeof action !== 'string') {
          throw new Error('No action type is defined');
        }

        initiateAction(dispatch, action, { ...payload });

        const isPromise =
          Object.prototype.toString.call(sideEffect) === '[object Promise]';

        if (isPromise) {
          try {
            const result = await sideEffect;
            const mergedResult = { ...result, ...payload };

            const isCancelled = ActionController.isActionCancelled(action);

            if (isCancelled) {
              initiateCancelAction(dispatch, action);
            } else {
              payloads.push({ ...result });
            }

            prevData = { ...result, error: false };

            if (track && !isCancelled) {
              initiateSuccessAction(dispatch, action, mergedResult);
            }
          } catch (err) {
            errors.push(err);
            initiateFailedAction(dispatch, action, { error: err });
            prevData = { result: null, error: err };
          }
        }
      }

      if (errors.length > 0) {
        reject(errors);
        return;
      }

      resolve(payloads);
    });

    return promise;
  };

  return next(promiseFunction);
};

export default GeneratorMiddleware;
