export const getHowManyMore = (count) => {
  if (count < 1000) {
    return `+${count}`;
  } else if (count >= 1000 && count <= 100000) {
    return `+${Math.floor(count / 1000)}K`;
  } else {
    return `+${Math.floor(count / 100000)}L`;
  }
};

export const removeSpaceFromString = (value) => {
  return value ? value.split(' ').join('') : value;
};
