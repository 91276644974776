import React from 'react';

import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
const liveProjectsRuote = Routes.authorized.liveProjects;

const tabLinks = Object.keys(liveProjectsRuote.routes).map(
  (key) => liveProjectsRuote.routes[key].path
);

const LinkComponents = Object.keys(liveProjectsRuote.routes).map((key) => {
  const { path, drawerTitle, accessType } = liveProjectsRuote.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const LiveProjectsTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={liveProjectsRuote.accessType}>
      <DrawerTitle title="Live Projects" />
      <DrawerTab
        icon="fas fa-project-diagram"
        title="Live Project"
        links={tabLinks}>
        {LinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default LiveProjectsTab;
