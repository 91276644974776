import React from 'react';

import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
const courseRuote = Routes.authorized.courseManagement;

const tabLinks = Object.keys(courseRuote.routes).map(
  (key) => courseRuote.routes[key].path
);

const LinkComponents = Object.keys(courseRuote.routes).map((key) => {
  const { path, drawerTitle, accessType } = courseRuote.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const courseLevelRoute = Routes.authorized.courseLevel;

const courseLevelTabLinks = Object.keys(courseLevelRoute.routes).map(
  (key) => courseLevelRoute.routes[key].path
);

const CourseLevelLinkComponents = Object.keys(courseLevelRoute.routes).map(
  (key) => {
    const { path, drawerTitle, accessType } = courseLevelRoute.routes[key];
    return (
      <DrawerLink
        key={path}
        path={path}
        title={drawerTitle}
        requiredAccess={accessType}
      />
    );
  }
);

const workshopRoute = Routes.authorized.workshopManagement;

const workshopTabLinks = Object.keys(workshopRoute.routes).map(
  (key) => workshopRoute.routes[key].path
);

const WorkshopLinkComponents = Object.keys(workshopRoute.routes).map((key) => {
  const { path, drawerTitle, accessType } = workshopRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const webinarRoute = Routes.authorized.webinarManagement;

const webinarTabLinks = Object.keys(webinarRoute.routes).map(
  (key) => webinarRoute.routes[key].path
);

const WebinarLinkComponents = Object.keys(webinarRoute.routes).map((key) => {
  const { path, drawerTitle, accessType } = webinarRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const liveProjectsRuote = Routes.authorized.liveProjects;

const liveProjectsTabLinks = Object.keys(liveProjectsRuote.routes).map(
  (key) => liveProjectsRuote.routes[key].path
);

const LiveProjectsLinkComponents = Object.keys(liveProjectsRuote.routes).map(
  (key) => {
    const { path, drawerTitle, accessType } = liveProjectsRuote.routes[key];
    return (
      <DrawerLink
        key={path}
        path={path}
        title={drawerTitle}
        requiredAccess={accessType}
      />
    );
  }
);

const CourseManagementTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={courseRuote.accessType}>
      <DrawerTitle title="Courses and Workshops" />
      <DrawerTab icon="fas fa-book" title="Course" links={tabLinks}>
        {LinkComponents}
      </DrawerTab>
      <DrawerTab
        icon="fas fa-chart-bar"
        title="Course Level"
        links={courseLevelTabLinks}>
        {CourseLevelLinkComponents}
      </DrawerTab>
      <DrawerTab icon="fas fa-user" title="Workshop" links={workshopTabLinks}>
        {WorkshopLinkComponents}
      </DrawerTab>
      <DrawerTab icon="fas fa-user" title="Webinar" links={webinarTabLinks}>
        {WebinarLinkComponents}
      </DrawerTab>
      <DrawerTab
        icon="fas fa-project-diagram"
        title="Live Project"
        links={liveProjectsTabLinks}>
        {LiveProjectsLinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default CourseManagementTab;
