export const createNewStoreOnGetData = (
  payload,
  store,
  syncPrevData = false
) => {
  let { allData, allIds, allRelation } = store;
  const { dataIds, data, relation, paginationData } = payload;

  if (syncPrevData && allData && allIds && allRelation) {
    allData = { ...allData, ...data };
    allIds = [...allIds, ...dataIds];
    allRelation = { ...allRelation, ...relation };
  }

  return {
    ...store,
    dataIds,
    data,
    relation,
    paginationData,
    onceFetched: true,
    ...(syncPrevData && allData && allIds && allRelation
      ? {
          allData,
          allIds,
          allRelation
        }
      : {})
  };
};

export const createNewStoreOnCreate = (
  payload,
  store,
  syncPrevData = false,
  appendNewDataToTop = false
) => {
  const { data, dataIds, relation } = payload;
  let { allData, allIds, allRelation } = store;

  if (syncPrevData && allData && allIds && allRelation) {
    allData = { ...allData, ...data };
    allIds = appendNewDataToTop
      ? [...dataIds, ...allIds]
      : [...allIds, ...dataIds];
    allRelation = { ...allRelation, ...relation };
  }

  return {
    ...store,
    data: { ...store.data, ...data },
    dataIds: appendNewDataToTop
      ? [...dataIds, ...store.dataIds]
      : [...store.dataIds, ...dataIds],
    relation: { ...store.relation, ...relation },
    ...(syncPrevData && allData && allIds && allRelation
      ? {
          allData,
          allIds,
          allRelation
        }
      : {})
  };
};

export const createNewStoreOnUpdate = (
  payload,
  store,
  syncPrevData = false
) => {
  const { data, relation } = payload;
  let { allData, allIds, allRelation } = store;

  if (syncPrevData && allData && allIds && allRelation) {
    allData = { ...allData, ...data };
    allRelation = { ...allRelation, ...relation };
  }

  return {
    ...store,
    data: { ...store.data, ...data },
    relation: { ...store.relation, ...relation },
    ...(syncPrevData && allData && allIds && allRelation
      ? {
          allData,
          allIds,
          allRelation
        }
      : {})
  };
};

export const createInitialStoreState = (
  props,
  shouldContainAllFields = false
) => {
  return {
    dataIds: [],
    data: {},
    relation: {},
    onceFetched: false,
    paginationData: {
      hasNextPage: true,
      hasPrevPage: null,
      prevPageNumber: -1,
      currentPage: 1,
      nextPageNumber: -1,
      currentPageSize: 0,
      totalPages: 1
    },
    ...(shouldContainAllFields
      ? {
          allData: {},
          allIds: [],
          allRelation: {}
        }
      : {}),
    ...props
  };
};

export const getTableName = (store, tableName) => {
  if (!tableName) {
    return store;
  }
  const names = tableName.split('.');
  let currStore = store;

  names.forEach((name) => {
    if (!currStore[name]) {
      throw new Error('table name not present in reducer');
    }
    currStore = currStore[name];
  });
  return currStore;
};

export const updateDataOnDelete = (store, id) => {
  let { data, dataIds } = store;

  const newData = { ...data };
  const newDataIds = [...dataIds];

  delete newData[id];

  const indexOfId = newDataIds.indexOf(id);
  newDataIds.splice(indexOfId, 1);

  return {
    ...store,
    data: { ...newData },
    dataIds: [...newDataIds]
  };
};
