import React from 'react';
import { useSelector } from 'react-redux';
import useDrawer from '../../Hooks/useDrawer';
import Button from '../Button';
import Icon from '../Icon';
import UserDropDown from './UserDropDown';
import { getChannelPartnerName } from '../../Selectors/auth';
import styles from './Navbar.module.css';
import Image from '../Image';
import EDUCATION_LOGO from '../../Assets/educationLogo.png';
import Tooltip from '../Tooltip';

const Navbar = () => {
  const { openDrawer, closeDrawer, isDrawerActive } = useDrawer();
  const channelPartnerName = useSelector(getChannelPartnerName);
  return (
    <div className={styles.container}>
      <Button
        className={styles.toggle_btn}
        size="auto"
        onClick={isDrawerActive ? closeDrawer : openDrawer}>
        <Icon name="fas fa-bars" />
      </Button>
      <Image src={EDUCATION_LOGO} className={styles.logoImage} />
      {channelPartnerName ? (
        <span style={{ marginLeft: '10px' }}>
          <Icon name="fa fa-close" />
        </span>
      ) : (
        ''
      )}
      <Tooltip text={`${channelPartnerName}`}>
        <span className={styles.tooltip_text}>{channelPartnerName}</span>
      </Tooltip>
      <UserDropDown />
    </div>
  );
};

export default Navbar;
