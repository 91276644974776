import { combineReducers } from 'redux';
import interviews from './View';
import employers from './Employer';
import studentSubscriptions from './StudentSubscription';
import Questions from './InterviewQuestions';

const studentInterview = combineReducers({
  interview: interviews,
  interviewQuestions: Questions,
  employer: employers,
  studentSubscription: studentSubscriptions
});

export default studentInterview;
