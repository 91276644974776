import {
  FETCH_SUBSCRIBED_STUDENTS,
  UPDATE_SUBSCRIBED_STUDENT_SEARCH_PARAMS,
  RESET_PREVIOUS_SUBSCRIBED_STUDENT_DATA,
  SEARCH_SUBSCRIBED_STUDENT
} from '../../Actions/subscribedStudents';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState({
  totalCount: 0,
  searchParams: null
});

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_SUBSCRIBED_STUDENT}_SUCCESS`:
    case `${FETCH_SUBSCRIBED_STUDENTS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, {
        ...store,
        totalCount: payload.meta?.totalCount || 0
      });
    }

    case UPDATE_SUBSCRIBED_STUDENT_SEARCH_PARAMS: {
      const { searchParams } = payload;

      return {
        ...store,
        onceFetched: false,
        searchParams: { ...searchParams }
      };
    }

    case RESET_PREVIOUS_SUBSCRIBED_STUDENT_DATA: {
      return {
        ...store,
        onceFetched: false,
        searchParams: null
      };
    }

    default:
      return store;
  }
};

export default view;
