const getMyPath = function () {};

const Routes = {
  unAuthorized: {
    loginRoute: {
      path: '/'
    }
  },

  authorized: {
    userManagement: {
      accessType: ['super_admin', 'channel_partner'],
      routes: {
        viewRoute: {
          path: '/user_view',
          accessType: ['super_admin', 'channel_partner'],
          drawerTitle: 'View'
        }
      }
    },
    mail: {
      accessType: ['super_admin'],
      routes: {
        createRoute: {
          path: '/mail_create',
          accessType: ['super_admin'],
          drawerTitle: 'Create Mail'
        },
        editRoute: {
          path: '/mail_edit',
          accessType: ['super_admin'],
          drawerTitle: 'Edit Email'
        },
        sendRoute: {
          path: '/send_email',
          accessType: ['super_admin'],
          drawerTitle: 'Send Email'
        },
        scheduleRoute: {
          path: '/schedule_email',
          accessType: ['super_admin'],
          drawerTitle: 'Schedule Email'
        },
        viewRoute: {
          path: '/view_reports',
          accessType: ['super_admin'],
          drawerTitle: 'View Reports'
        }
      }
    },
    courseManagement: {
      accessType: ['super_admin'],
      routes: {
        createRoute: {
          path: '/course_create',
          accessType: ['super_admin'],
          drawerTitle: 'Create'
        },
        viewRoute: {
          path: '/course_view',
          accessType: ['super_admin'],
          drawerTitle: 'View'
        }
      }
    },

    employerManagement: {
      accessType: ['super_admin', 'admin', 'academic_officer'],
      routes: {
        viewRoute: {
          path: '/employer_view',
          accessType: ['super_admin', 'admin', 'academic_officer'],
          drawerTitle: 'View'
        }
      }
    },

    studentManagement: {
      accessType: [
        'super_admin',
        'admin',
        'academic_officer',
        'channel_partner'
      ],
      routes: {
        createRoute: {
          path: '/student_create',
          accessType: [
            'super_admin',
            'admin',
            'academic_officer',
            'channel_partner'
          ],
          drawerTitle: 'Create'
        },
        viewRoute: {
          path: '/student_view',
          accessType: [
            'super_admin',
            'admin',
            'academic_officer',
            'channel_partner'
          ],
          drawerTitle: 'View'
        },
        subscribedRoute: {
          path: '/subscribed_students_view',
          accessType: ['super_admin', 'admin', 'academic_officer'],
          drawerTitle: 'Subscribed Students'
        },
        subscribedDetailRoute: {
          path: '/subscribed_students_view',
          slug: '/:studentId',
          accessType: ['super_admin', 'admin', 'academic_officer'],
          drawerTitle: null
        },
        subscribedMemorendumCreateRoute: {
          path: '/subscribed_students_view/:studentId/subscription/:subscriptionId/memorendum/create',
          accessType: ['super_admin', 'admin', 'academic_officer'],
          drawerTitle: null,
          createPath: (studentId, subscriptionId) =>
            `/subscribed_students_view/${studentId}/subscription/${subscriptionId}/memorendum/create`
        },
        subscribedMemorendumEditRoute: {
          path: '/subscribed_students_view/:studentId/memorendum/:memoId',
          accessType: ['super_admin', 'admin', 'academic_officer'],
          drawerTitle: null,
          createPath: (studentId, memoId) =>
            `/subscribed_students_view/${studentId}/memorendum/${memoId}`
        }
      }
    },

    studentSubscriptionManagement: {
      accessType: ['super_admin', 'admin', 'academic_officer'],
      routes: {
        createRoute: {
          path: '/student_subscription_create',
          accessType: ['super_admin', 'admin', 'academic_officer'],
          drawerTitle: 'Create'
        },
        viewRoute: {
          path: '/student_subscription_view',
          accessType: ['super_admin', 'admin', 'academic_officer'],
          drawerTitle: 'View'
        }
      }
    },

    studentSubscriptionDetailsManagement: {
      accessType: ['super_admin', 'admin', 'academic_officer'],
      routes: {
        viewRoute: {
          path: '/student_subscription_details_view',
          accessType: ['super_admin', 'admin', 'academic_officer'],
          drawerTitle: 'View'
        }
      }
    },

    workshopManagement: {
      accessType: ['super_admin'],
      routes: {
        searchByRoute: {
          path: '/workshop_search',
          accessType: ['super_admin'],
          drawerTitle: 'Search By'
        },
        createRoute: {
          path: '/workshop_create',
          accessType: ['super_admin'],
          drawerTitle: 'Create'
        },
        activeRoute: {
          path: '/workshop_active',
          accessType: ['super_admin'],
          drawerTitle: 'Active'
        },
        inactiveRoute: {
          path: '/workshop_inactive',
          accessType: ['super_admin'],
          drawerTitle: 'Inactive'
        }
      }
    },

    webinarManagement: {
      accessType: ['super_admin'],
      routes: {
        viewRoute: {
          path: '/webinar_view',
          accessType: ['super_admin'],
          drawerTitle: 'View'
        }
      }
    },

    liveProjects: {
      accessType: ['super_admin', 'admin'],
      routes: {
        createRoute: {
          path: '/live_project_create',
          accessType: ['super_admin', 'admin'],
          drawerTitle: 'Create'
        },
        viewRoute: {
          path: '/live_project_view',
          accessType: ['super_admin', 'admin'],
          drawerTitle: 'View'
        }
      }
    },
    courseLevel: {
      accessType: ['super_admin', 'admin'],
      routes: {
        createRoute: {
          path: '/course_level_create',
          accessType: ['super_admin', 'admin'],
          drawerTitle: 'Create'
        },
        viewRoute: {
          path: '/course_level_view',
          accessType: ['super_admin', 'admin'],
          drawerTitle: 'View'
        }
      }
    },

    companyMentorManagement: {
      accessType: ['super_admin', 'admin', 'channel_partner'],
      routes: {
        viewRoute: {
          path: '/companyMentors_view',
          accessType: ['super_admin', 'admin', 'channel_partner'],
          drawerTitle: 'View'
        }
      }
    },

    support: {
      accessType: ['admin', 'super_admin'],
      routes: {
        enquiryRoute: {
          path: '/support_enquiry',
          accessType: ['admin', 'super_admin'],
          drawerTitle: 'Enquiry'
        },

        callbackRequestRoute: {
          path: '/callback_request_view',
          accessType: ['super_admin', 'admin'],
          drawerTitle: 'Callback Request'
        },

        syllabusRoute: {
          path: '/support_syllabus',
          accessType: ['admin', 'super_admin'],
          drawerTitle: 'Syllabus'
        }
      }
    },

    batch: {
      accessType: ['admin', 'super_admin', 'channel_partner'],
      routes: {
        viewRoute: {
          path: '/batch_view',
          accessType: ['super_admin', 'admin', 'channel_partner'],
          drawerTitle: 'View'
        }
      }
    },

    channelPartner: {
      accessType: ['admin', 'super_admin'],
      routes: {
        viewRoute: {
          path: '/channel_partner_view',
          accessType: ['super_admin', 'admin'],
          drawerTitle: 'View'
        }
      }
    }
  }
};

export default Routes;
