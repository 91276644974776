import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { USER_LOCAL_DATA } from '../Constants';

export const sendExceptionToSentry = (err, tags) => {
  const userRawData = window.sessionStorage.getItem(USER_LOCAL_DATA);
  const userData = userRawData
    ? JSON.parse(userRawData)
    : { name: 'No data found in session storage' };

  Sentry.withScope(function (scope) {
    debugger;
    scope.setContext('userData', userData);
    if (tags) {
      for (const key in tags) {
        scope.setTag(key, JSON.stringify(tags[key]));
      }
    }
    Sentry.captureException(err);
  });
};

export const initializeSentry = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  const logSentryInProd = window.localStorage.getItem('logger');
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    debug: !!logSentryInProd
  });
};
