export const zIndex = {
  backdrop: '3000',
  drawer: '4000',
  navbar: '5000',
  modal: '6000',
  alert: '10000',
  popoverBackdrop: '18000',
  popover: '20000',
  overlay: '1000'
};
