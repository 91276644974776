import React from 'react';

import useInput from '../useInput';

import styles from './RadioGroup.module.css';
import Radio from './Radio';

const RadioGroup = ({
  horizontal = true,
  options = [],
  groupName,
  ...otherprops
}) => {
  const { restInputProps } = useInput(otherprops);

  const { value, ...remainingProps } = restInputProps;
  return (
    <div
      style={{
        flexDirection: horizontal ? 'row' : 'column',
        alignItems: horizontal ? 'center' : 'flex-start'
      }}
      className={styles.container}>
      {options.map((item) => {
        return (
          <Radio
            key={item.value}
            name={groupName}
            value={item.value}
            label={item.name}
            disabled={item.disabled || false}
            checked={value === item.value}
            {...remainingProps}
          />
        );
      })}
    </div>
  );
};

export default RadioGroup;
