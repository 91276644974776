import { GET_ALL_WORKSHOP } from '../../../Actions';
import {
  createNewStoreOnGetData,
  createInitialStoreState
} from '../../../Helper/reducer';

const initialState = createInitialStoreState({}, true);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_ALL_WORKSHOP}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    default:
      return store;
  }
};
export default view;
