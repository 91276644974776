export const CREATE_COMAPNY_MENTOR = 'CREATE_COMAPNY_MENTOR';
export const UPDATE_COMAPNY_MENTOR = 'UPDATE_COMAPNY_MENTOR';
export const FETCH_COMAPNY_MENTORS = 'FETCH_COMAPNY_MENTORS';
export const SEARCH_COMPANY_MENTORS = 'SEARCH_COMPANY_MENTORS';
export const RESET_PREV_COMPANY_MENTORS_DATA =
  'RESET_PREV_COMPANY_MENTORS_DATA';
export const UPDATE_COMPANY_MENTOR_SEARCH_PARAMS =
  'UPDATE_COMPANY_MENTOR_SEARCH_PARAMS';

export const GET_MENTOR_BATCHES = 'GET_MENTOR_BATCHES';
export const RESET_MENTOR_DETAILS = 'RESET_MENTOR_DETAILS';
