import React, { useMemo } from 'react';
import ActivityIndicator from '../../ActivityIndicator';

import EmptyResult from './EmptyResult';
import { ListGroupContainer, LoadingContainer, Button } from './style';

const ListGroup = React.forwardRef((props, ref) => {
  const {
    data = [],
    selectedData = {},
    onChange,
    isInBottom,
    isLoading,
    optionChildren
  } = props;
  const list = useMemo(() => {
    return data.map((item) => {
      return (
        <Button
          type="button"
          data-value={item.id}
          onClick={() => onChange(item.id, item)}
          key={item.id}>
          {optionChildren ? optionChildren({ ...item }) : item.name}
        </Button>
      );
    });
  }, [data, selectedData]);

  return (
    <ListGroupContainer isInBottom={isInBottom} ref={ref}>
      {isLoading && (
        <LoadingContainer>
          <ActivityIndicator size="s" color="#3f6ad8" />
        </LoadingContainer>
      )}
      {!isLoading && list.length === 0 && <EmptyResult />}
      {!isLoading && list}
    </ListGroupContainer>
  );
});

export default ListGroup;
