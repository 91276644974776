import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import ListGroup from './ListGroup';
import MultiSelectListGroup from './ListGroup2';

import useInput from '../useInput';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { createOuterClickListner } from '../../../Helper';

import { InputContainer, InputIcon, GlobalInput } from '../style';

const SearchSelect = ({
  size = 'md',
  success = '',
  secondary = false,
  error = '',
  data = [],
  placeholder,
  value,
  onChange,
  onSearch,
  optionChildren,
  searchValue,
  singleSelect = false,
  isLoading = false,
  emptySearchInput,
  onEnd = null,
  useMultiSelectGroup = false,
  isDataListOpenInitially = false,
  showListOnFocus = false,
  alwaysOpen = false,
  showSelectAll = false,
  ...otherprops
}) => {
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error
  });
  success = success ? 'true' : undefined;
  error = error ? 'true' : undefined;

  const { dimesnions } = useWindowDimensions();
  const [isListOpen, updateListStatus] = useState(
    alwaysOpen || isDataListOpenInitially
  );
  const [isInBottom, toggleBottomPosition] = useState(true);
  const removeClickListnerRef = useRef(null);
  const popupRef = useRef(null);
  const popupBtnRef = useRef(null);

  const toggleList = useCallback(
    (status) => {
      if (alwaysOpen) {
        return;
      }
      updateListStatus((val) => {
        if (typeof status === 'boolean') {
          return status;
        }
        return !val;
      });
    },
    [alwaysOpen]
  );

  const onClickOutside = useCallback(
    (e) => {
      if (
        e.target !== popupBtnRef.current &&
        !popupRef.current.contains(e.target)
      ) {
        toggleList(false);
        if (!singleSelect && typeof emptySearchInput === 'function') {
          emptySearchInput();
        }
      }
    },
    [emptySearchInput, singleSelect]
  );

  useEffect(() => {
    if (showListOnFocus) {
      return;
    }

    if (searchValue) {
      toggleList(true);
    } else {
      toggleList(false);
    }
  }, [searchValue, showListOnFocus]);

  useEffect(() => {
    if (isDataListOpenInitially) {
      toggleList(true);
    }
  }, []);

  const updatePosition = useCallback(() => {
    if (!popupRef.current) {
      return;
    }
    const { height } = popupRef.current.getBoundingClientRect();
    const {
      top,
      height: inputHeight
    } = popupBtnRef.current.getBoundingClientRect();
    if (top + inputHeight + height + 5 > dimesnions.height) {
      toggleBottomPosition(false);
    } else {
      toggleBottomPosition(true);
    }
  }, []);

  const setPopupRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    popupRef.current = ref;
    updatePosition();
  }, []);

  useEffect(() => {
    updatePosition();
  }, [dimesnions, data]);

  // setting outer listner when list gets open
  useEffect(() => {
    if (!isListOpen && removeClickListnerRef.current) {
      removeClickListnerRef.current();
      return;
    }
    if (isListOpen) {
      removeClickListnerRef.current = createOuterClickListner(onClickOutside);
    }
  }, [isListOpen, onClickOutside]);

  const onToggleSelectAll = () => {
    const isAllSelected = value && Object.keys(value).length === data.length;
    const newValue = {};
    if (!isAllSelected) {
      data.forEach((item) => {
        newValue[item.id] = item;
      });
    }
    onChange && onChange({ target: { value: newValue } });
  };

  const onListGroupClick = useCallback(
    (listItemId, listItem) => {
      if (typeof onChange === 'function') {
        let newValue = value ? { ...value } : {};
        if (singleSelect) {
          newValue = {};
        }

        if (newValue[listItemId]) {
          delete newValue[listItemId];
        } else {
          newValue[listItemId] = listItem;
        }
        const selectedItems = Object.keys(newValue).length;
        onChange({ target: { value: selectedItems === 0 ? null : newValue } });

        if (singleSelect) {
          onSearch(
            { target: { value: Object.values(newValue)[0].heading } },
            false
          );
        }
      }
    },
    [onChange, value, emptySearchInput, singleSelect]
  );

  const onListFocus = (e) => {
    if (showListOnFocus) {
      toggleList(true);
    }
    restInputProps.onFocus && restInputProps.onFocus(e);
  };

  const List = useMemo(() => {
    return useMultiSelectGroup ? MultiSelectListGroup : ListGroup;
  }, [useMultiSelectGroup]);

  return (
    <InputContainer className="ab_multi_select" size={size}>
      <GlobalInput
        success={success}
        error={error}
        type="text"
        placeholder={placeholder}
        ref={popupBtnRef}
        {...restInputProps}
        onChange={onSearch}
        value={searchValue}
        onFocus={onListFocus}
      />

      {isListOpen && (
        <List
          optionChildren={optionChildren}
          isInBottom={isInBottom}
          ref={setPopupRef}
          data={data}
          onEnd={onEnd}
          selectedData={value}
          onChange={onListGroupClick}
          isLoading={isLoading}
          showSelectAll={showSelectAll}
          onToggleSelectAll={onToggleSelectAll}
        />
      )}

      {!!icon && <InputIcon success={success} error={error} name={icon} />}
    </InputContainer>
  );
};

export default SearchSelect;
