import { combineReducers } from 'redux';
import webinarView from './view';
import webinarStudent from './student';
import webinarCertificate from './certificate';
import registerStudents from './registerStudents';

const webinar = combineReducers({
  view: webinarView,
  student: webinarStudent,
  certificate: webinarCertificate,
  registerStudents: registerStudents
});

export default webinar;
