import {
  FETCH_STUDENTS,
  FETCH_EMPLOYER_COURSES
} from '../../Actions';

const initialState = {
  dataIds: [],
  data: {},
  courseData: {
    courseRelation: {}
  },
  courseRelation: {},
  currentCourseId: '',
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  }
};

const students = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_STUDENTS}_SUCCESS`: {
      const { data, paginationData } = payload;

      return {
        ...store,
        data: { ...store.data, ...data },
        paginationData
      };
    }

    case `${FETCH_EMPLOYER_COURSES}_SUCCESS`: {
      const { data, relation } = payload;

      return {
        ...store,
        courseData: { ...store.courseData, ...data, courseRelation: relation },
        courseRelation: relation
      };
    }

    default:
      return store;
  }
};

export default students;
