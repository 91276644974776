import React, { useCallback } from "react";

import Icon from "../Icon";
import {
  ListItemContainer,
  ListItemCheckButton,
  ListItemContent,
  ListItemDesc,
  ListItemHeading,
  ListItemImage,
  StyledProfilePic,
} from "./style";

export const ListItem = ({
  selected,
  onSelect,
  data,
  showSelectButton = true,
  showDeselectButton = false,
}) => {
  const ListItemClicked = useCallback(() => {
    if (typeof onSelect === "function") {
      onSelect(data.id, data);
    }
  }, [onSelect, data]);
  return (
    <ListItemContainer onClick={!showSelectButton ? ListItemClicked : null}>
      {!!data.image ? (
        <ListItemImage src={data.image} />
      ) : (
        <StyledProfilePic userName={data.heading} />
      )}
      <ListItemContent>
        <ListItemHeading>{data.heading}</ListItemHeading>
        <ListItemDesc>{data.desc}</ListItemDesc>
      </ListItemContent>

      {showSelectButton && (
        <ListItemCheckButton
          type="button"
          outline={showDeselectButton}
          onClick={ListItemClicked}
          active={selected}
        >
          <Icon
            name={
              showDeselectButton
                ? "fas fa-minus"
                : selected
                ? "fas fa-check"
                : "fas fa-plus"
            }
          />
        </ListItemCheckButton>
      )}
    </ListItemContainer>
  );
};
