export const FETCH_SUBSCRIBED_STUDENTS = 'FETCH_SUBSCRIBED_STUDENTS';
export const SEARCH_SUBSCRIBED_STUDENT = 'SEARCH_SUBSCRIBED_STUDENT';
export const UPDATE_SUBSCRIBED_STUDENT_SEARCH_PARAMS =
  'UPDATE_SUBSCRIBED_STUDENT_SEARCH_PARAMS';
export const RESET_PREVIOUS_SUBSCRIBED_STUDENT_DATA =
  'RESET_PREVIOUS_SUBSCRIBED_STUDENT_DATA';
export const CREATE_SUBSCRIBED_STUDENT_MEMORENDUM =
  'CREATE_SUBSCRIBED_STUDENT_MEMORENDUM';

export const FETCH_SUBSCRIBED_STUDENT_MEMORENDUM =
  'FETCH_SUBSCRIBED_STUDENT_MEMORENDUM';
