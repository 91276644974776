import requestData from './index';
import { getUnderScoreString, addParamsToQuery } from '../Helper/index';

export const fetchInactiveWorkshopsApi = ({ pageNumber }) => {
  return requestData(`/admin/get_inactive_seminars?page=${pageNumber}`, {
    method: 'GET'
  });
};

export const triggerWorkshopMailToAudienceApi = ({
  seminarId,
  emailIds,
  csvFile,
  userEmails,
  isRegistered
}) => {
  const body = {
    seminar_id: seminarId,
    email_saver_ids: emailIds
  };
  if (csvFile) {
    body['csv_file'] = csvFile;
  } else if (Array.isArray(userEmails) && userEmails.length > 0) {
    body['emails'] = userEmails;
  }

  return requestData(
    `/admin/email_saver/trigger_email_for_${
      isRegistered ? 'registered' : 'unregistered'
    }_participants`,
    {
      method: 'POST',
      isFormData: true,
      body
    }
  );
};

export const fetchActiveWorkshopsApi = ({ pageNumber }) => {
  return requestData(`/admin/get_active_seminars?page=${pageNumber}`, {
    method: 'GET'
  });
};

export const fetchWorkshopRegisteredAudiencesApi = ({
  pageNumber,
  seminarId
}) => {
  return requestData(
    `/admin/get_registered_seminar_audiences?seminar_id=${seminarId}&page=${pageNumber}`,
    { method: 'GET' }
  );
};

export const fetchWorkshopUnregisteredAudiencesApi = ({
  pageNumber,
  seminarId
}) => {
  return requestData(
    `/admin/get_unregistered_seminar_audiences?seminar_id=${seminarId}&page=${pageNumber}`,
    { method: 'GET' }
  );
};

export const searchRegisteredAudiencesApi = ({
  pageNumber,
  seminarId,
  searchText
}) => {
  return requestData(
    `/admin/search_registered_seminar_audiences?seminar_id=${seminarId}&page=${pageNumber}&name_or_email_or_phone=${searchText}`,
    { method: 'GET' }
  );
};

export const searchUnregisteredAudiencesApi = ({
  pageNumber,
  seminarId,
  searchText
}) => {
  return requestData(
    `/admin/search_unregistered_seminar_audiences?seminar_id=${seminarId}&page=${pageNumber}&name_or_email_or_phone=${searchText}`,
    { method: 'GET' }
  );
};

export const workshopCreationApi = (formData) => {
  const body = {
    seminar: formData
  };
  return requestData('/admin/seminars', {
    method: 'POST',
    body,
    isFormData: true
  });
};

export const searchWorkshopAudienceApi = ({ pageNumber, ...otherParams }) => {
  let query = `?page=${pageNumber}`;
  query = addParamsToQuery(query, otherParams);

  return requestData(`/admin/search_seminar_audiences${query}`, {
    method: 'GET'
  });
};

export const getAllWorkshopsApi = ({ pageNumber }) => {
  return requestData(`/admin/seminars?page=${pageNumber}`, {
    method: 'GET'
  });
};

export const workshopRegistrationApi = ({
  participantName,
  participantPhone,
  participantEmail,
  participantBranch,
  participantCity,
  participantState,
  participantCollege,
  participantSemester,
  paymentSource,
  description,
  amount,
  seminarId
}) => {
  const body = {
    seminar_audience: {
      name: participantName,
      phone: participantPhone,
      college: participantCollege,
      email: participantEmail,
      semester: participantSemester,
      branch: participantBranch,
      city: participantCity,
      state: participantState
    },
    seminar_registration: {
      payment_source: paymentSource,
      description,
      paid_amount: amount,
      seminar_id: seminarId
    }
  };
  return requestData('/admin/seminar_registrations', {
    method: 'POST',
    body,
    isFormData: true
  });
};

export const workshopRegistrationCSVApi = ({ csvFile, seminarId }) => {
  const body = {
    csv_file: csvFile
  };

  return requestData(
    `/admin/seminar_registrations/create_by_csv?seminar_id=${seminarId}`,
    {
      method: 'POST',
      body,
      isFormData: true
    }
  );
};

export const workshopCSVDownloadApi = ({ seminarId, type }) => {
  return requestData(
    `/admin/seminar_audiences/csv_export_seminar_audiences.csv?seminar_id=${seminarId}&seminar_audience_type=${type}`,
    {
      method: 'GET',
      isBlob: true
    }
  );
};

export const searchRegisteredparticipantDetailsByParamsApi = ({
  pageNumber,
  ...otherParams
}) => {
  let query = `?page=${pageNumber}`;
  query = addParamsToQuery(query, otherParams);

  return requestData(`/admin/search_registered_seminar_audiences${query}`, {
    method: 'GET'
  });
};

export const searchSeminarDetailsByParamsApi = ({
  pageNumber,
  ...otherParams
}) => {
  let query = `?page=${pageNumber}`;
  query = addParamsToQuery(query, otherParams);

  return requestData(`/admin/search_seminar${query}`, {
    method: 'GET'
  });
};

export const searchUnRegisteredparticipantDetailsByParamsApi = ({
  pageNumber,
  ...otherParams
}) => {
  let query = `?page=${pageNumber}`;
  query = addParamsToQuery(query, otherParams);

  return requestData(`/admin/search_unregistered_seminar_audiences${query}`, {
    method: 'GET'
  });
};

export const fetchAllRegisteredAudiencesApi = ({ pageNumber, seminarId }) => {
  return requestData(
    `/admin/seminars/${seminarId}/get_registrations_by_seminar?page=${pageNumber}`,
    { method: 'GET' }
  );
};

export const createActiveWorkshopCertificateApi = ({
  modelableId,
  modelableType,
  certificationType,
  dateOfIssue,
  candidateOption,
  mail,
  selectedStudentIds
}) => {
  const body = {
    certificate: {
      certification_type: certificationType,
      date_of_issue: dateOfIssue
    }
  };
  if (selectedStudentIds) {
    body['certificate']['certifiable_ids'] = selectedStudentIds;
  }
  return requestData(
    `/admin/certifications?modelable_type=${modelableType}&modelable_id=${modelableId}&create_for_all_flag=${candidateOption}&notify_flag=${mail}`,
    {
      method: 'POST',
      body,
      isFormData: true
    }
  );
};

export const fetchAllCertificateApi = ({
  pageNumber,
  seminarId,
  modelableType
}) => {
  return requestData(
    `/admin/certifications?page=${pageNumber}&modelable_type=${modelableType}&modelable_id=${seminarId}`,
    {
      method: 'GET'
    }
  );
};

export const workshopEditApi = ({ formData, workshopId }) => {
  const body = {
    seminar: formData
  };
  return requestData(`/admin/seminars/${workshopId}`, {
    method: 'PUT',
    body,
    isFormData: true
  });
};

export const sendCertificateMailApi = (seminarId, certificateId) => {
  return requestData(
    `/admin/send_certification_email/${certificateId}?modelable_type=Seminar&modelable_id=${seminarId}`,
    {
      method: 'GET'
    }
  );
};

export const deleteCertificateApi = (seminarId, certificateId) => {
  return requestData(
    `/admin/certifications/${certificateId}?modelable_type=Seminar&modelable_id=${seminarId}`,
    {
      method: 'DELETE'
    }
  );
};

export const sendMailToRegisterParticipantApi = (
  seminarId,
  registeredParticipantId
) => {
  return requestData(
    `/admin/send_seminar_registration_email/${registeredParticipantId}?seminar_id=${seminarId}`,
    {
      method: 'GET'
    }
  );
};

export const createRegistrationForPaymentReceivalApi = (
  seminarId,
  seminarAudienceId
) => {
  return requestData(
    `/admin/seminars/${seminarId}/create_registration_for_payment_receival?seminar_audience_id=${seminarAudienceId}`,
    {
      method: 'POST'
    }
  );
};
