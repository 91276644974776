import { useCallback, useRef, useState } from 'react';

const useChildDimensions = () => {
  const [elemPosition, setPosition] = useState(null);
  const childRef = useRef(null);

  const calculatePosition = (target) => {
    if (!target && !childRef.current) {
      return;
    }

    const el = target || childRef.current;
    const { top, left, width, height, right } = el.getBoundingClientRect();
    setPosition({ top, left, width, height, right });
  };

  const setRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    childRef.current = ref;
    calculatePosition();
  }, []);

  return [setRef, elemPosition, calculatePosition];
};

export default useChildDimensions;
