import { LOGIN_USER } from '../Actions';
import {
  USER_LOCAL_DATA,
  TOKEN_LOCAL_NAME,
  CHANNEL_PARTNER_DATA
} from '../Constants';

const getInitialLoggedIn = () => {
  return window.sessionStorage.getItem(TOKEN_LOCAL_NAME) ? true : false;
};

const getInitialUserData = () => {
  const data = window.sessionStorage.getItem(USER_LOCAL_DATA);

  if (!data) {
    return {
      id: '',
      name: '',
      phone: '',
      email: '',
      avtarUrl: '',
      accessType: null
    };
  }

  const userData = JSON.parse(data);
  return {
    id: userData.id,
    ...(userData.attributes || {})
  };
};

const getInitialChannelPartnerData = () => {
  const data = window.sessionStorage.getItem(CHANNEL_PARTNER_DATA);

  if (!data) {
    return null;
  }

  const channelPartnerData = JSON.parse(data);
  return {
    id: channelPartnerData.id,
    ...(channelPartnerData.attributes || {})
  };
};

const getChannelPartnerData = ({ data, dataIds, relation }) => {
  const id = dataIds[0];
  const relationshipData =
    data[id]?.relationships?.channelPartner?.data || null;
  return (
    (relationshipData?.id &&
      relationshipData?.type &&
      relation[`${relationshipData?.id}_${relationshipData?.type}`]) ||
    null
  );
};

const initialStore = {
  isLoggedIn: getInitialLoggedIn(),
  userData: getInitialUserData(),
  channelPartnerData: getInitialChannelPartnerData()
};

const auth = (store = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${LOGIN_USER}_SUCCESS`: {
      const { data, dataIds, relation } = payload;
      const id = dataIds[0];
      const channelPartnerData = getChannelPartnerData(payload);

      return {
        ...store,
        isLoggedIn: true,
        userData: {
          id,
          ...(data[id].attributes || {})
        },
        channelPartnerData: {
          id: channelPartnerData?.id || null,
          ...(channelPartnerData?.attributes || null)
        }
      };
    }

    default: {
      return store;
    }
  }
};

export default auth;
