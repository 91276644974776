import React from 'react';
import Icon from '../../Icon';

import styles from './Fallback.module.css';

const PageFallback = () => {
  return (
    <div className={`${styles.page_fall_cont} cc`}>
      <Icon className={styles.error_icon} name="fas fa-exclamation-triangle" />
      <span className={styles.error_text}>Something went wrong !!!</span>
    </div>
  );
};

export default PageFallback;
