import { GET_MENTOR_BATCHES, RESET_MENTOR_DETAILS } from '../../Actions/index';
import { createNewStoreOnGetData } from '../../Helper/reducer';

const initialState = {
  dataIds: [],
  data: {},
  relation: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  }
};

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_MENTOR_BATCHES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case RESET_MENTOR_DETAILS:
      return initialState;

    default:
      return store;
  }
};
export default view;
