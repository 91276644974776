import { useCallback, useState } from 'react';

const usePasswordViewer = () => {
  const [showPassword, setShowPassword] = useState(false);

  const getFieldType = useCallback(
    (type) => {
      const isPasswordType = type == 'password';
      let fieldType = 'text';
      if (!!type) {
        if (isPasswordType) {
          fieldType = showPassword ? 'text' : 'password';
        } else {
          fieldType = type;
        }
      }
      return [fieldType, isPasswordType];
    },
    [showPassword]
  );

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return {
    getFieldType,
    showPassword,
    toggleShowPassword
  };
};

export default usePasswordViewer;
