import {
  FETCH_ACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES,
  RESET_TO_UNREGISTERED_PARTICIPANT_DETAILS_PREV_DATA,
  RESET_WORKSHOP_PARTICIPANTS_DATA,
  SEARCH_UNREGISTERED_PARTICIPANT_DETAILS,
  UPDATE_UNREGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS,
  CREATE_REGISTRATION_PAYMENT_RECIVAL
} from '../../../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData,
  updateDataOnDelete
} from '../../../../Helper/reducer';

const initialState = createInitialStoreState(
  {
    totalRegistrations: null,
    onceFetched: false,
    currentContactData: '',
    currentCollege: '',
    currentCity: '',
    currentBranch: '',
    currentState: '',
    count: 0
  },
  true
);

const unregistered = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_UNREGISTERED_PARTICIPANT_DETAILS}_SUCCESS`:
    case `${FETCH_ACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(
        payload,
        {
          ...store,
          count: meta?.totalCount || 0
        },
        true
      );
    }
    case RESET_WORKSHOP_PARTICIPANTS_DATA: {
      return {
        ...initialState
      };
    }

    case UPDATE_UNREGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS: {
      const { contactData, college, city, branch, state } = payload;

      return {
        ...store,
        onceFetched: false,
        currentContactData: contactData,
        currentCollege: college,
        currentCity: city,
        currentBranch: branch,
        currentState: state
      };
    }

    case `${CREATE_REGISTRATION_PAYMENT_RECIVAL}_SUCCESS`: {
      const { dataIds } = payload;
      return updateDataOnDelete(
        { ...store, count: store.count > 0 ? store.count - 1 : 0 },
        dataIds[0]
      );
    }

    case RESET_TO_UNREGISTERED_PARTICIPANT_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentContactData: '',
        currentCollege: '',
        currentCity: '',
        currentBranch: '',
        currentState: ''
      };
    }

    default:
      return store;
  }
};

export default unregistered;
