import {
  FETCH_INACTIVE_WORKSHOPS,
  RESET_TO_INACTIVE_SEMINAR_DETAILS_PREV_DATA,
  SET_INACTIVE_CURRENT_SEMINAR_ID,
  SET_INACTIVE_SLIDER_STATUS,
  UPDATE_INACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS,
  SEARCH_SEMINAR_DETAILS
} from '../../../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../../../Helper/reducer';

const initialState = createInitialStoreState({
  totalRegistrations: null,
  sliderStatus: null,
  seminarId: null,
  currentSeminarType: '',
  currentSeminarName: '',
  currentSeminarVenue: '',
  currentSeminarPaidStatus: '',
  count: 0
});

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_SEMINAR_DETAILS}_SUCCESS`:
    case `${FETCH_INACTIVE_WORKSHOPS}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(payload, {
        ...store,
        count: meta?.totalCount || 0
      });
    }

    case `${SET_INACTIVE_SLIDER_STATUS}`: {
      const { index } = payload;
      return {
        ...store,
        sliderStatus: index
      };
    }

    case `${SET_INACTIVE_CURRENT_SEMINAR_ID}`: {
      const { seminarId } = payload;
      return {
        ...store,
        seminarId
      };
    }

    case UPDATE_INACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS: {
      const { seminarType, name, venue, paidStatus } = payload;
      return {
        ...store,
        onceFetched: false,
        currentSeminarType: seminarType,
        currentSeminarName: name,
        currentSeminarVenue: venue,
        currentSeminarPaidStatus: paidStatus
      };
    }

    case RESET_TO_INACTIVE_SEMINAR_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentSeminarType: '',
        currentSeminarName: '',
        currentSeminarVenue: '',
        currentSeminarPaidStatus: ''
      };
    }

    default:
      return store;
  }
};

export default view;
