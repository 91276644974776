import React, { useMemo } from 'react';
import UserManagementTab from './Tabs/UserManagement';
import CourseManagementTab from './Tabs/CourseManagement';
import LiveProjectsTab from './Tabs/LiveProjects';
import SupportTab from './Tabs/Support';
// import CourseLevelTab from "./Tabs/CourseLevel";
import StudentTab from './Tabs/Student';
import EmployerTab from './Tabs/Employer';
import WorkshopManagementTab from './Tabs/Workshop';
import CompanyMentorsTab from './Tabs/CompanyMentors';

import styles from './Drawer.module.css';
import MailTab from './Tabs/Mail';
import BatchTab from './Tabs/Batch';
import ChannelPartnersTab from './Tabs/ChannelPartners';

const Drawer = ({ isDrawerActive, isMobileView }) => {
  const isZeroWidth = useMemo(() => {
    return !isDrawerActive && !isMobileView;
  }, [isDrawerActive, isMobileView]);
  return (
    <div
      style={{
        transform:
          isDrawerActive || !isMobileView
            ? 'translateX(0%)'
            : 'translateX(-100%)'
      }}
      className={`${styles.container} ${isZeroWidth ? styles.zero_width : ''} ${
        !isMobileView ? styles.shadow : ''
      }`}>
      <BatchTab />
      {/* <UserInfoSection /> */}
      {/* <UserManagementTab /> */}
      <CourseManagementTab />
      {/* <StudentTab /> */}
      {/* <ChannelPartnersTab /> */}
      {/* <WorkshopManagementTab /> */}
      {/* <EmployerTab /> */}
      {/* <CourseLevelTab/> */}
      {/* <LiveProjectsTab /> */}
      <SupportTab />
      {/* <CompanyMentorsTab /> */}
      {/* <MailTab /> */}
    </div>
  );
};

export default Drawer;
