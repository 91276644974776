import {
  CREATE_INTERVIEW,
  FETCH_INTERVIEW,
  CLEAR_INTERVIEW,
  UPDATE_INTERVIEW
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate
} from '../../Helper/reducer';

const initialState = createInitialStoreState({}, true);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${CREATE_INTERVIEW}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${FETCH_INTERVIEW}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${UPDATE_INTERVIEW}_SUCCESS`:
      return createNewStoreOnUpdate(payload, store);

    case CLEAR_INTERVIEW:
      return initialState;

    default:
      return store;
  }
};

export default view;
