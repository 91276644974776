import { createSelector } from 'reselect';

const storeLoggedIn = (store, props) => store.auth.isLoggedIn;
const storeUserData = (store, props) => store.auth.userData;
const channelPartnerData = (store, props) => store.auth?.channelPartnerData;

export const isUserLoggedIn = createSelector(
  storeLoggedIn,
  (loggedIn) => loggedIn
);

export const getUserData = createSelector(
  storeUserData,
  (userData) => userData
);

export const getAccessType = createSelector(
  storeUserData,
  (userData) => userData.accessType
);

export const getChannelPartnerName = createSelector(
  channelPartnerData,
  (channelPartnerData) => channelPartnerData?.name
);

export const getChannelPartnerId = createSelector(
  channelPartnerData,
  (channelPartnerData) => channelPartnerData?.id
);
