import React, { useMemo, useState, useCallback } from 'react';
import { IMAGE_URL } from '../../config.local';
import ActivityIndicator from '../ActivityIndicator';
import styles from './avatar.module.css';
import Button from '../Button';
import { getNameInitials, getRandomColor } from '../../Helper';

import AvatarEditModal from './AvatarEditModal';
import Modal from '../Modal';

const Avatar = ({
  avatarUrl = '',
  name = '',
  imageClass = '',
  className = '',
  avatarUpdateAction = false
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const onError = () => {
    setIsValidUrl(false);
    setIsImageLoading(false);
  };

  const onLoad = () => {
    setIsImageLoading(false);
  };

  const handleEdit = useCallback(() => {
    setIsModalOpen(true);
  }, [avatarUpdateAction]);

  const randomColor = useMemo(() => {
    return getRandomColor();
  }, [name]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleViewModal = () => {
    if (isValidUrl) {
      setIsViewModalOpen((prev) => !prev);
    }
  };

  const imageUrl = useMemo(() => {
    if (avatarUrl?.includes('https')) {
      return avatarUrl;
    } else {
      return `${IMAGE_URL}/${avatarUrl}`;
    }
  }, [avatarUrl]);

  return (
    <>
      <div
        className={`${styles.container} ${className}`}
        onClick={toggleViewModal}>
        {isValidUrl ? (
          <img
            className={`${styles.image} ${imageClass}`}
            alt="avatar"
            src={imageUrl}
            onLoad={onLoad}
            onError={onError}
          />
        ) : (
          <p
            style={{ backgroundColor: randomColor }}
            className={`${styles.initials} ${imageClass}`}>
            {getNameInitials(name)}
          </p>
        )}
        {isImageLoading && (
          <div className={`${styles.loader} rc`}>
            <ActivityIndicator size="s" />
          </div>
        )}
      </div>
      <Modal active={isViewModalOpen} closeModal={toggleViewModal}>
        <img
          className={styles.modalImage}
          alt="avatar"
          src={`${IMAGE_URL}/${avatarUrl}`}
          onLoad={onLoad}
          onError={onError}
        />
      </Modal>
      {typeof avatarUpdateAction === 'function' && (
        <>
          <Button
            onClick={handleEdit}
            size="small"
            className={styles.editButton}>
            Edit
          </Button>
          <AvatarEditModal
            avatarUrl={avatarUrl}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            avatarUpdateAction={avatarUpdateAction}
          />
        </>
      )}
    </>
  );
};

export default Avatar;
