import { combineReducers } from 'redux';
import MailReportsReducer from './mailReports/view';
import TemplateMailsReducer from './templateMails/view';
import AllMailsReducer from './scheduleMails/mails';
import scheduleMailViewReducer from './scheduleMails/view';

const mail = combineReducers({
  mailReports: MailReportsReducer,
  templateMails: TemplateMailsReducer,
  allMails: AllMailsReducer,
  scheduleMailView: scheduleMailViewReducer
});

export default mail;
