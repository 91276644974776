import { useState, useCallback } from 'react';
import useAlert from '../../Hooks/useAlert';

export const useUpdateAvatar = ({ udpateAvatar }) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        const { avatar_updateImage } = values;
        updateLoading(true);
        await udpateAvatar(avatar_updateImage[0]);
        showAlert({
          message: 'Avatar has been updated',
          type: 'success'
        });
        resetForm();
        updateLoading(false);
      } catch (err) {
        updateLoading(false);
        showAlert({ message: err[0], type: 'error' });
      }
    },
    [udpateAvatar, showAlert]
  );

  return {
    isLoading,
    onFormSubmit
  };
};
