import {
  CLEAR_EMPLOYER_ID,
  CREATE_EMPLOYER_JOB_DESCRIPTION,
  FETCH_EMPLOYER_JOB_DESCRIPTION,
  UPDATE_EMPLOYER_JOB_DESCRIPTION,
  DELETE_EMPLOYER_JOB_DESCRIPTION
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
  updateDataOnDelete
} from '../../Helper/reducer';

const initialState = createInitialStoreState({});

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_EMPLOYER_JOB_DESCRIPTION}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${CREATE_EMPLOYER_JOB_DESCRIPTION}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${CLEAR_EMPLOYER_ID}`: {
      return {
        ...store,
        onceFetched: false
      };
    }

    case `${UPDATE_EMPLOYER_JOB_DESCRIPTION}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${DELETE_EMPLOYER_JOB_DESCRIPTION}_SUCCESS`: {
      const { rowId } = payload;
      return updateDataOnDelete(store, rowId);
    }

    default:
      return store;
  }
};

export default view;
