import React from 'react';

import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
const userRuote = Routes.authorized.userManagement;

const tabLinks = Object.keys(userRuote.routes).map(
  (key) => userRuote.routes[key].path
);

const LinkComponents = Object.keys(userRuote.routes).map((key) => {
  const { path, drawerTitle, accessType } = userRuote.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const UserManagementTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={userRuote.accessType}>
      <DrawerTab icon="far fa-user" title="User" links={tabLinks}>
        {LinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default UserManagementTab;
