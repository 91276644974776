import { RandColors } from '../Constants';
import { IMAGE_URL } from '../config.local';
import CSSIcon from '../Assets/FileIcons/icons=CSS.png';
import EPSIcon from '../Assets/FileIcons/icons=EPS.png';
import GIFIcon from '../Assets/FileIcons/icons=GIF.png';
import HTMLIcon from '../Assets/FileIcons/icons=HTML.png';
import MP4Icon from '../Assets/FileIcons/icons=video.png';
import PDFIcon from '../Assets/FileIcons/icons=PDF (2).png';
import PPTIcon from '../Assets/FileIcons/icons=PPT.png';
import TXTIcon from '../Assets/FileIcons/icons=txt (3).png';
import XLSIcon from '../Assets/FileIcons/icons=sheet (1).png';
import ZIPIcon from '../Assets/FileIcons/icons=zip.png';
import ImgIcon from '../Assets/FileIcons/icons=img.png';
import FileIcon from '../Assets/FileIcons/icons=file.png';

let cache = {};

const storeInCache = (container, key, value) => {
  cache = {
    ...cache,
    [container]: {
      ...(cache[container] || {}),
      [key]: value
    }
  };
};

export const getRandomColor = () => {
  const rand = Math.floor(Math.random() * 10);
  const index = rand % RandColors.length;
  return RandColors[index];
};

const isInCache = (container, key) => {
  return cache[container] && cache[container][key];
};
export const generateRandomId = () => {
  let id = '';
  for (let i = 0; i < 6; i++) {
    id += Math.ceil(Math.random() * 10);
  }
  return id;
};

export const getDateInLocal = (date) => {
  const d = new Date(String(date));
  return d.toLocaleDateString();
};

const createJoinedString = (value, joinWith = ' ') => {
  if (!value) {
    return ' ';
  }
  let res = '';
  for (let i = 0; i < value.length; i++) {
    if (value.charCodeAt(i) < 97) {
      res += joinWith + value.charAt(i);
    } else {
      res += value.charAt(i);
    }
  }

  return res;
};

const replaceCharacter = (value = '', replace = ' ', replaceWith = ' ') => {
  if (!value) {
    return '';
  }
  const stringArr = value.split(replace);
  return stringArr.join(replaceWith);
};

export const replaceUnderScoreWithSpace = (value) => {
  const cachedValue = isInCache('replaceUnderscore', value);
  if (cachedValue) {
    return cachedValue;
  }
  const result = replaceCharacter(value, '_', ' ');
  storeInCache('replaceUnderscore', value, result);
  return result;
};

const removeGroupBy = (value) => {
  if (!value) {
    return '';
  }
  return value.replace('group by', '');
};

export const removeGroupByFromChartTitle = (value) => {
  const cachedValue = isInCache('removeGroupBy', value);
  if (cachedValue) {
    return cachedValue;
  }
  const result = removeGroupBy(value);
  storeInCache('removeGroupBy', value);
  return result;
};

export const getUnderScoreString = (value) => {
  const cachedValue = isInCache('underscore', value);

  if (cachedValue) {
    return cachedValue;
  }
  const result = createJoinedString(value, '_');
  storeInCache('underscore', value, result);
  return result;
};

export const removePrevUnderScoreString = (value = '') => {
  const cachedValue = isInCache('prevUnderscore', value);

  if (cachedValue) {
    return cachedValue;
  }

  if (typeof value !== 'string') {
    return value;
  }

  const lastIndex = value.lastIndexOf('_');
  const result = value.substring(lastIndex + 1);
  storeInCache('prevUnderscore', value, result);
  return result;
};

export const getSpacedString = (value) => {
  const cachedValue = isInCache('space', value);

  if (cachedValue) {
    return cachedValue;
  }
  const result = createJoinedString(value, ' ');
  storeInCache('space', value, result);
  return result;
};

export const generateTableHeading = (...value) => {
  if (value.length === 0) {
    return '---';
  }
  let result = [];
  for (let j = 0; j < value.length; j++) {
    const cachedValue = isInCache('heading', value[j]);

    if (cachedValue) {
      result.push(cachedValue);
      continue;
    }

    const res = createJoinedString(value[j]);
    storeInCache('heading', value[j], res);

    result.push(res);
  }

  return result;
};

export const arrayToString = (array = []) => {
  if (array.length === 0) {
    return '';
  }
  const itemType = typeof array[0];
  if (itemType === 'string') {
    return array.join(', ');
  }

  if (itemType === 'object') {
    let result = '';
    array.forEach((arrItem) => {
      Object.keys(arrItem).forEach((key) => {
        const item = arrItem[key];
        result += `
${key}: ${item}`;
      });
      result += `
-------------`;
    });
    return result;
  }
};

export const objectToString = (obj = {}) => {
  let result = '';

  Object.keys(obj).forEach((key) => {
    const item = obj[key];
    result += `
${key}: ${item}`;
  });

  return result;
};

export const getSearchParams = (url = '', key) => {
  if (!url) {
    return null;
  }

  const queryParams = new URL(url).search;
  const searchData = new URLSearchParams(queryParams);
  return searchData.has(key) ? searchData.get(key) : null;
};

export const stringToArray = (text = '', delimeter = ',') => {
  return [...text.split(delimeter)];
};

export const isLink = (url) => {
  if (!url) {
    return false;
  }

  try {
    const urlData = new URL(url);
    return urlData.origin === 'null' ? false : true;
  } catch (err) {
    return false;
  }
};

export const debounce = (callback, timeout = 500) => {
  let id = null;
  return (...args) => {
    if (id) {
      clearTimeout(id);
    }

    id = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

export const unionData = (...arrs) => {
  return arrs.reduce((arr1, arr2) => [...new Set([...arr1, ...arr2])]);
};

export const createOuterClickListner = (callback) => {
  document.body.addEventListener('click', callback);
  return () => {
    document.body.removeEventListener('click', callback);
  };
};

export const getFormattedDate = (date, delimiter = '/') => {
  const dateStr = new Date(date);
  if (dateStr === 'Invalid Date') return date;

  return `${dateStr.getDate().toString().padStart(2, '0')}${delimiter}${(
    dateStr.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}${delimiter}${dateStr.getFullYear()}`;
};

export const createLimitsArray = (length = 10, startAt = 0) => {
  return Array.from({ length }, (_, i) =>
    (i + startAt).toString().padStart(2, '0')
  );
};

export const getNameInitials = (name) => {
  if (typeof name !== 'string') {
    return '';
  }

  const arr = name.split(' ');
  return arr.length > 1
    ? `${arr[0].charAt(0)}${arr[arr.length - 1].charAt(0)}`
    : `${arr[0].charAt(0)}`;
};

export const addParamsToQuery = (initialQuery, otherParams) => {
  let query = initialQuery;
  const hasAlreadyParams = initialQuery.includes('?');

  if (!hasAlreadyParams) {
    query += '?';
  }

  Object.keys(otherParams).forEach((key) => {
    if (otherParams[key]) {
      if (!hasAlreadyParams) {
        query += `${getUnderScoreString(key).toLowerCase()}=${
          otherParams[key]
        }`;
      } else {
        query += `&${getUnderScoreString(key).toLowerCase()}=${
          otherParams[key]
        }`;
      }
    }
  });
  return query;
};

export function mergeProps(...rest) {
  let result = {};
  rest.forEach((item) => {
    result = {
      ...result,
      ...item
    };
  });
  return result;
}

export const getImageUrl = (url) => {
  if (url && typeof url === 'string') return `${IMAGE_URL}${url}`;
  return '';
};

export const getFileNameAndExtensionFromUrl = (url) => {
  let decodedUrl = decodeURIComponent(url);
  let fileName = '';
  let extension = '';
  if (decodedUrl) {
    fileName = decodedUrl.split('/').pop();
    extension = fileName.split('.').pop();
  }
  return [fileName, extension];
};

export const checkIsImageUrl = (url = '') => {
  const regex = /\.(png|jpg|jpeg|wbep|gif)$/gim;
  return !!url.match(regex);
};

export const appendHashToUrl = (key, value) => {
  const url = new URL(window.location);
  url.hash = `${key}_${value}`;
  window.history.pushState(null, '', url.toString());
};

export const timeStringToArray = (val) => {
  const arr = val.match(/[a-zA-Z]+|[0-9]+/g);
  return {
    hour: arr[0],
    minute: arr[1],
    meridian: arr[2]
  };
};

export const getFileTypeIcon = (extension) => {
  switch (extension.toUpperCase()) {
    case 'CSS':
      return CSSIcon;
    case 'EPS':
      return EPSIcon;
    case 'GIF':
      return GIFIcon;
    case 'HTML':
      return HTMLIcon;
    case 'MP4':
      return MP4Icon;
    case 'PDF':
      return PDFIcon;
    case 'PPT':
    case 'PPTX':
      return PPTIcon;
    case 'TXT':
      return TXTIcon;
    case 'XLS':
    case 'XLSX':
      return XLSIcon;
    case 'ZIP':
      return ZIPIcon;
    case 'PNG':
    case 'JPG':
    case 'JPEG':
    case 'WBEP':
      return ImgIcon;
    default:
      return FileIcon;
  }
};
