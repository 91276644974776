import React from 'react';

import DrawerLink from '../DrawerLink';
import DrawerTab from '../DrawerTab';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';

const employerRoute = Routes.authorized.employerManagement;

const tabLinks = Object.keys(employerRoute.routes).map(
  (key) => employerRoute.routes[key].path
);

const LinkComponents = Object.keys(employerRoute.routes).map((key) => {
  const { path, drawerTitle, accessType } = employerRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const EmployerManagementTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={employerRoute.accessType}>
      <DrawerTab icon="fas fa-user" title="Employer" links={tabLinks}>
        {LinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};
export default EmployerManagementTab;
