import {
  FETCH_SUBSCRIPTIONS,
  RESET_TO_SUBSCRIPTIONS_PREV_DATA,
  SEARCH_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS_SEARCH_PARAMS
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState();

const view = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_SUBSCRIPTIONS}_SUCCESS`:
    case `${SEARCH_SUBSCRIPTIONS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case UPDATE_SUBSCRIPTIONS_SEARCH_PARAMS: {
      const { courseId, courseLevel, fromDate, toDate, status } = payload;

      return {
        ...store,
        onceFetched: false,
        currentCourseId: courseId,
        currentCourseLevel: courseLevel,
        currentFromDate: fromDate,
        currentToDate: toDate,
        currentStatus: status
      };
    }

    case RESET_TO_SUBSCRIPTIONS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentCourseId: '',
        currentCourseLevel: '',
        currentFromDate: '',
        currentToDate: '',
        currentStatus: ''
      };
    }

    default:
      return store;
  }
};

export default view;
