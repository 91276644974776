import React from 'react';

import DrawerLink from '../DrawerLink';
import DrawerTab from '../DrawerTab';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';

const workshopRoute = Routes.authorized.workshopManagement;

const tabLinks = Object.keys(workshopRoute.routes).map(
  (key) => workshopRoute.routes[key].path
);

const LinkComponents = Object.keys(workshopRoute.routes).map((key) => {
  const { path, drawerTitle, accessType } = workshopRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const WorkshopManagementTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={workshopRoute.accessType}>
      <DrawerTitle title="Workshops" />
      <DrawerTab icon="fas fa-user" title="Workshop" links={tabLinks}>
        {LinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};
export default WorkshopManagementTab;
