export const getCurrentDataAttributes = (item, allData, relations) => {
  return {
    id: item.id,
    ...(item.attributes || {}),
    ...(item.relationships
      ? allData.getRelationData(item.relationships, relations)
      : {})
  };
};

export const getAllAttributesOfData = (allIds, allData, relationMap) => {
  const res = [];

  allIds.forEach((id) => {
    const el = allData[id];
    if (!el) {
      return;
    }
    const data = getCurrentDataAttributes(el, allData, relationMap);
    res.push(data);
  });
  return res;
};
