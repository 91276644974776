import {
  CREATE_EMPLOYER_REMARK,
  DELETE_EMPLOYER_REMARK,
  FETCH_EMPLOYER_REMARKS,
  UPDATE_EMPLOYER_REMARK
} from '../../Actions';
import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
  updateDataOnDelete
} from '../../Helper/reducer';

const initialState = {
  totalRegistrations: null,
  dataIds: [],
  data: {},
  relation: {},
  allData: {},
  allDataIds: [],
  allRelations: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  }
};

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_EMPLOYER_REMARKS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${CREATE_EMPLOYER_REMARK}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${UPDATE_EMPLOYER_REMARK}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${DELETE_EMPLOYER_REMARK}_SUCCESS`: {
      const { rowId } = payload;
      return updateDataOnDelete(store, rowId);
    }

    default:
      return store;
  }
};

export default view;
