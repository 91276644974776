import React from 'react';

import styles from './Drawer.module.css';

const DrawerTitle = ({ title }) => {
  return (
    <div className={styles.drawer_heading_title}>
      <span>{title}</span>
    </div>
  );
};

export default DrawerTitle;
