import {
  CREATE_STUDENT_SUBSCRIPTION,
  FETCH_STUDENTS_SUBSCRIPTION,
  CREATE_STUDENT_DETAIL_SUBSCRIPTION,
  UPDATE_STUDENT_SUBSCRIPTION
} from '../../Actions';
import { unionData } from '../../Helper';
import {
  createRelationIterator,
  getRelationData
} from '../../Helper/middleware';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState({ totalRegistrations: null });

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_STUDENTS_SUBSCRIPTION}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${CREATE_STUDENT_SUBSCRIPTION}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${UPDATE_STUDENT_SUBSCRIPTION}_SUCCESS`: {
      const { data, included } = payload;
      const newData = { ...store.data };
      if (!newData.getRelationData) {
        Object.assign(newData, { getRelationData });
      }
      newData[data.id] = { ...data };

      const newIds = unionData(store.dataIds, data.id);
      const newRelation = { ...store.relation };

      if (Array.isArray(included)) {
        included.forEach((item) => {
          newRelation[`${item.id}_${item.type}`] = {
            ...item,
            [Symbol.iterator]: createRelationIterator()
          };
        });
      }
      return {
        ...store,
        data: newData,
        dataIds: newIds,
        relation: newRelation
      };
    }

    case `${CREATE_STUDENT_DETAIL_SUBSCRIPTION}_SUCCESS`: {
      const { data, included, subscriptionId } = payload;
      const newData = { ...store.data };
      if (
        newData[subscriptionId] &&
        newData[subscriptionId].relationships &&
        newData[subscriptionId].relationships.subscriptionDetails &&
        Array.isArray(
          newData[subscriptionId].relationships.subscriptionDetails.data
        )
      ) {
        newData[subscriptionId].relationships.subscriptionDetails.data.push({
          id: data.id,
          type: data.type
        });
      }

      const newRelation = { ...store.relation };

      newRelation[`${data.id}_${data.type}`] = {
        ...data,
        [Symbol.iterator]: createRelationIterator()
      };

      if (Array.isArray(included)) {
        included.forEach((item) => {
          newRelation[`${item.id}_${item.type}`] = {
            ...item,
            [Symbol.iterator]: createRelationIterator()
          };
        });
      }
      return {
        ...store,
        data: newData,
        relation: newRelation
      };
    }

    case `CLEAR_STUDENT_SUBSCRIPTION_VIEW_DATA`: {
      return {
        ...store,
        dataIds: [],
        data: {},
        relation: {},
        onceFetched: false,

        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1
        }
      };
    }

    default:
      return store;
  }
};

export default view;
