let API_URL;
let VERSION_URL;
if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://alientraining.herokuapp.com/';
  VERSION_URL = '/api/v1';
}
if (process.env.NODE_ENV === 'development') {
  API_URL = 'https://alientraining.herokuapp.com/';
  VERSION_URL = '/api/v1';
}
export const SERVER_URL = `${API_URL}${VERSION_URL}`;
export const IMAGE_URL = `${API_URL}`;
