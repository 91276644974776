import React from 'react';

import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';

const subscriptionRuote = Routes.authorized.studentSubscriptionManagement;

const SubscriptionTabLinks = Object.keys(subscriptionRuote.routes).map(
  (key) => subscriptionRuote.routes[key].path
);

const SubscriptionLinkComponents = Object.keys(subscriptionRuote.routes).map(
  (key) => {
    const { path, drawerTitle, accessType } = subscriptionRuote.routes[key];
    return (
      <DrawerLink
        key={path}
        path={path}
        title={drawerTitle}
        requiredAccess={accessType}
      />
    );
  }
);

const SubscriptionTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={subscriptionRuote.accessType}>
      <DrawerTab
        icon="fas fa-ticket-alt"
        title="Subscription"
        links={SubscriptionTabLinks}>
        {SubscriptionLinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default SubscriptionTab;
