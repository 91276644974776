import {
  CLEAR_SUBSCRIPTION_DETAIL,
  FETCH_SUBSCRIPTION_DETAIL,
  UPDATE_SUBSCRIPTION_ID,
  GENERATE_INVOICE_TO_SUBSCRIPTION_DETAIL,
  FETCH_ALL_SUBSCRIPTION_DETAIL
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState({ subscriptionId: '' });

const view = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_ALL_SUBSCRIPTION_DETAIL}_SUCCESS`:
    case `${FETCH_SUBSCRIPTION_DETAIL}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case UPDATE_SUBSCRIPTION_ID:
      const { subscriptionId } = payload;
      return {
        ...store,
        subscriptionId
      };

    case CLEAR_SUBSCRIPTION_DETAIL:
      return initialState;

    case `${GENERATE_INVOICE_TO_SUBSCRIPTION_DETAIL}_SUCCESS`: {
      const { data, rowId } = payload;
      let prvData = store.data;
      let newData = { ...prvData, [rowId]: data[rowId] };
      return {
        ...store,
        data: newData
      };
    }

    default:
      return store;
  }
};
export default view;
