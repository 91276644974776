export const CREATE_STUDENT_SUBSCRIPTION = 'CREATE_STUDENT_SUBSCRIPTION';
export const UPDATE_STUDENT_SUBSCRIPTION = 'UPDATE_STUDENT_SUBSCRIPTION';
export const FETCH_STUDENTS_SUBSCRIPTION = 'FETCH_STUDENTS_SUBSCRIPTION';
export const FETCH_COURSES_AND_COURSE_LEVELS =
  'FETCH_COURSES_AND_COURSE_LEVELS';

export const UPDATE_CURRENT_STUDENT_SUBSCRIPTION_COURSE =
  'UPDATE_CURRENT_STUDENT_SUBSCRIPTION_COURSE';

export const CREATE_STUDENT_DETAIL_SUBSCRIPTION =
  'CREATE_STUDENT_DETAIL_SUBSCRIPTION';
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
