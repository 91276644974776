import React from 'react';

import styles from './FormView.module.css';

const FormView = React.forwardRef(({ children }, ref) => {
  return (
    <div ref={ref} className={`form_view ${styles.form_container} rc`}>
      <div className={styles.form_card}>{children}</div>
    </div>
  );
});
export default FormView;
