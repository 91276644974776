import React from 'react';

import InsertInDom from '../InsertInDom';
import Drawer from './Drawer';
import useTransition from '../../Hooks/useTransition';

import styles from './Drawer.module.css';

const MobileDrawer = ({ toggleDrawer, isDrawerActive, isMobileView }) => {
  const { isVisible } = useTransition({ active: isDrawerActive, maxWait: 400 });
  return (
    isVisible && (
      <InsertInDom domId="overlay">
        <div className={styles.mob_cont}>
          <div
            style={{
              opacity: isDrawerActive ? 1 : 0
            }}
            role="button"
            onClick={toggleDrawer}
            className={styles.overlay}
          />
          <Drawer isMobileView={isMobileView} isDrawerActive={isDrawerActive} />
        </div>
      </InsertInDom>
    )
  );
};

export default MobileDrawer;
