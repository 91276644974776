import { GET_LIVE_PROJECTS } from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate
} from '../../Helper/reducer';

const initialState = createInitialStoreState();

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_LIVE_PROJECTS}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    default:
      return store;
  }
};

export default view;
