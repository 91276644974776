import React, { useMemo } from 'react';
import Button from '../Button';
import FormGroup from '../FormGroup';
import InputGroup from '../InputGroup';
import Modal from '../Modal';
import { useUpdateAvatar } from './useUpdateAvatar';
import FormView from '../ContentView/FormView';
import styles from './avatar.module.css';

const AvatarEditModal = ({
  avatarUpdateAction,
  avatarUrl,
  isModalOpen,
  closeModal
}) => {
  const { isLoading, onFormSubmit } = useUpdateAvatar({
    udpateAvatar: avatarUpdateAction
  });

  const initialUrl = useMemo(() => {
    if (avatarUrl) {
      return [{ name: avatarUrl }];
    }
    return '';
  }, [avatarUrl]);

  return (
    <Modal heading="Update Avatar" active={isModalOpen} closeModal={closeModal}>
      <div className={styles.modalContainer}>
        <FormView>
          <FormGroup onSubmit={onFormSubmit}>
            <InputGroup
              id="avatar_updateImage"
              secondary
              initialValue={initialUrl || ''}
              shouldPersist
              accept="image/*"
              fieldType="googleFilePicker"
              label="Avatar"
              required
            />
            <Button
              disabled={isLoading}
              loading={isLoading}
              size="small"
              primary
              type="submit">
              Update
            </Button>
          </FormGroup>
        </FormView>
      </div>
    </Modal>
  );
};

export default AvatarEditModal;
