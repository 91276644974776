import React, { useMemo, useCallback } from 'react';
import { createYearMatrix } from 'helper/datePicker';

import styles from './DatePicker.module.css';

const YearRow = ({ data = [], onYearChange, currentYear }) => {
  const onChange = useCallback(
    (e) => {
      const { year } = e.target.dataset;
      onYearChange(year);
    },
    [onYearChange]
  );

  return (
    <div className={styles.year_row}>
      {data.map((year) => {
        return (
          <span
            data-year={year}
            onClick={onChange}
            role="button"
            className={`${styles.year_cell} ${
              year == currentYear && styles.year_cell_active
            }`}
            key={`yearCell_${year}`}>
            {year}
          </span>
        );
      })}
    </div>
  );
};

const Years = ({ startingYear, endingYear, onYearChange, currentYear }) => {
  const { years } = useMemo(() => {
    return {
      years: createYearMatrix({
        startingYear,
        endingYear
      })
    };
  }, [endingYear, startingYear]);
  return (
    <div className={styles.years_box}>
      {years.map((yearRows, index) => {
        return (
          <YearRow
            key={`yearRow_${index}`}
            currentYear={currentYear}
            onYearChange={onYearChange}
            data={yearRows}
          />
        );
      })}
    </div>
  );
};

export default Years;
