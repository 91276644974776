import React, { useMemo, useCallback } from 'react';

import { createDayMatrix } from 'helper/datePicker';

import styles from './DatePicker.module.css';

const DayRow = ({ data = [], row, currentDay, onDayChange }) => {
  const onChange = useCallback(
    (e) => {
      const { day } = e.target.dataset;
      onDayChange(day);
    },
    [onDayChange]
  );

  return (
    <div className={styles.day_row}>
      {data.map((day, index) => {
        return (
          <span
            onClick={day ? onChange : null}
            data-day={day}
            className={`${day ? styles.day_cell : styles.cell} ${
              day == currentDay && styles.day_cell_active
            }`}
            key={`dayCell_${row}_${index}`}>
            {day}
          </span>
        );
      })}
    </div>
  );
};

const Days = ({ day, month, year, onDayChange }) => {
  const { days } = useMemo(() => {
    const date = new Date(year, month, 1);
    const startingDay = date.getDay();
    const totalDays = new Date(year, month + 1, 0).getDate();
    return {
      days: createDayMatrix({
        rows: 5,
        columns: 6,
        startingDay,
        totalDays
      })
    };
  }, [month, year]);
  return (
    <div className={styles.days_box}>
      {days.map((dayRows, index) => {
        return (
          <DayRow
            onDayChange={onDayChange}
            currentDay={day}
            row={index}
            key={`dayRow_${index}`}
            data={dayRows}
          />
        );
      })}
    </div>
  );
};

export default Days;
