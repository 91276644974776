import React from 'react';

import styles from './DatePicker.module.css';
const Week = () => {
  return (
    <div className={styles.week}>
      <span>Sun</span>
      <span>Mon</span>
      <span>Tue</span>
      <span>Wed</span>
      <span>Thu</span>
      <span>Fri</span>
      <span>Sat</span>
    </div>
  );
};

export default Week;
