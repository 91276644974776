import React from 'react';
import Icon from '../../Icon';
import useClipText from 'hooks/useClipText';

import { ListItemCheckBox, ListItemContainer, ListItemText } from './style';

const ListItem = ({ isChecked = false, text, value, showCheckBox }) => {
  const { clippedText } = useClipText({ text });

  return (
    <ListItemContainer data-value={value}>
      {showCheckBox && (
        <ListItemCheckBox data-value={value}>
          {isChecked && <Icon data-value={value} name="fas fa-check" />}
        </ListItemCheckBox>
      )}
      <ListItemText data-value={value}>{clippedText}</ListItemText>
    </ListItemContainer>
  );
};

export default ListItem;
