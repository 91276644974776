const createAsyncActionCreator = (generator) => {
  if (Array.isArray(generator)) {
    return generator.map((gen) => {
      return (params = {}) => gen.bind(this, params);
    });
  }
  return (params = {}) => generator.bind(this, params);
};

export default createAsyncActionCreator;
