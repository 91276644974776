import {
  CREATE_USER,
  DELETE_USER,
  FETCH_USERS,
  RESET_TO_USER_DETAILS_PREV_DATA,
  UPDATE_USER,
  UPDATE_USER_DETAILS_SEARCH_PARAMS,
  SEARCH_USER_DETAILS
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
  updateDataOnDelete
} from '../../Helper/reducer';

const initialState = createInitialStoreState({
  totalRegistrations: null,
  count: 0,
  onceFetched: false,
  currentUserContactData: '',
  currentAccessType: '',
  currentStatus: ''
});

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_USERS}_SUCCESS`:
    case `${SEARCH_USER_DETAILS}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(payload, {
        ...store,
        count: meta?.totalCount || 0
      });
    }

    case `${CREATE_USER}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${UPDATE_USER}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${DELETE_USER}_SUCCESS`: {
      const { dataIds } = payload;
      const filterId = dataIds[0];
      return updateDataOnDelete(store, filterId);
    }

    case UPDATE_USER_DETAILS_SEARCH_PARAMS: {
      const { userContactData, accessType, status } = payload;

      return {
        ...store,
        onceFetched: false,
        currentUserContactData: userContactData,
        currentAccessType: accessType,
        currentStatus: status
      };
    }

    case RESET_TO_USER_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentUserContactData: '',
        currentAccessType: '',
        currentStatus: ''
      };
    }

    default:
      return store;
  }
};

export default view;
