import {
  CREATE_BATCH,
  GET_BATCH,
  UPDATE_BATCH,
  DELETE_BATCH,
  UPDATE_BATCH_DETAILS_SEARCH_PARAMS,
  RESET_TO_BATCH_DETAILS_PREV_DATA,
  SEARCH_BATCH_DETAILS
} from '../../Actions/index';
import {
  createNewStoreOnCreate,
  createNewStoreOnUpdate,
  updateDataOnDelete
} from '../../Helper/reducer';

const initialState = {
  dataIds: [],
  data: {},
  relation: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  },
  count: 0,
  currentBatchName: '',
  currentCourseId: '',
  currentFromDate: '',
  currentToDate: '',
  currentStatus: ''
};

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_BATCH_DETAILS}_SUCCESS`:
    case `${GET_BATCH}_SUCCESS`:
      const { dataIds, data, relation, paginationData, meta } = payload;

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
        count: meta?.totalCount || 0
      };

    case `${CREATE_BATCH}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, false, true);
    }

    case `${UPDATE_BATCH}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${DELETE_BATCH}_SUCCESS`: {
      const { dataIds } = payload;
      const filterId = dataIds[0];
      return updateDataOnDelete(store, filterId);
    }
    case UPDATE_BATCH_DETAILS_SEARCH_PARAMS: {
      const { batchName, courseId, fromDate, toDate, status } = payload;

      return {
        ...store,
        onceFetched: false,
        currentBatchName: batchName,
        currentCourseId: courseId,
        currentFromDate: fromDate,
        currentToDate: toDate,
        currentStatus: status
      };
    }

    case RESET_TO_BATCH_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentBatchName: '',
        currentCourseId: '',
        currentFromDate: '',
        currentToDate: '',
        currentStatus: ''
      };
    }

    default:
      return store;
  }
};
export default view;
