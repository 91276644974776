import React from 'react';

import Icon from '../Icon';
import useInput from './useInput';

import styles from './Input.module.css';

const TextArea = ({ fillParent = false, ...otherProps }) => {
  const {
    containerClass,
    inputClass,
    iconClass,
    icon,
    restInputProps
  } = useInput(otherProps);

  return (
    <div className={`${containerClass} ${fillParent ? styles.fill : ''}`}>
      <textarea
        {...restInputProps}
        className={`${inputClass} ${styles.text_area}`}
      />

      {!!icon && <Icon className={iconClass} name={icon} />}
    </div>
  );
};

export default TextArea;
