import { FETCH_ATTENDACE, RESET_ATTENDANCE } from '../actionTypes';
import { createNewStoreOnGetData, createInitialStoreState } from 'helper';

const initialState = createInitialStoreState();

export const classAttendanceReducer = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_ATTENDACE}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case RESET_ATTENDANCE:
      return initialState;

    default:
      return store;
  }
};
