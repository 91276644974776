import React, { useMemo, useCallback } from 'react';
import { createMonthMatrix } from 'helper/datePicker';

import styles from './DatePicker.module.css';

const MonthRow = ({ data = [], row, onMonthChange, currentMonth }) => {
  const onChange = useCallback(
    (e) => {
      const { month } = e.target.dataset;
      onMonthChange(month);
    },
    [onMonthChange]
  );

  return (
    <div className={styles.month_row}>
      {data.map((month, index) => {
        return (
          <span
            data-month={month.value || ''}
            onClick={month ? onChange : null}
            role="button"
            className={`${month ? styles.month_cell : styles.cell} ${
              month && month.value == currentMonth && styles.month_cell_active
            }`}
            key={`monthCell_${row}_${index}`}>
            {month.name || ''}
          </span>
        );
      })}
    </div>
  );
};

const Months = ({ onMonthChange, currentMonth }) => {
  const { months } = useMemo(() => {
    return { months: createMonthMatrix({ rows: 5, columns: 4 }) };
  }, []);
  return (
    <div className={styles.years_box}>
      {months.map((monthRow, index) => {
        return (
          <MonthRow
            key={`monthRow_${index}`}
            onMonthChange={onMonthChange}
            data={monthRow}
            row={index}
            currentMonth={currentMonth}
          />
        );
      })}
    </div>
  );
};

export default Months;
