import {
  COLUMNS_DISPLAYING_USER_INFO,
  COLUMNS_DISPLAYING_COURSE_INFO
} from '../Constants';
import { validateColorCode } from './validators';

export const transform2dMatrixTo1D = (columns, data) => {
  const result = [];
  columns.forEach((columnName, index) => {
    let rows = data[index];

    if (Array.isArray(data[index])) {
      let headings;
      const row = [];
      data[index].forEach((item) => {
        const res = transform2dMatrixTo1D([columnName], [item]);
        headings = res[0].result[0];
        row.push(res[0].result ? res[0].result[1] : []);
      });
      rows = [headings, ...row];
    } else if (typeof data[index] === 'object') {
      const headings = [];
      const row = [];
      Object.keys(data[index]).forEach((key) => {
        headings.push(key);
        row.push(data[index][key]);
      });
      rows = [headings, row];
    }

    result.push({
      heading: columnName,
      isTable: typeof data[index] === 'object',
      result: rows
    });
  });
  return result;
};

export const getTableCellDisplayString = (children, data, row, columnName) => {
  const rowAndColumnExists = !!(row > 0 && columnName);
  const columnNameLower = columnName?.toLowerCase();

  if (children) {
    return 'display-children';
  } else if (
    rowAndColumnExists &&
    COLUMNS_DISPLAYING_USER_INFO.includes(columnNameLower)
  ) {
    return 'created-by-popover';
  } else if (
    rowAndColumnExists &&
    COLUMNS_DISPLAYING_COURSE_INFO.includes(columnNameLower)
  ) {
    return 'course-popover';
  } else if (rowAndColumnExists && columnName === ' Sl   No') {
    return 'icons';
  } else if (rowAndColumnExists && columnNameLower === 'status') {
    return 'status-cell';
  } else if (
    rowAndColumnExists &&
    columnNameLower === 'color code' &&
    validateColorCode(data)
  ) {
    return 'color-code-cell';
  } else if (typeof data === 'object') {
    return 'object-popover';
  }
  return 'text';
};
