import {
  FETCH_CLASS_ALL_STUDENTS,
  CLEAR_CLASS_ALL_STUDENTS
} from '../actionTypes';
import { createNewStoreOnGetData, createInitialStoreState } from 'helper';

const initialState = createInitialStoreState();

export const classStudentsReducer = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_CLASS_ALL_STUDENTS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case CLEAR_CLASS_ALL_STUDENTS: {
      return initialState;
    }

    default:
      return store;
  }
};
