import { SERVER_URL } from '../config.local';
import { TOKEN_LOCAL_NAME } from '../Constants';

const convertObjectToFormData = (object, formData, prevKey) => {
  Object.keys(object).forEach((key) => {
    const item = object[key];
    const isFile = Object.prototype.toString.call(item) === '[object File]';
    const newKey = prevKey ? `${prevKey}[${key}]` : `${key}`;
    if (Array.isArray(item)) {
      appendArrayToFormData(formData, item, newKey);
    } else if (!isFile && typeof item === 'object') {
      convertObjectToFormData(item, formData, newKey);
    } else {
      formData.append(newKey, item);
    }
  });
};

const appendArrayToFormData = (formData, array = [], key) => {
  const newKey = `${key}[]`;

  if (array.length === 0) {
    formData.append(`${key}`, '');
  } else {
    array.forEach((item) => {
      const isFile = Object.prototype.toString.call(item) === '[object File]';
      if (Array.isArray(item)) {
        appendArrayToFormData(formData, item, newKey);
      } else if (!isFile && typeof item === 'object') {
        convertObjectToFormData(item, formData, newKey);
      } else {
        formData.append(newKey, item);
      }
    });
  }
};

const requestData = (url, options) => {
  const {
    body = {},
    isFormData,
    isBlob = false,
    method,
    ...otherOptions
  } = options;

  const newOptions = {
    ...otherOptions,
    method,
    headers: {}
  };

  if (method === 'POST') {
    newOptions.body = JSON.stringify(body);
  }

  if (isFormData) {
    const formData = new FormData();
    convertObjectToFormData(body, formData, '');
    newOptions.body = formData;
  }

  const token = window.sessionStorage.getItem(TOKEN_LOCAL_NAME);
  if (token) {
    newOptions.headers = {
      ...newOptions.headers,
      Authorization: `Bearer ${token}`
    };
  }

  return fetch(`${SERVER_URL}${url}`, newOptions)
    .then((res) => {
      if (res.status >= 400 || res.status >= 500) {
        return res.json();
      } else if (isBlob) {
        return res.blob();
      } else {
        return res.json();
      }
    })
    .then((response) => {
      if (isBlob) {
        return { blobUrl: URL.createObjectURL(response) };
      }

      if (response.errors || response.error) {
        if (typeof response.error === 'string') {
          throw response.error;
        }

        if (typeof response.errors === 'string') {
          throw response.errors;
        }

        throw response.meta && response.meta.message
          ? response.meta.message
          : 'Something went wrong';
      }

      return response;
    });
};

export default requestData;
