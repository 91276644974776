import React, { useCallback, useEffect } from 'react';

import usePagination from 'hooks/usePagination';
import MultipleSelect from '../MultipleSelect';

const AsyncMultipleSelect = ({
  pageConfig = { api: '', tableName: '' },
  pageProps = {},
  options,
  ...restProps
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages
  } = usePagination({
    actionCreator: pageConfig.api,
    tableName: pageConfig.tableName
  });

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      ...pageProps,
      isFirstPage: true
    });
  }, [getNextPage, pageProps]);

  const fetchNextPage = useCallback(() => {
    getNextPage(pageProps);
  }, [getNextPage, pageProps]);

  const onEndReached = useCallback(() => {
    if (currentPageNumber === totalPages) {
      return;
    }
    fetchNextPage();
  }, [fetchNextPage, currentPageNumber, totalPages]);

  useEffect(() => {
    if (Array.isArray(options) && options.length === 0) {
      fetchFirstPage();
    }
  }, []);

  return (
    <MultipleSelect
      onEnd={onEndReached}
      options={options}
      isLoading={loadingNextPage || loadingPrevPage}
      {...restProps}
    />
  );
};

export default AsyncMultipleSelect;
