import React from 'react';
import { SelectItem } from './SelectItem';

import styles from './TimePicker.module.css';

export const List = ({ list = [], type, currentData, onClick }) => {
  return (
    <div className={styles.selectList}>
      {list.map((data) => (
        <SelectItem
          data={data}
          onClick={onClick}
          type={type}
          currentData={currentData}
        />
      ))}
    </div>
  );
};
