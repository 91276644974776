import {
  FETCH_INACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES,
  RESET_TO_UNREGISTERED_PARTICIPANT_DETAILS_PREV_DATA,
  RESET_WORKSHOP_PARTICIPANTS_DATA,
  SEARCH_UNREGISTERED_PARTICIPANT_DETAILS,
  UPDATE_UNREGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS
} from '../../../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../../../Helper/reducer';

const initialState = createInitialStoreState({
  totalRegistrations: null,
  onceFetched: false,
  currentContactData: '',
  currentCollege: '',
  currentCity: '',
  currentBranch: '',
  currentState: '',
  count: 0
});

const unregistered = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_UNREGISTERED_PARTICIPANT_DETAILS}_SUCCESS`:
    case `${FETCH_INACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(payload, {
        ...store,
        count: meta?.totalCount || 0
      });
    }
    case RESET_WORKSHOP_PARTICIPANTS_DATA: {
      return {
        ...initialState
      };
    }

    case UPDATE_UNREGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS: {
      const { contactData, college, city, branch, state } = payload;

      return {
        ...store,
        onceFetched: false,
        currentContactData: contactData,
        currentCollege: college,
        currentCity: city,
        currentBranch: branch,
        currentState: state
      };
    }

    case RESET_TO_UNREGISTERED_PARTICIPANT_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentContactData: '',
        currentCollege: '',
        currentCity: '',
        currentBranch: '',
        currentState: ''
      };
    }

    default:
      return store;
  }
};

export default unregistered;
