import { GET_COURSE_SYLLABUS, UPDATE_SYLLABUS_REMARKS } from '../../Actions';
import { createRelationIterator } from '../../Helper';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState();

const syllabus = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_COURSE_SYLLABUS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${UPDATE_SYLLABUS_REMARKS}_SUCCESS`: {
      const { data, rowId, relation, dataIds } = payload;
      const newData = { ...store.data };
      const currentSyllabus = data[dataIds[0]];
      if (
        newData[rowId].relationships &&
        newData[rowId].relationships.remarks
      ) {
        const item = newData[rowId].relationships.remarks.data;
        if (Array.isArray(item)) {
          newData[rowId].relationships.remarks.data.push({
            id: currentSyllabus.id,
            type: currentSyllabus.type
          });
        }
      }

      const newRelation = { ...store.relation, ...relation };
      const key = `${currentSyllabus.id}_${currentSyllabus.type}`;
      newRelation[key] = {
        ...currentSyllabus,
        [Symbol.iterator]: createRelationIterator()
      };

      return {
        ...store,
        relation: newRelation,
        data: newData
      };
    }

    default:
      return store;
  }
};

export default syllabus;
