import React from 'react';

import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
const mailRoute = Routes.authorized.mail;

const tabLinks = Object.keys(mailRoute.routes).map(
  (key) => mailRoute.routes[key].path
);

const LinkComponents = Object.keys(mailRoute.routes).map((key) => {
  const { path, drawerTitle, accessType } = mailRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const MailTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={mailRoute.accessType}>
      <DrawerTitle title="Mail" />
      <DrawerTab icon="far fa-user" title="Mail" links={tabLinks}>
        {LinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default MailTab;
