import styled from 'styled-components';
import Icon from '../Icon';
import Button from '../Button';

const getHeight = (props) => {
  const { size, fillParent } = props;
  if (fillParent) {
    return '100%';
  }
  switch (size) {
    case 'sm':
      return '30px';
    case 'md':
      return '50px';
    case 'lg':
      return '80px';
    default:
      return 'auto';
  }
};

const getBorder = (props) => {
  const { success, error, theme } = props;
  const {
    colors: { alert, input }
  } = theme;
  if (success) {
    return `2px ${alert.success} solid !important`;
  } else if (error) {
    return `2px ${alert.error} solid !important`;
  } else {
    return `1px ${input.primary.borderColor} solid `;
  }
};

const getIconColor = (props) => {
  const { success, error, theme } = props;
  const {
    colors: { alert }
  } = theme;
  if (success) {
    return alert.success;
  } else if (error) {
    return alert.error;
  } else {
    return 'black';
  }
};

export const InputContainer = styled.div`
  --input-padding: 10px;
  width: 100%;
  display: block;
  position: relative;
  border-radius: 5px;
  margin-bottom: 6px;
  height: ${getHeight};
`;

export const GlobalInput = styled.input`
  width: 100%;
  padding: 10px;
  height: 100%;
  border: ${getBorder};
  border-radius: 5px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.input.textColor};
  box-sizing: border-box;
  display: block;
  ${(props) => {
    if (props.secondary) {
      return `
            background-color: white;
            border: 1px ${props.theme.colors.input.secondary.borderColor} solid;
            border-radius: 3px;
            color: ${props.theme.colors.input.secondary.textColor};
            font-weight: 500;
          `;
    }

    return ``;
  }}

  ${(props) => {
    if (props.isTextArea) {
      return `
      resize: none;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          `;
    }

    return ``;
  }}
`;

export const InputIcon = styled(Icon)`
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 15px;
  color: ${getIconColor};
`;

export const ClearButton = styled(Button)`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  right: 10px;
  top: 10px;
`;

export const SelectOption = styled.option`
  padding: 10px;
  font-size: ${(props) => props.theme.text.para.medium};
`;

export const SecondaryInputIcon = styled(InputIcon)`
  right: 35px;
  color: ${(props) => props.theme.colors.darkGrayColor};
`;
