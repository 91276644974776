import React from 'react';

import styles from './RadioGroup.module.css';
const Radio = ({
  name,
  disabled,
  value,
  label,
  checked = false,
  ...restProps
}) => {
  return (
    <div className={styles.radio_cont}>
      <input
        className={styles.input_radio}
        {...restProps}
        type="radio"
        id={label}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label
        className={`${styles.radio_box} ${disabled ? styles.disabled : ''}`}
        htmlFor={!disabled ? label : ''}>
        <div className={styles.circle_out}>
          <div className={styles.overlay} />
          <div
            className={`${styles.circle_box} ${
              checked ? styles.active_circle_box : ''
            }`}>
            {checked && <div className={styles.circle} />}
          </div>
        </div>

        <span className={styles.radio_label}>{label}</span>
      </label>
    </div>
  );
};
export default Radio;
