import { FETCH_REGISTERED_AUDIENCES } from '../../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../../Helper/reducer';

const initialState = createInitialStoreState({}, true);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_REGISTERED_AUDIENCES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store, true);

    default:
      return store;
  }
};

export default view;
