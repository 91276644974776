import React from 'react';

import AuthorizedRoutes from './AuthorizedRoutes';

import Navbar from '../Components/Navbar';
import Drawer from '../Components/Drawer';

import styles from './Routes.module.css';
const MainRoute = () => {
  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.drawerContainer}>
        <Drawer />
      </div>
      <div className={styles.content}>
        <AuthorizedRoutes />
      </div>
    </div>
  );
};

export default MainRoute;
