import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './Theme';

import MainRoute from './Routes';
import Alert from 'components/Alert';

import store from './Store';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <MainRoute />
          <Alert />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
