import styled from 'styled-components';

const getBorder = (props) => {
  const { success, error, theme } = props;
  const {
    colors: { alert, input }
  } = theme;
  if (success) {
    return `2px ${alert.success} solid !important`;
  } else if (error) {
    return `2px ${alert.error} solid !important`;
  } else {
    return `1px ${input.primary.borderColor} solid `;
  }
};

export const PlaceholderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  position: relative;
  background-color: white;
  align-items: center;
  font-size: ${(props) => props.theme.text.para.small};
  cursor: pointer;
  border: ${getBorder};
  border-radius: 5px;

  ${(props) => {
    if (props.secondary) {
      return `
            background-color: white;
            border: 1px ${props.theme.colors.input.secondary.borderColor} solid;
            border-radius: 3px;
            color: ${props.theme.colors.input.secondary.textColor};
            font-weight: 500;
          `;
    }

    return ``;
  }}
`;

export const CountContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: bold;
  top: 35%;
  right: ${(props) => (props.moveLeft ? '40px' : '10px')};
`;

export const DropIconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: bold;
  top: 35%;
  right: ${(props) => (props.moveLeft ? '40px' : '10px')};
`;

export const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30%;
  right: ${(props) => (props.moveLeft ? '40px' : '10px')};
`;

export const ListGroupContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  ${({ isInBottom }) => {
    if (isInBottom) {
      return ` top: calc(100% + 5px);`;
    }
    return ` bottom: calc(100% + 5px);`;
  }}
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.shadow.medium};
  background-color: white;
  max-height: 200px;
  overflow-y: scroll;
  z-index: 10000;
`;

export const ListItemContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  &:hover {
    background-color: lightblue;
    cursor: pointer;
  }
`;

export const SearchBoxContainer = styled.div`
  padding: 10px;
`;

export const ListItemCheckBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px lightgray solid;
  border-radius: 3px;
  font-size: ${(props) => props.theme.text.para.extraSmall};
`;

export const ListItemText = styled.span`
  font-size: ${(props) => props.theme.text.para.medium};
  margin-left: 10px;
`;

export const EmptyResultBox = styled.div`
   {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const EmptyResultText = styled.div`
   {
    font-size: ${(props) => props.theme.text.para.small};
    color: ${(props) => props.theme.colors.darkGrayColor};
  }
`;
