import { useState, useCallback, useEffect, useMemo } from 'react';
import useInput from '../useInput';
import { Views } from './constants';

export const useDatePicker = ({ ...otherprops }) => {
  const [isActive, toggleActive] = useState(false);
  const [currentView, updateView] = useState(Views['DAY']);
  const [currentDate, updateDate] = useState(() => ({
    currentYear: new Date().getFullYear(),
    currentMonth: new Date().getMonth(),
    currentDay: new Date().getDate()
  }));

  const [pageYear, updatePageYear] = useState({
    pageCurrentYear: 2020,
    pageLastYear: 2039
  });
  const {
    containerClass,
    inputClass,
    iconClass,
    icon,
    restInputProps
  } = useInput(otherprops);
  const { onChange, value, placeholder = '', ...rest } = restInputProps;

  const toggleDatePicker = useCallback(
    (event) => {
      toggleActive((val) => !val);
    },
    [isActive]
  );

  useEffect(() => {
    if (!value) {
      return;
    }
    const date = new Date(value);
    updateDate({
      currentYear: date.getFullYear(),
      currentMonth: date.getMonth(),
      currentDay: date.getDate()
    });
  }, [value]);

  const SwitchToMonthView = useCallback(() => {
    updateView(Views['MONTH']);
  }, []);

  const SwitchToDayView = useCallback(() => {
    updateView(Views['DAY']);
  }, []);

  const toggleYearAndDayView = useCallback(() => {
    updateView((prevData) => {
      return prevData === Views['DAY'] ? Views['YEAR'] : Views['DAY'];
    });
  }, []);

  const calcNextMonth = useCallback(() => {
    if (currentDate.currentMonth === 11) {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: 0,
        currentYear: prevData.currentYear + 1
      }));
    } else {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: prevData.currentMonth + 1
      }));
    }
  }, [currentDate.currentMonth]);

  const calcPrevMonth = useCallback(() => {
    if (currentDate.currentMonth === 0) {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: 11,
        currentYear: prevData.currentYear - 1
      }));
    } else {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: prevData.currentMonth - 1
      }));
    }
  }, [currentDate.currentMonth]);

  const calcNextSetOfYears = useCallback(() => {
    updatePageYear((prevData) => ({
      pageCurrentYear: prevData.pageLastYear + 1,
      pageLastYear: prevData.pageLastYear + 20
    }));
  }, []);

  const calcPrevSetOfYears = useCallback(() => {
    updatePageYear((prevData) => ({
      ...prevData,
      pageCurrentYear: prevData.pageCurrentYear - 20,
      pageLastYear: prevData.pageCurrentYear - 1
    }));
  }, []);

  const onYearChange = useCallback(
    (year) => {
      updateDate((prevData) => ({
        ...prevData,
        currentYear: Number(year)
      }));

      SwitchToMonthView();
    },
    [SwitchToMonthView]
  );

  const onDayChange = useCallback(
    (day) => {
      updateDate((prevData) => ({
        ...prevData,
        currentDay: Number(day)
      }));

      if (typeof onChange !== 'function') {
        return;
      }

      onChange({
        target: {
          value: new Date(
            currentDate.currentYear,
            currentDate.currentMonth,
            day
          ).toString()
        }
      });

      toggleDatePicker();
    },
    [
      currentDate.currentMonth,
      currentDate.currentYear,
      onChange,
      toggleDatePicker
    ]
  );

  const onMonthChange = useCallback(
    (month) => {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: Number(month)
      }));
      SwitchToDayView();
    },
    [SwitchToDayView]
  );

  const dateText = useMemo(() => {
    const { currentDay, currentMonth, currentYear } = currentDate;
    return value
      ? `${currentDay}/${currentMonth + 1}/${currentYear}`
      : placeholder;
  }, [currentDate, placeholder, value]);

  return {
    dateText,
    onMonthChange,
    onDayChange,
    onYearChange,
    calcPrevSetOfYears,
    calcNextSetOfYears,
    calcPrevMonth,
    toggleYearAndDayView,
    calcNextMonth,
    currentView,
    pageYear,
    containerClass,
    inputClass,
    iconClass,
    icon,
    isActive,
    toggleDatePicker,
    currentDate,
    ...rest
  };
};
