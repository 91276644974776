import React, { useCallback, useMemo } from 'react';
import ActivityIndicator from '../../ActivityIndicator';

import EmptyResult from './EmptyResult';

import { ListItem, ListHeader } from '../../List';
import { ListGroupContainer, LoadingContainer } from './style';

const ListGroup = React.forwardRef((props, ref) => {
  const {
    data = [],
    selectedData = {},
    onChange,
    isInBottom,
    isLoading,
    onEnd,
    showSelectAll,
    onToggleSelectAll
  } = props;

  const onScroll = useCallback(
    (e) => {
      const { scrollTop, scrollHeight } = e.target;
      const { height } = e.target.getBoundingClientRect();
      if (scrollTop + height === scrollHeight && typeof onEnd === 'function') {
        onEnd();
      }
    },
    [onEnd]
  );

  const list = useMemo(() => {
    const newList = [];
    const isAllSelected =
      selectedData && Object.keys(selectedData).length === data.length;
    if (showSelectAll) {
      newList.push(
        <ListHeader
          key="list_header_0"
          data={{
            heading: 'Select All'
          }}
          selected={isAllSelected}
          onClick={onToggleSelectAll}
        />
      );
    }

    data.forEach((item) => {
      newList.push(
        <ListItem
          key={item.id}
          data={item}
          onSelect={onChange}
          selected={selectedData && selectedData[item.id]}
        />
      );
    });
    return newList;
  }, [data, selectedData, showSelectAll]);

  return (
    <ListGroupContainer isInBottom={isInBottom} ref={ref} onScroll={onScroll}>
      {isLoading && (
        <LoadingContainer>
          <ActivityIndicator size="s" color="#3f6ad8" />
        </LoadingContainer>
      )}
      {!isLoading && data.length === 0 && <EmptyResult />}
      {!isLoading && data.length > 0 && list}
    </ListGroupContainer>
  );
});

export default ListGroup;
