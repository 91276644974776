import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import Placeholder from './Placeholder';
import ListGroup from './ListGroup';

import useInput from '../useInput';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { createOuterClickListner } from 'helper';

import { InputContainer, InputIcon } from '../style';

const MultipleSelect = ({
  size = 'md',
  success = '',
  secondary = false,
  error = '',
  options = [],
  placeholder,
  placehoderProps = {},
  value,
  onChange,
  isSearchable = false,
  isLoading = false,
  isMultiple = true,
  onEnd = () => {},
  ...otherprops
}) => {
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error
  });
  success = success ? 'true' : undefined;
  error = error ? 'true' : undefined;

  const { dimesnions } = useWindowDimensions();
  const [isListOpen, updateListStatus] = useState(false);
  const [isInBottom, toggleBottomPosition] = useState(true);
  const removeClickListnerRef = useRef(null);
  const popupRef = useRef(null);
  const popupBtnRef = useRef(null);

  const toggleList = useCallback(() => {
    updateListStatus((val) => !val);
  }, []);

  const onClickOutside = useCallback((e) => {
    if (e.target.contains(popupRef.current)) {
      toggleList();
    }
  }, []);

  /**
   * Updates the position of datePicker popup box according to the window dimensions
   */
  const updatePosition = useCallback(() => {
    if (!popupRef.current) {
      return;
    }
    const { height } = popupRef.current.getBoundingClientRect();
    const {
      top,
      height: inputHeight
    } = popupBtnRef.current.getBoundingClientRect();
    if (top + inputHeight + height > dimesnions.height) {
      toggleBottomPosition(false);
    } else {
      toggleBottomPosition(true);
    }
  }, []);

  const setPopupRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    popupRef.current = ref;
    updatePosition();
  }, []);

  useEffect(() => {
    updatePosition();
  }, [dimesnions]);

  // setting outer listner when list gets open
  useEffect(() => {
    if (!isListOpen) {
      if (removeClickListnerRef.current) {
        removeClickListnerRef.current();
      }
      return;
    }
    removeClickListnerRef.current = createOuterClickListner(onClickOutside);
  }, [isListOpen, onClickOutside]);

  const onListGroupClick = useCallback(
    (e) => {
      const { value: selectedId } = e.target.dataset;
      if (typeof onChange === 'function') {
        const newValue = isMultiple ? { ...value } : {};
        if (newValue[selectedId]) {
          delete newValue[selectedId];
        } else {
          newValue[selectedId] = true;
        }
        onChange({ target: { value: newValue } });
      }

      if (!isMultiple) {
        toggleList();
      }
    },
    [onChange, value, isMultiple]
  );

  const slectedData = useMemo(() => {
    return options.filter((item) => value[item.value]);
  }, [value, options]);

  return (
    <InputContainer className="ab_multi_select" size={size}>
      <Placeholder
        {...placehoderProps}
        ref={popupBtnRef}
        secondary={secondary}
        data={slectedData}
        placeholder={placeholder}
        onClick={toggleList}
        moveLeft={icon}
        success={success}
        error={error}
        isLoading={isLoading}
        maxCharOnChip={isMultiple ? 5 : 40}
      />
      {!!icon && <InputIcon success={success} error={error} name={icon} />}

      {isListOpen && (
        <ListGroup
          isInBottom={isInBottom}
          ref={setPopupRef}
          data={options}
          onEnd={onEnd}
          isMultiple={isMultiple}
          selectedData={value}
          onChange={onListGroupClick}
          isSearchable={isSearchable}
        />
      )}
    </InputContainer>
  );
};

export default MultipleSelect;
