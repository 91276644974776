import { useMemo } from 'react';

import styles from './Input.module.css';

const useInput = ({
  success = '',
  secondary = false,
  error = '',
  size = 'medium',
  className = '',
  setClearButton = false,
  containerClass = '',
  clearField = () => {},
  ...otherprops
}) => {
  const { style, icon, iconColor } = useMemo(() => {
    let style = '';
    let icon = '';
    let iconColor = '';
    if (success) {
      style += ` ${styles.success}`;
      icon = 'fas fa-check-circle';
      iconColor = styles.icon_success;
    } else if (error) {
      style += ` ${styles.error}`;
      icon = 'fas fa-exclamation-circle';
      iconColor = styles.icon_error;
    } else {
      style = '';
      icon = '';
      iconColor = '';
    }

    if (secondary) {
      style += ` ${styles.secondary}`;
    }

    return { style, icon, iconColor };
  }, [success, error, secondary]);

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'small':
        return styles.sm;
      case 'medium':
        return styles.md;
      case 'large':
        return styles.lg;
      case 'auto':
        return styles.at;
      default:
        return styles.md;
    }
  }, [size]);

  return {
    containerClass: `${styles.container} ${sizeClass} ${containerClass}`,
    inputClass: `${styles.input} ${className} ${style}`,
    iconClass: `${styles.icon} ${iconColor} ${otherprops.iconClass}`,
    icon: setClearButton ? false : icon,
    restInputProps: otherprops,
    showClearButton: setClearButton,
    clearButtonClass: styles.clear_btn,
    onClear: clearField,
    size
  };
};

export default useInput;
