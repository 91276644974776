import React from 'react';
import { useSelector } from 'react-redux';
import { getAccessType } from '../../../Selectors/auth';
import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
const studentRuote = Routes.authorized.studentManagement;

const tabLinks = Object.keys(studentRuote.routes).map(
  (key) => studentRuote.routes[key].path
);

const LinkComponents = Object.keys(studentRuote.routes).map((key) => {
  const { path, drawerTitle, accessType } = studentRuote.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const StudentManagementTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={studentRuote.accessType}>
      <DrawerTab icon="fas fa-user-graduate" title="Student" links={tabLinks}>
        {LinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default StudentManagementTab;
