import React, { useMemo } from 'react';

import useInput from './useInput';

import Icon from '../Icon';
import { SecondaryInputIcon } from './style';
import Button from '../Button';

import usePasswordPreviewer from '../../Hooks/usePasswordPreview';

const Input = ({ onGivenKeyPressed = null, ...otherprops }) => {
  const {
    containerClass,
    inputClass,
    iconClass,
    icon,
    restInputProps,
    showClearButton,
    clearButtonClass,
    onClear
  } = useInput(otherprops);

  const { type } = restInputProps;

  const {
    getFieldType,
    showPassword,
    toggleShowPassword
  } = usePasswordPreviewer();

  const [fieldType, isPasswordType] = useMemo(() => getFieldType(type), [
    getFieldType
  ]);

  return (
    <div className={containerClass}>
      <input {...restInputProps} className={inputClass} type={fieldType} />
      {isPasswordType && restInputProps?.value.length > 0 && (
        <SecondaryInputIcon
          name={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
          onClick={toggleShowPassword}
        />
      )}
      {!!icon && <Icon className={iconClass} name={icon} />}
      {showClearButton && (
        <Button
          secondary
          type="button"
          size="auto"
          className={clearButtonClass}
          onClick={onClear}>
          <Icon name="fas fa-times" />
        </Button>
      )}
    </div>
  );
};

export default Input;
