import { useSelector } from 'react-redux';

import { getUserData } from '../Selectors/auth';
const useUserInformation = () => {
  const userInfo = useSelector(getUserData);
  return {
    userInfo
  };
};

export default useUserInformation;
