import styled from 'styled-components';

export const CheckBoxGroupContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  align-items: ${(props) => (props.horizontal ? 'center' : 'flex-start')};
`;

export const CheckBoxWrapper = styled.div`
  width: auto;
`;

export const StyledCheckBox = styled.label`
  min-width: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) => {
    if (props.disabled) {
      return `
          cursor: not-allowed;
          color: lightgray;
          `;
    }
    return ``;
  }}
  &:hover > .circle_out > .overlay {
    transform: scale(2);
  }
`;

export const CheckBoxLabel = styled.span`
  margin-left: 10px;
  font-size: ${(props) => props.theme.text.para.small};
  text-transform: capitalize;
  text-align: left;
  white-space: nowrap;
`;

export const CircleWrapper = styled.div`
  width: ${(props) => props.theme.dimensions.checkBox.width};
  height: ${(props) => props.theme.dimensions.checkBox.height};
  position: relative;
`;

export const CircleBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;

  border: 1px
    ${(props) => {
      return props.checked
        ? props.theme.colors.checkBox.activeBorderColor
        : props.theme.colors.checkBox.unChecked.border;
    }}
    solid;
  z-index: 0;
  background-color: ${(props) => {
    return props.checked
      ? props.theme.colors.checkBox.checked.background
      : props.theme.colors.checkBox.unChecked.background;
  }};

  & i {
    font-size: 10px;
    font-weight: 800;
    color: ${(props) => props.theme.colors.checkBox.checked.color};
  }
`;

export const Circle = styled.div`
  width: calc(${(props) => props.theme.dimensions.checkBox.width} / 2);
  height: calc(${(props) => props.theme.dimensions.checkBox.height} / 2);
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.checkBox.activeBorderColor};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.checkBox.overlay};
  transform: scale(1);
  opacity: 0.3;
  transition: transform ease 0.3s;
`;
