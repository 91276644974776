import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAccessType } from '../../Selectors/auth';

import styles from './Drawer.module.css';
const DrawerLink = ({ path, title, requiredAccess }) => {
  const userAccessType = useSelector(getAccessType);
  const location = useLocation();
  const isActive = useMemo(() => {
    return path === location.pathname;
  }, [location.pathname, path]);

  let requiredTypes = [];
  if (typeof requiredAccess === 'string') {
    requiredTypes = [requiredAccess];
  }
  if (Array.isArray(requiredAccess)) {
    requiredTypes = [...requiredAccess];
  }

  if (requiredTypes.includes(userAccessType)) {
    if (!title) {
      return null;
    }

    return (
      <Link
        style={{
          textDecoration: 'none'
        }}
        className={`${styles.drawer_link} ${
          isActive ? styles.drawer_link_active : ''
        } `}
        to={path}>
        <span
          className={`${styles.drawer_link_title} ${
            isActive ? styles.drawer_link_title_active : ''
          } `}>
          {title}
        </span>
      </Link>
    );
  }
  return null;
};

export default DrawerLink;
