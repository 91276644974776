import React, { useCallback } from 'react';

import useInput from '../useInput';

import styles from './ChipsGroup.module.css';
import Chip from './Chip';

const ChipsGroup = ({ options = [], groupName, ...otherprops }) => {
  const { restInputProps } = useInput(otherprops);
  const { value: values, onChange, ...remainingProps } = restInputProps;

  const onChipChange = useCallback(
    (e) => {
      const { value } = e.target;
      const newValues = {
        ...values
      };
      if (newValues[value]) {
        delete newValues[value];
      } else {
        newValues[value] = true;
      }
      onChange({
        target: {
          value: newValues
        }
      });
    },
    [onChange, values]
  );

  return (
    <div className={styles.container}>
      {options.map((item) => {
        return (
          <Chip
            key={item.value}
            name={groupName}
            value={item.value}
            label={item.name}
            disabled={item.disabled || false}
            checked={values[item.value]}
            onChange={onChipChange}
            {...remainingProps}
          />
        );
      })}
    </div>
  );
};

export default ChipsGroup;
