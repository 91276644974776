import {
  CLEAR_EMPLOYER_REPRESENTATIVES,
  FETCH_EMPLOYER_REPRESENTATIVES,
  UPDATE_EMPLOYER_REPRESENTATIVE,
  DELETE_EMPLOYER_REPRESENTATIVES
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
  updateDataOnDelete
} from '../../Helper/reducer';

const initialState = createInitialStoreState();

const employerRepresentativeReducer = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_EMPLOYER_REPRESENTATIVES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }
    case CLEAR_EMPLOYER_REPRESENTATIVES:
      return initialState;

    case `${UPDATE_EMPLOYER_REPRESENTATIVE}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${DELETE_EMPLOYER_REPRESENTATIVES}_SUCCESS`: {
      const { dataIds } = payload;
      const filterId = dataIds[0];
      return updateDataOnDelete(store, filterId);
    }

    default:
      return store;
  }
};

export default employerRepresentativeReducer;
