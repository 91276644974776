import {
  DELETE_ALL_EMAIL_REPORTS,
  DELETE_EMAIL_REPORTS,
  GET_EMAIL_REPORTS,
  RESET_TO_EMAIL_REPORTS_DETAILS_PREV_DATA,
  SEARCH_EMAIL_REPORTS,
  UPDATE_EMAIL_REPORTS_DETAILS_SEARCH_PARAMS
} from '../../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData,
  updateDataOnDelete
} from '../../../Helper/reducer';

const initialState = createInitialStoreState({
  selectedEmailId: '',
  selectedEditEmailId: '',
  totalCount: 0,
  onceFetched: false,
  currentEmail: '',
  currentSubject: ''
});

const mailReports = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${SEARCH_EMAIL_REPORTS}_SUCCESS`:
    case `${GET_EMAIL_REPORTS}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(payload, {
        ...state,
        totalCount: meta?.totalCount || 0
      });
    }

    case UPDATE_EMAIL_REPORTS_DETAILS_SEARCH_PARAMS: {
      const { email, subject } = payload;

      return {
        ...state,
        onceFetched: false,
        currentEmail: email,
        currentSubject: subject
      };
    }

    case RESET_TO_EMAIL_REPORTS_DETAILS_PREV_DATA: {
      return {
        ...state,
        onceFetched: false,
        currentEmail: '',
        currentSubject: ''
      };
    }

    case `${DELETE_EMAIL_REPORTS}_SUCCESS`: {
      const { fromId, toId } = payload;
      const { dataIds } = state;
      let newState = { ...state };
      for (let i = fromId; i <= toId; i++) {
        if (dataIds.includes(i?.toString())) {
          newState = updateDataOnDelete(newState, i?.toString());
        }
      }
      return { ...newState };
    }

    case `${DELETE_ALL_EMAIL_REPORTS}_SUCCESS`: {
      return { ...state, dataIds: [] };
    }

    default: {
      return state;
    }
  }
};

export default mailReports;
