import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../Reducers';
import generatorMiddleware from '../Middleware/generatorMiddleware';
import MTableMiddleware from '../Middleware/MTableMiddleware';

const middleware = [generatorMiddleware, thunk, MTableMiddleware];

if (process.env.NODE_ENV === 'development') {
  const logger = require('redux-logger').default;
  middleware.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middleware));

export const dummyStore = createStore((store) => {
  return {
    ...store,
    auth: {}
  };
});
export default store;
