import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import Routes from '../Constants/Routes';
import AdvancedRoute from '../Components/AdvancedRoute';
import ErrorBoundary from '../Components/ErrorBoundary';

const userManagement = Routes.authorized.userManagement.routes;
const courseManagement = Routes.authorized.courseManagement.routes;
const liveProjectsManagement = Routes.authorized.liveProjects.routes;
const courseLevelManagement = Routes.authorized.courseLevel.routes;
const supportManagement = Routes.authorized.support.routes;
const studentManagement = Routes.authorized.studentManagement.routes;
const studentSubscriptionManagement =
  Routes.authorized.studentSubscriptionManagement.routes;
const StudentSubscriptionDetailsManagement =
  Routes.authorized.studentSubscriptionDetailsManagement.routes;
const companyMentorsManagement =
  Routes.authorized.companyMentorManagement.routes;
const employerManagement = Routes.authorized.employerManagement.routes;
const workshopManagement = Routes.authorized.workshopManagement.routes;
const webinarManagement = Routes.authorized.webinarManagement.routes;

const mail = Routes.authorized.mail.routes;
const batchManagement = Routes.authorized.batch.routes;
const channelPartnersManagement = Routes.authorized.channelPartner.routes;

const UserViewPage = lazy(() => import('../Pages/UserManagement/View'));

const CourseCreatePage = lazy(() => import('../Pages/CourseManagement/Create'));
const CourseViewPage = lazy(() => import('../Pages/CourseManagement/View'));

const LiveProjectCreatePage = lazy(() =>
  import('../Pages/LiveProjects/Create')
);
const LiveProjectViewPage = lazy(() => import('../Pages/LiveProjects/View'));

const CourseLevelCreatePage = lazy(() => import('../Pages/CourseLevel/Create'));
const CourseLevelViewPage = lazy(() => import('../Pages/CourseLevel/View'));

const EnquiryPage = lazy(() => import('../Pages/Support/Enquiry'));
const SyllabusViewPage = lazy(() => import('../Pages/Support/Syllabus'));

const StudentCreatePage = lazy(() => import('../Pages/Student/Create'));
const StudentViewPage = lazy(() => import('../Pages/Student/View'));

const SubscribedStudentsViewPage = lazy(() =>
  import('../Pages/Student/SubscribedStudents')
);

const SingleSubscribedStudentsViewPage = lazy(() =>
  import('../Pages/Student/SubscribedStudents/SingleSubscribedStudent')
);

const MemorendumPage = lazy(() => import('../Pages/Memorendum/views/Form'));

const StudentSubscriptionCreatePage = lazy(() =>
  import('../Pages/StudentSubscription/Create')
);
const SubscriptionsViewPage = lazy(() => import('../Pages/Subscriptions/View'));

const CompanyMentorsCreatePage = lazy(() =>
  import('../Pages/CompanyMentors/Create')
);
const CompanyMentorsViewPage = lazy(() =>
  import('../Pages/CompanyMentors/View')
);
const SubscriptionDetailsManagement = lazy(() =>
  import('../Pages/StudentSubscriptionDetails/View')
);

const EmployerCreatePage = lazy(() => import('../Pages/Employer/Create'));
const EmployerViewPage = lazy(() => import('../Pages/Employer/View'));

const CreateMailPage = lazy(() => import('../Pages/Mail/CreateMail'));
const editMailPage = lazy(() => import('../Pages/Mail/EditMail'));
const sendMailPage = lazy(() => import('../Pages/Mail/SendMail'));
const scheduleMailPage = lazy(() => import('../Pages/Mail/ScheduleMail/index'));
const viewReportsPage = lazy(() => import('../Pages/Mail/ViewReports'));

const WorkshopCreatePage = lazy(() => import('../Pages/Workshop/Create'));
const WorkshopActivePage = lazy(() => import('../Pages/Workshop/Active'));
const WorkshopInactivePage = lazy(() => import('../Pages/Workshop/Inactive'));
const WorkshopSearchByPage = lazy(() => import('../Pages/Workshop/SearchBy'));
const WebinarViewPage = lazy(() => import('../Pages/Webinar/View'));

const BatchCreatePage = lazy(() => import('../Pages/Batch/Create'));
const BatchViewPage = lazy(() => import('../Pages/Batch/View'));

const ChannelPartnersCreate = lazy(() =>
  import('../Pages/ChannelPartners/Create')
);
const ChannelPartnersView = lazy(() => import('../Pages/ChannelPartners/View'));
const CallbackRequestView = lazy(() => import('../Pages/CallBackRequest/View'));

const Error404Page = lazy(() => import('../Pages/Error404'));

const Fallback = () => <p>Loading Page ...</p>;

const AuthorizedRoutes = () => {
  return (
    <ErrorBoundary type="page">
      <Suspense fallback={<Fallback />}>
        <Switch>
          <AdvancedRoute
            exact
            path={userManagement.viewRoute.path}
            accessType={userManagement.viewRoute.accessType}
            component={UserViewPage}
          />

          <AdvancedRoute
            exact
            path={courseManagement.createRoute.path}
            accessType={courseManagement.createRoute.accessType}
            component={CourseCreatePage}
          />

          <AdvancedRoute
            exact
            path={courseManagement.viewRoute.path}
            accessType={courseManagement.viewRoute.accessType}
            component={CourseViewPage}
          />

          <AdvancedRoute
            exact
            path={liveProjectsManagement.createRoute.path}
            accessType={liveProjectsManagement.createRoute.accessType}
            component={LiveProjectCreatePage}
          />

          <AdvancedRoute
            exact
            path={liveProjectsManagement.viewRoute.path}
            accessType={liveProjectsManagement.viewRoute.accessType}
            component={LiveProjectViewPage}
          />

          <AdvancedRoute
            exact
            path={courseLevelManagement.createRoute.path}
            accessType={courseLevelManagement.createRoute.accessType}
            component={CourseLevelCreatePage}
          />

          <AdvancedRoute
            exact
            path={courseLevelManagement.viewRoute.path}
            accessType={courseLevelManagement.viewRoute.accessType}
            component={CourseLevelViewPage}
          />

          <AdvancedRoute
            exact
            path={studentManagement.createRoute.path}
            accessType={studentManagement.createRoute.accessType}
            component={StudentCreatePage}
          />

          <AdvancedRoute
            exact
            path={studentManagement.viewRoute.path}
            accessType={studentManagement.viewRoute.accessType}
            component={StudentViewPage}
          />

          <AdvancedRoute
            exact
            path={studentManagement.subscribedRoute.path}
            accessType={studentManagement.subscribedRoute.accessType}
            component={SubscribedStudentsViewPage}
          />

          <AdvancedRoute
            exact
            path={`${studentManagement.subscribedDetailRoute.path}${studentManagement.subscribedDetailRoute.slug}`}
            accessType={studentManagement.subscribedDetailRoute.accessType}
            component={SingleSubscribedStudentsViewPage}
          />

          <AdvancedRoute
            exact
            path={studentManagement.subscribedMemorendumCreateRoute.path}
            accessType={
              studentManagement.subscribedMemorendumCreateRoute.accessType
            }
            component={MemorendumPage}
          />

          <AdvancedRoute
            exact
            path={studentManagement.subscribedMemorendumEditRoute.path}
            accessType={
              studentManagement.subscribedMemorendumEditRoute.accessType
            }
            component={MemorendumPage}
          />

          <AdvancedRoute
            exact
            path={studentSubscriptionManagement.createRoute.path}
            accessType={studentSubscriptionManagement.createRoute.accessType}
            component={StudentSubscriptionCreatePage}
          />

          <AdvancedRoute
            exact
            path={studentSubscriptionManagement.viewRoute.path}
            accessType={studentSubscriptionManagement.viewRoute.accessType}
            component={SubscriptionsViewPage}
          />

          <AdvancedRoute
            exact
            path={supportManagement.enquiryRoute.path}
            accessType={supportManagement.enquiryRoute.accessType}
            component={EnquiryPage}
          />

          <AdvancedRoute
            exact
            path={supportManagement.callbackRequestRoute.path}
            accessType={supportManagement.callbackRequestRoute.accessType}
            component={CallbackRequestView}
          />

          <AdvancedRoute
            exact
            path={supportManagement.syllabusRoute.path}
            accessType={supportManagement.syllabusRoute.accessType}
            component={SyllabusViewPage}
          />

          <AdvancedRoute
            exact
            path={companyMentorsManagement.viewRoute.path}
            accessType={companyMentorsManagement.viewRoute.accessType}
            component={CompanyMentorsViewPage}
          />

          <AdvancedRoute
            exact
            path={StudentSubscriptionDetailsManagement.viewRoute.path}
            accessType={
              StudentSubscriptionDetailsManagement.viewRoute.accessType
            }
            component={SubscriptionDetailsManagement}
          />

          <AdvancedRoute
            exact
            path={workshopManagement.searchByRoute.path}
            accessType={workshopManagement.searchByRoute.accessType}
            component={WorkshopSearchByPage}
          />

          <AdvancedRoute
            exact
            path={workshopManagement.createRoute.path}
            accessType={workshopManagement.createRoute.accessType}
            component={WorkshopCreatePage}
          />

          <AdvancedRoute
            exact
            path={workshopManagement.activeRoute.path}
            accessType={workshopManagement.activeRoute.accessType}
            component={WorkshopActivePage}
          />

          <AdvancedRoute
            exact
            path={workshopManagement.inactiveRoute.path}
            accessType={workshopManagement.inactiveRoute.accessType}
            component={WorkshopInactivePage}
          />

          <AdvancedRoute
            exact
            path={webinarManagement.viewRoute.path}
            accessType={webinarManagement.viewRoute.accessType}
            component={WebinarViewPage}
          />

          <AdvancedRoute
            exact
            path={employerManagement.viewRoute.path}
            accessType={employerManagement.viewRoute.accessType}
            component={EmployerViewPage}
          />
          <AdvancedRoute
            exact
            path={mail.createRoute.path}
            accessType={mail.createRoute.accessType}
            component={CreateMailPage}
          />
          <AdvancedRoute
            exact
            path={mail.editRoute.path}
            accessType={mail.editRoute.accessType}
            component={editMailPage}
          />
          <AdvancedRoute
            exact
            path={mail.sendRoute.path}
            accessType={mail.sendRoute.accessType}
            component={sendMailPage}
          />
          <AdvancedRoute
            exact
            path={mail.scheduleRoute.path}
            accessType={mail.scheduleRoute.accessType}
            component={scheduleMailPage}
          />
          <AdvancedRoute
            exact
            path={mail.viewRoute.path}
            accessType={mail.viewRoute.accessType}
            component={viewReportsPage}
          />
          <AdvancedRoute
            exact
            path={batchManagement.viewRoute.path}
            accessType={batchManagement.viewRoute.accessType}
            component={BatchViewPage}
          />
          <AdvancedRoute
            exact
            path={channelPartnersManagement.viewRoute.path}
            accessType={channelPartnersManagement.viewRoute.accessType}
            component={ChannelPartnersView}
          />
          <Route component={Error404Page} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AuthorizedRoutes;
