import React, { useMemo, useCallback, useState } from 'react';

import Icon from '../../Icon';
import useInput from '../useInput';
import useClipText from '../../../Hooks/useClipText';
import styles from './FilePicker.module.css';

const FileAttachment = ({ name }) => {
  const { clippedText } = useClipText({ text: name, maxChar: 10 });
  return (
    <div className={styles.attachment}>
      <Icon name="far fa-file" />
      <span className={styles.attach_text}>{clippedText}</span>
    </div>
  );
};

const FilePicker = (props) => {
  const {
    containerClass,
    inputClass,
    iconClass,
    icon,
    size,
    restInputProps
  } = useInput(props);

  const [maxWidth, updateMaxWidth] = useState('auto');

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'small':
        return styles.sm_f;
      case 'medium':
        return styles.md_f;
      case 'large':
        return styles.lg_f;
      default:
        return styles.md_f;
    }
  }, [size]);

  const { value, ...remainingProps } = restInputProps;
  const fileName = useMemo(() => {
    if (!value || value.length === 0) {
      return null;
    }

    const names = [];

    for (let i = 0; i < value.length; i++) {
      names.push(value[i].name);
    }

    return names;
  }, [value]);

  const setRef = useCallback((r) => {
    if (!r) {
      return;
    }
    updateMaxWidth(r.clientWidth);
  }, []);

  return (
    <div className={`${containerClass} ${sizeClass}`}>
      <label
        style={{ maxWidth }}
        ref={setRef}
        htmlFor={restInputProps.id}
        className={`${inputClass} ${styles.file_input}`}>
        {fileName ? (
          fileName.map((fileName) => (
            <FileAttachment key={fileName} name={fileName} />
          ))
        ) : (
          <>
            <Icon name="fas fa-cloud-upload-alt" />
            <span className={styles.upload_txt}>Upload File</span>
          </>
        )}
      </label>
      <input
        style={{ display: 'none' }}
        type="file"
        {...remainingProps}
        className={inputClass}
      />
      {!!icon && <Icon className={iconClass} name={icon} />}
    </div>
  );
};

export default FilePicker;
