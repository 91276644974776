import {
  CLEAR_CHANNEL_PARTNER_REPRESENTATIVES,
  FETCH_CHANNEL_PARTNER_REPRESENTATIVES
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState();

const channelPartnerRepresentativeReducer = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_CHANNEL_PARTNER_REPRESENTATIVES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);
    case CLEAR_CHANNEL_PARTNER_REPRESENTATIVES:
      return initialState;

    default:
      return store;
  }
};

export default channelPartnerRepresentativeReducer;
