export const TOKEN_LOCAL_NAME = 'education_AJ((@CI(*%#*&#';
export const USER_LOCAL_DATA = 'education_c9w87yAX*&T*&GQ*wdgiYGWSD';
export const CHANNEL_PARTNER_DATA =
  'education_7FtVLuPV5d/z+95L49TPQ3iYo8Q+qQKsHSrxli5ten8=';

export const activeOptions = [
  { name: 'Active', value: true },
  { name: 'Inactive', value: false }
];

export const priorityOptions = [
  {
    name: 'Low',
    value: 0
  },
  {
    name: 'Medium',
    value: 1
  },
  {
    name: 'High',
    value: 2
  }
];

export const MonthIndex = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];

export const RandColors = [
  '#EC5224',
  '#2474EC',
  '#24EC8C',
  '#AC24EC',
  '#EC2484',
  '#ECA824'
];

export const COLUMNS_DISPLAYING_USER_INFO = [
  'created by',
  'user',
  'company mentor',
  'student',
  'paid to',
  'remarked by',
  'employer',
  'modelable',
  'channel partner'
];

export const COLUMNS_DISPLAYING_COURSE_INFO = [
  'course',
  'course level',
  'student course',
  'batch course',
  'seminar'
];

export const STATUS_COLOUR_CODE = {
  ongoing: '#02C467',
  upcoming: '#FF9600',
  cancelled: '#FF0743',
  completed: '#0094E7',
  active: '#02C467',
  inactive: '#FF0743',
  in_progress: '#FF9600',
  essential: '#02C467',
  inessential: '#FF9600',
  absconded: '#FF0743'
};

export const Alphabets = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];
