import React, { useMemo } from 'react';
import Counter from '../Counter';
import {
  getNameInitials,
  getRandomColor,
  getImageUrl,
  getHowManyMore
} from 'helper';
import { Container, Pic, Initials, Dot } from './style';

const ProfilePic = ({
  img,
  userName,
  more,
  color,
  className,
  showDot,
  count,
  ...otherProps
}) => {
  const isImage = typeof img === 'string' && img !== 'NA' && img !== '';
  const initials = useMemo(() => {
    if (isImage) {
      return '';
    }
    if (more) {
      return getHowManyMore(more);
    }
    return getNameInitials(userName);
  }, [isImage, userName]);

  const background = useMemo(() => {
    if (isImage) {
      return 'white';
    }
    if (color) {
      return color;
    }
    return getRandomColor();
  }, [isImage, color]);

  return (
    <Container {...otherProps} className={className} $background={background}>
      {!!isImage && <Pic src={getImageUrl(img)} />}
      {!!initials && <Initials>{initials}</Initials>}
      {showDot && <Dot />}
      {!!count && <Counter count={count} />}
    </Container>
  );
};
ProfilePic.defaultProps = {
  className: '',
  color: null,
  showDot: false,
  count: 0
};

export default ProfilePic;
