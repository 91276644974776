import {
  FETCH_BATCH_CERTIFICATE,
  UPDATE_BATCH_CERTIFICATION_ONCEFETCHED
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState({ onceFetched: false });

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_BATCH_CERTIFICATE}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(payload, {
        ...store,
        count: meta?.totalCount || 0
      });
    }

    case UPDATE_BATCH_CERTIFICATION_ONCEFETCHED: {
      return { ...store, onceFetched: false };
    }

    default:
      return store;
  }
};

export default view;
