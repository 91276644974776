import {
  GET_BATCH_MENTOR,
  CREATE_BATCH_MENTOR,
  RESET_BATCH_DETAILS,
  UPDATE_BATCH_MENTOR,
  DELETE_BATCH_MENTOR
} from '../../Actions/index';
import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
  updateDataOnDelete
} from '../../Helper/reducer';
const initialState = {
  dataIds: [],
  data: {},
  relation: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  }
};

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_BATCH_MENTOR}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${CREATE_BATCH_MENTOR}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);

    case RESET_BATCH_DETAILS:
      return initialState;

    case `${UPDATE_BATCH_MENTOR}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${DELETE_BATCH_MENTOR}_SUCCESS`: {
      const { rowId } = payload;
      return updateDataOnDelete(store, rowId);
    }

    default:
      return store;
  }
};
export default view;
