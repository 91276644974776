import createAsyncActionCreator from './index';
import {
  FETCH_INACTIVE_WORKSHOPS,
  FETCH_INACTIVE_WORKSHOP_REGISTERED_AUDIENCES,
  FETCH_INACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES,
  SEARCH_REGISTERED_AUDIENCES,
  SEARCH_UNREGISTERED_AUDIENCES,
  FETCH_ACTIVE_WORKSHOPS,
  FETCH_ACTIVE_WORKSHOP_REGISTERED_AUDIENCES,
  FETCH_ACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES,
  SET_ACTIVE_SLIDER_STATUS,
  SET_INACTIVE_SLIDER_STATUS,
  SET_ACTIVE_CURRENT_SEMINAR_ID,
  SET_INACTIVE_CURRENT_SEMINAR_ID,
  RESET_WORKSHOP_PARTICIPANTS_DATA,
  TRIGGER_EMAIL_FOR_REGISTERED_PARTICIPANTS,
  TRIGGER_EMAIL_FOR_UNREGISTERED_PARTICIPANTS,
  CREATE_WORKSHOP,
  SEARCH_SEMINAR_AUDIENCE,
  GET_ALL_WORKSHOP,
  UPDATE_ONCE_FETCHED,
  CREATE_WORKSHOP_PARTICIPANT,
  CREATE_WORKSHOP_PARTICIPANT_CSV,
  WORKSHOP_AUDIENCE_CSV_DOWNLOAD,
  UPDATE_REGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS,
  RESET_TO_REGISTERED_PARTICIPANT_DETAILS_PREV_DATA,
  UPDATE_UNREGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS,
  RESET_TO_UNREGISTERED_PARTICIPANT_DETAILS_PREV_DATA,
  SEARCH_REGISTERED_PARTICIPANT_DETAILS,
  SEARCH_UNREGISTERED_PARTICIPANT_DETAILS,
  UPDATE_ACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS,
  RESET_TO_ACTIVE_SEMINAR_DETAILS_PREV_DATA,
  UPDATE_INACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS,
  RESET_TO_INACTIVE_SEMINAR_DETAILS_PREV_DATA,
  SEARCH_SEMINAR_DETAILS,
  UPDATE_WORKSHOP_DETAILS_BY_SEARCH_PARAMS,
  RESET_TO_WORKSHOP_DETAILS_PREV_DATA,
  CREATE_ACTIVE_WORKSHOP_CERTIFICATE,
  FETCH_REGISTERED_AUDIENCES,
  FETCH_ALL_CERTIFICATE,
  UPDATE_CERTIFICATION_ONCEFETCHED,
  WORKSHOP_EDIT,
  SEND_CERTIFICATE_MAIL,
  DELETE_CERTIFICATE,
  SEND_MAIL_REGISTERED_PARTICIPANT,
  CREATE_REGISTRATION_PAYMENT_RECIVAL
} from '../Actions';
import {
  fetchInactiveWorkshopsApi,
  fetchActiveWorkshopsApi,
  fetchWorkshopRegisteredAudiencesApi,
  fetchWorkshopUnregisteredAudiencesApi,
  workshopCreationApi,
  searchWorkshopAudienceApi,
  getAllWorkshopsApi,
  workshopRegistrationApi,
  workshopRegistrationCSVApi,
  workshopCSVDownloadApi,
  searchRegisteredparticipantDetailsByParamsApi,
  searchUnRegisteredparticipantDetailsByParamsApi,
  searchSeminarDetailsByParamsApi,
  searchRegisteredAudiencesApi,
  searchUnregisteredAudiencesApi,
  triggerWorkshopMailToAudienceApi,
  createActiveWorkshopCertificateApi,
  fetchAllRegisteredAudiencesApi,
  fetchAllCertificateApi,
  workshopEditApi,
  sendCertificateMailApi,
  deleteCertificateApi,
  sendMailToRegisterParticipantApi,
  createRegistrationForPaymentReceivalApi
} from '../Api/workshop';

const fetchActiveWorkshopsGenerator = function* ({ pageNumber }) {
  yield {
    action: FETCH_ACTIVE_WORKSHOPS,
    sideEffect: fetchActiveWorkshopsApi({ pageNumber })
  };
};

const fetchWorkshopsRegisteredAudienceGenerator = function* ({
  pageNumber,
  active = false,
  seminarId
}) {
  yield {
    action: active
      ? FETCH_ACTIVE_WORKSHOP_REGISTERED_AUDIENCES
      : FETCH_INACTIVE_WORKSHOP_REGISTERED_AUDIENCES,
    sideEffect: fetchWorkshopRegisteredAudiencesApi({ pageNumber, seminarId })
  };
};

const searchRegisteredAudienceGenerator = function* ({
  pageNumber,
  seminarId,
  searchText
}) {
  yield {
    action: SEARCH_REGISTERED_AUDIENCES,
    sideEffect: searchRegisteredAudiencesApi({
      pageNumber,
      seminarId,
      searchText
    })
  };
};

const searchUnregisteredAudienceGenerator = function* ({
  pageNumber,
  seminarId,
  searchText
}) {
  yield {
    action: SEARCH_UNREGISTERED_AUDIENCES,
    sideEffect: searchUnregisteredAudiencesApi({
      pageNumber,
      seminarId,
      searchText
    })
  };
};

const triggerWorkshopMailToRegisteredAudienceGenerator = function* ({
  seminarId,
  emailIds,
  csvFile,
  userEmails
}) {
  yield {
    action: TRIGGER_EMAIL_FOR_REGISTERED_PARTICIPANTS,
    sideEffect: triggerWorkshopMailToAudienceApi({
      emailIds,
      csvFile,
      userEmails,
      seminarId,
      isRegistered: true
    })
  };
};

const triggerWorkshopMailToUnRegisteredAudienceGenerator = function* ({
  seminarId,
  emailIds,
  csvFile,
  userEmails
}) {
  yield {
    action: TRIGGER_EMAIL_FOR_UNREGISTERED_PARTICIPANTS,
    sideEffect: triggerWorkshopMailToAudienceApi({
      emailIds,
      csvFile,
      userEmails,
      seminarId,
      isRegistered: false
    })
  };
};

const fetchWorkshopsUnregisteredAudienceGenerator = function* ({
  pageNumber,
  active = false,
  seminarId
}) {
  yield {
    action: active
      ? FETCH_ACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES
      : FETCH_INACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES,
    sideEffect: fetchWorkshopUnregisteredAudiencesApi({ pageNumber, seminarId })
  };
};

const fetchInactiveWorkshopsGenerator = function* ({ pageNumber }) {
  yield {
    action: FETCH_INACTIVE_WORKSHOPS,
    sideEffect: fetchInactiveWorkshopsApi({ pageNumber })
  };
};

export const setSliderStatus = (index, active = false) => ({
  type: active ? SET_ACTIVE_SLIDER_STATUS : SET_INACTIVE_SLIDER_STATUS,
  payload: { index }
});

export const setCurrentSeminarId = (seminarId, active = false) => ({
  type: active
    ? SET_ACTIVE_CURRENT_SEMINAR_ID
    : SET_INACTIVE_CURRENT_SEMINAR_ID,
  payload: { seminarId }
});

export const resetWorkshopParticipantsData = () => ({
  type: RESET_WORKSHOP_PARTICIPANTS_DATA
});

const workshopCreationGenerator = function* ({ formData }) {
  yield {
    action: CREATE_WORKSHOP,
    sideEffect: workshopCreationApi(formData)
  };
};

export const updateWorkshopSearchOnceFetched = () => ({
  type: UPDATE_ONCE_FETCHED
});

const searchWorkshopGenerator = function* ({
  pageNumber,
  currentName,
  currentPhone,
  currentEmail,
  currentSeminarId,
  currentEnrollmentId,
  currentPaidAmount,
  currentFromDate,
  currentToDate,
  currentRegistrationType
}) {
  yield {
    action: SEARCH_SEMINAR_AUDIENCE,
    sideEffect: searchWorkshopAudienceApi({
      pageNumber,
      name: currentName,
      phone: currentPhone,
      email: currentEmail,
      seminarId: currentSeminarId,
      enrollmentId: currentEnrollmentId,
      paidAmount: currentPaidAmount,
      fromDate: currentFromDate,
      toDate: currentToDate,
      registrationType: currentRegistrationType
    })
  };
};

export const updateWorkshopDetailsBySearchParams = ({
  name,
  phone,
  email,
  seminarId,
  enrollmentId,
  paidAmount,
  fromDate,
  toDate,
  registrationType
}) => ({
  type: UPDATE_WORKSHOP_DETAILS_BY_SEARCH_PARAMS,
  payload: {
    name,
    phone,
    email,
    seminarId,
    enrollmentId,
    paidAmount,
    fromDate,
    toDate,
    registrationType
  }
});

export const resetToWorkshopDetailsByPrevData = () => ({
  type: RESET_TO_WORKSHOP_DETAILS_PREV_DATA
});

const createWorkshopParticipantGenerator = function* ({
  participantName,
  participantPhone,
  participantEmail,
  participantCollege,
  participantSemester,
  paymentSource,
  description,
  amount,
  participantBranch,
  participantCity,
  participantState,
  seminarId
}) {
  yield {
    action: CREATE_WORKSHOP_PARTICIPANT,
    sideEffect: workshopRegistrationApi({
      participantName,
      participantPhone,
      participantEmail,
      participantCollege,
      participantSemester,
      participantBranch,
      participantCity,
      participantState,
      paymentSource,
      description,
      amount,
      seminarId
    })
  };
};

const getAllWorkshopsGenerator = function* ({ pageNumber }) {
  yield {
    action: GET_ALL_WORKSHOP,
    sideEffect: getAllWorkshopsApi({ pageNumber })
  };
};

const createWorkshopCSVParticipantGenerator = function* ({
  csvFile,
  seminarId
}) {
  yield {
    action: CREATE_WORKSHOP_PARTICIPANT_CSV,
    sideEffect: workshopRegistrationCSVApi({
      csvFile,
      seminarId
    })
  };
};

const workshopAudienceCSVDownloadGenerator = function* ({ seminarId, type }) {
  yield {
    action: WORKSHOP_AUDIENCE_CSV_DOWNLOAD,
    sideEffect: workshopCSVDownloadApi({
      type,
      seminarId
    })
  };
};

const searchRegisteredparticipantDetailsGenerator = function* ({
  pageNumber,
  seminarId,
  currentContactData,
  currentCollege,
  currentCity,
  currentBranch,
  currentState
}) {
  yield {
    action: SEARCH_REGISTERED_PARTICIPANT_DETAILS,
    sideEffect: searchRegisteredparticipantDetailsByParamsApi({
      pageNumber,
      seminarId: seminarId,
      nameOrEmailOrPhone: currentContactData,
      college: currentCollege,
      city: currentCity,
      branch: currentBranch,
      state: currentState
    })
  };
};

const searchSeminarDetailsGenerator = function* ({
  pageNumber,
  currentSeminarType,
  currentSeminarName,
  currentSeminarVenue,
  currentSeminarPaidStatus
}) {
  yield {
    action: SEARCH_SEMINAR_DETAILS,
    sideEffect: searchSeminarDetailsByParamsApi({
      pageNumber,
      seminarType: currentSeminarType,
      name: currentSeminarName,
      venue: currentSeminarVenue,
      isPaid: currentSeminarPaidStatus
    })
  };
};

export const updateRegisteredparticipantDetailsSearchParams = ({
  contactData,
  college,
  city,
  branch,
  state
}) => ({
  type: UPDATE_REGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS,
  payload: { contactData, college, city, branch, state }
});

export const resetToRegisteredParticipantDetailsPrevData = () => ({
  type: RESET_TO_REGISTERED_PARTICIPANT_DETAILS_PREV_DATA
});

const searchUnRegisteredparticipantDetailsGenerator = function* ({
  pageNumber,
  seminarId,
  currentContactData,
  currentCollege,
  currentCity,
  currentBranch,
  currentState
}) {
  yield {
    action: SEARCH_UNREGISTERED_PARTICIPANT_DETAILS,
    sideEffect: searchUnRegisteredparticipantDetailsByParamsApi({
      pageNumber,
      seminarId: seminarId,
      nameOrEmailOrPhone: currentContactData,
      college: currentCollege,
      city: currentCity,
      branch: currentBranch,
      state: currentState
    })
  };
};

export const updateUnRegisteredparticipantDetailsSearchParams = ({
  contactData,
  college,
  city,
  branch,
  state
}) => ({
  type: UPDATE_UNREGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS,
  payload: { contactData, college, city, branch, state }
});

export const resetToUnRegisteredParticipantDetailsPrevData = () => ({
  type: RESET_TO_UNREGISTERED_PARTICIPANT_DETAILS_PREV_DATA
});

export const updateActiveSeminarDetailsSearchParams = ({
  seminarType,
  name,
  venue,
  paidStatus
}) => ({
  type: UPDATE_ACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS,
  payload: { seminarType, name, venue, paidStatus }
});

export const resetToActiveSeminarDetailsPrevData = () => ({
  type: RESET_TO_ACTIVE_SEMINAR_DETAILS_PREV_DATA
});

export const updateInActiveSeminarDetailsSearchParams = ({
  seminarType,
  name,
  venue,
  paidStatus
}) => ({
  type: UPDATE_INACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS,
  payload: { seminarType, name, venue, paidStatus }
});

export const resetToInActiveSeminarDetailsPrevData = () => ({
  type: RESET_TO_INACTIVE_SEMINAR_DETAILS_PREV_DATA
});

const createActiveWorkshopCertificateGenerator = function* ({
  modelableId,
  modelableType,
  certificationType,
  dateOfIssue,
  candidateOption,
  mail,
  selectedStudentIds
}) {
  yield {
    action: CREATE_ACTIVE_WORKSHOP_CERTIFICATE,
    sideEffect: createActiveWorkshopCertificateApi({
      modelableId,
      modelableType,
      certificationType,
      dateOfIssue,
      candidateOption,
      mail,
      selectedStudentIds
    })
  };
};

const fetchRegisteredAudienceGenerator = function* ({ pageNumber, seminarId }) {
  yield {
    action: FETCH_REGISTERED_AUDIENCES,
    sideEffect: fetchAllRegisteredAudiencesApi({ pageNumber, seminarId })
  };
};

const fetchAllCertificateGenerator = function* ({
  pageNumber,
  seminarId,
  modelableType
}) {
  yield {
    action: FETCH_ALL_CERTIFICATE,
    sideEffect: fetchAllCertificateApi({ pageNumber, seminarId, modelableType })
  };
};

export const updateCertificationOnceFetched = () => ({
  type: UPDATE_CERTIFICATION_ONCEFETCHED
});

const sendCertificateMailGenerator = function* ({ seminarId, certificateId }) {
  yield {
    action: SEND_CERTIFICATE_MAIL,
    sideEffect: sendCertificateMailApi(seminarId, certificateId)
  };
};

const deleteCertificationGenerator = function* ({ seminarId, certificateId }) {
  const { error, ...response } = yield {
    action: DELETE_CERTIFICATE,
    sideEffect: deleteCertificateApi(seminarId, certificateId),
    track: false
  };
  if (error) {
    return;
  }
  yield {
    action: `${DELETE_CERTIFICATE}_SUCCESS`,
    payload: { ...response, rowId: certificateId }
  };
};

const sendMailToRegisterParticipantGenerator = function* ({
  seminarId,
  registeredParticipantId
}) {
  yield {
    action: SEND_MAIL_REGISTERED_PARTICIPANT,
    sideEffect: sendMailToRegisterParticipantApi(
      seminarId,
      registeredParticipantId
    )
  };
};

const createRegistrationForPaymentReceivalGenerator = function* ({
  seminarId,
  seminarAudienceId
}) {
  yield {
    action: CREATE_REGISTRATION_PAYMENT_RECIVAL,
    sideEffect: createRegistrationForPaymentReceivalApi(
      seminarId,
      seminarAudienceId
    )
  };
};

const workshopEditGenerator = function* ({ formData, workshopId }) {
  const { error, ...response } = yield {
    action: WORKSHOP_EDIT,
    sideEffect: workshopEditApi({ formData, workshopId }),
    track: false
  };
  if (error) {
    return;
  }
  yield {
    action: `${WORKSHOP_EDIT}_SUCCESS`,
    payload: {
      ...response,
      isWorkshopActive:
        formData?.is_active == undefined ? true : formData.is_active,
      workshopId
    }
  };
};

export const fetchInactiveWorkshops = createAsyncActionCreator(
  fetchInactiveWorkshopsGenerator
);
export const fetchActiveWorkshops = createAsyncActionCreator(
  fetchActiveWorkshopsGenerator
);
export const fetchWorkshopsRegisteredAudience = createAsyncActionCreator(
  fetchWorkshopsRegisteredAudienceGenerator
);
export const fetchWorkshopsUnregisteredAudience = createAsyncActionCreator(
  fetchWorkshopsUnregisteredAudienceGenerator
);

export const [
  searchRegisteredAudience,
  searchUnregisteredAudience,
  triggerWorkshopMailToRegisteredAudience,
  triggerWorkshopMailToUnRegisteredAudience
] = createAsyncActionCreator([
  searchRegisteredAudienceGenerator,
  searchUnregisteredAudienceGenerator,
  triggerWorkshopMailToRegisteredAudienceGenerator,
  triggerWorkshopMailToUnRegisteredAudienceGenerator
]);

export const createWorkshop = createAsyncActionCreator(
  workshopCreationGenerator
);

export const searchWorkshop = createAsyncActionCreator(searchWorkshopGenerator);
export const getAllWorkshops = createAsyncActionCreator(
  getAllWorkshopsGenerator
);
export const createWorkshopParticipant = createAsyncActionCreator(
  createWorkshopParticipantGenerator
);

export const createWorkshopParticipantCSV = createAsyncActionCreator(
  createWorkshopCSVParticipantGenerator
);

export const workshopAudienceCSVDownload = createAsyncActionCreator(
  workshopAudienceCSVDownloadGenerator
);
export const searchRegisteredParticipant = createAsyncActionCreator(
  searchRegisteredparticipantDetailsGenerator
);

export const searchUnRegisteredParticipant = createAsyncActionCreator(
  searchUnRegisteredparticipantDetailsGenerator
);

export const searchSeminarDetails = createAsyncActionCreator(
  searchSeminarDetailsGenerator
);
export const [
  activeWorkshopCertificateCreate,
  fetchRegisteredAudience,
  fetchCertificate,
  sendCertificateMail,
  deleteCertificate,
  sendMailToRegisterParticipant,
  createRegistrationForPaymentReceival
] = createAsyncActionCreator([
  createActiveWorkshopCertificateGenerator,
  fetchRegisteredAudienceGenerator,
  fetchAllCertificateGenerator,
  sendCertificateMailGenerator,
  deleteCertificationGenerator,
  sendMailToRegisterParticipantGenerator,
  createRegistrationForPaymentReceivalGenerator
]);

export const workshopEdit = createAsyncActionCreator(workshopEditGenerator);
