import {
  GET_STUDENTS_ENQUIRY,
  SEARCH_STUDENT_ENQUIRY,
  UPDATE_ENQUIRY_REMARKS,
  UPDATE_ENQUIRY_SEARCH_PARAMS,
  RESET_PREV_SEARCH_ENQUIRY_DATA
} from '../../Actions';
import { createRelationIterator } from '../../Helper';

const initialState = {
  totalRegistrations: null,
  dataIds: [],
  data: {},
  relation: {},
  onceFetched: false,

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  },

  prevData: {},
  currentOrigin: '',
  currentTicketId: ''
};

const enquiry = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_STUDENTS_ENQUIRY}_SUCCESS`:
    case `${SEARCH_STUDENT_ENQUIRY}_SUCCESS`: {
      const { dataIds, data, relation, paginationData } = payload;

      let prevData = {
        ...store.prevData
      };

      if (type === `${SEARCH_STUDENT_ENQUIRY}_SUCCESS`) {
        if (Object.keys(store.prevData).length === 0) {
          //  only one time previous data should be stored
          prevData = {
            ...store,
            prevData: {}
          };
        }
      }

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
        prevData
      };
    }

    case `${UPDATE_ENQUIRY_REMARKS}_SUCCESS`: {
      const { data, rowId, relation, dataIds } = payload;
      const newData = { ...store.data };
      const currentRemark = data[dataIds[0]];
      if (
        newData[rowId].relationships &&
        newData[rowId].relationships.remarks
      ) {
        const item = newData[rowId].relationships.remarks.data;
        if (Array.isArray(item)) {
          newData[rowId].relationships.remarks.data.push({
            id: currentRemark.id,
            type: currentRemark.type
          });
        }
      }

      const newRelation = { ...store.relation, ...relation };
      const key = `${currentRemark.id}_${currentRemark.type}`;
      newRelation[key] = {
        ...currentRemark,
        [Symbol.iterator]: createRelationIterator()
      };

      return {
        ...store,
        relation: newRelation,
        data: newData
      };
    }

    case RESET_PREV_SEARCH_ENQUIRY_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        currentOrigin: '',
        currentTicketId: ''
      };
    }

    case UPDATE_ENQUIRY_SEARCH_PARAMS: {
      const { ticketId, origin } = payload;
      return {
        ...store,
        currentTicketId: ticketId,
        currentOrigin: origin
      };
    }
    default:
      return store;
  }
};

export default enquiry;
