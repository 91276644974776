import React, { useRef, useState, useCallback } from 'react';
import Avatar from '../../Avatar';

import Icon from '../../Icon';
import InsertInDom from '../../InsertInDom';
import useUserInformation from '../../../Hooks/useUserInformation';

import InfoCard from './InfoCard';
import useTransition from '../../../Hooks/useTransition';
import styles from './UserDropDown.module.css';

const user_card_width = 400;

const UserDropDown = () => {
  const [modalActive, updateModalStatus] = useState(false);
  const { isVisible } = useTransition({ active: modalActive, maxWait: 500 });
  const { userInfo } = useUserInformation();
  const posRef = useRef({ x: 0, y: 0 });

  const toggleModal = useCallback(() => {
    updateModalStatus((val) => !val);
  }, []);

  const onProfileClick = useCallback((e) => {
    let { target } = e;
    if (target.id !== 'nav-profile-drop-btn') {
      target = target.closest('button');
    }

    const { x, y, width, height } = target.getBoundingClientRect();

    posRef.current = { x: x - user_card_width + width, y: y + height };
    toggleModal();
  }, []);

  return (
    <>
      <button
        id="nav-profile-drop-btn"
        type="button"
        onClick={onProfileClick}
        className={`${styles.profile_pic_button} rc`}>
        {userInfo.avatarUrl ? (
          <Avatar
            alt="user profile pic"
            imageClass={styles.profile_btn_img}
            avatarUrl={userInfo.avatarUrl}
            name={userInfo.name}
          />
        ) : (
          <>
            <Icon
              className={styles.profile_btn_icon}
              name="fas fa-user-circle"
            />
            <Icon
              className={styles.profile_drop_icon}
              name="fas fa-chevron-down"
            />
          </>
        )}
      </button>
      {isVisible && (
        <InsertInDom domId="overlay">
          <div className={styles.overlay_cont}>
            <div
              role="button"
              onClick={toggleModal}
              className={styles.overlay}
            />
            <InfoCard
              cardWidth={user_card_width}
              position={posRef.current}
              userInfo={userInfo}
              active={modalActive}
            />
          </div>
        </InsertInDom>
      )}
    </>
  );
};

export default UserDropDown;
