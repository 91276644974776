import React, { useMemo, useEffect, useRef } from 'react';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import Drawer from './Drawer';
import MobileDrawer from './MobileDrawer';
import useDrawer from '../../Hooks/useDrawer';

import { debounce } from '../../Helper';

const DrawerDiff = () => {
  const debounceFuncRef = useRef(null);
  const { dimesnions } = useWindowDimensions();
  const { isDrawerActive, toggleDrawer, openDrawer } = useDrawer();
  const isMobile = useMemo(() => {
    return dimesnions.width <= 800;
  }, [dimesnions.width]);

  if (!debounceFuncRef.current) {
    debounceFuncRef.current = debounce(openDrawer, 500);
  }

  useEffect(() => {
    const isDesktop = dimesnions.width > 800;
    if (isDesktop) {
      debounceFuncRef.current();
    }
  }, [dimesnions.width]);

  if (isMobile) {
    return (
      <MobileDrawer
        isDrawerActive={isDrawerActive}
        toggleDrawer={toggleDrawer}
        isMobileView={isMobile}
      />
    );
  } else {
    return <Drawer isMobileView={isMobile} isDrawerActive={isDrawerActive} />;
  }
};

export default DrawerDiff;
