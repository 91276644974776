import React from 'react';

import Icon from '../Icon';
import {
  ListItemContent,
  ListItemDesc,
  ListItemHeading,
  ListItemHollowCheckButton,
  ListHeaderItemContainer
} from './style';

export const ListHeader = ({
  data,
  selected,
  showSelectButton = true,
  onClick
}) => {
  return (
    <ListHeaderItemContainer>
      <ListItemContent>
        <ListItemHeading>{data.heading}</ListItemHeading>
        <ListItemDesc>{data.desc}</ListItemDesc>
      </ListItemContent>

      {showSelectButton && (
        <ListItemHollowCheckButton type="button" onClick={onClick}>
          {selected && <Icon name="fas fa-check" />}
        </ListItemHollowCheckButton>
      )}
    </ListHeaderItemContainer>
  );
};
