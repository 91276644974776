import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Collapse from '../Collapse';
import ErrorBoundary from '../ErrorBoundary';
import Icon from '../Icon';

import styles from './Drawer.module.css';

const DrawerHead = ({ icon, title, active = false, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.drawer_head} ${
        !active ? styles.drawer_head_hover : ''
      }`}>
      <Icon className={styles.drawer_icon} name={icon} />
      <span
        style={{
          fontWeight: active ? 'bolder' : 'normal'
        }}
        className={styles.drawer_title}>
        {title}
      </span>
    </button>
  );
};

const DrawerTab = ({ children, title, icon, links = [], ...otherProps }) => {
  const location = useLocation();
  const isTabActive = useMemo(() => {
    const res = links.includes(location.pathname);
    return res;
  }, [links, location.pathname]);
  return (
    <ErrorBoundary type="child">
      {children ? (
        <Collapse
          className={styles.drawer_tab}
          shouldBeCollapsed={isTabActive}
          header={
            <DrawerHead active={isTabActive} title={title} icon={icon} />
          }>
          {children}
        </Collapse>
      ) : (
        <DrawerHead title={title} icon={icon} {...otherProps} />
      )}
    </ErrorBoundary>
  );
};

export default DrawerTab;
