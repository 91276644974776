import { useSelector } from 'react-redux';
import { getAccessType } from '../../Selectors/auth';
const OnlyWhenAuthorized = ({ children, requiredAccess = '' }) => {
  const userAccessType = useSelector(getAccessType);
  let requiredTypes = [];
  if (typeof requiredAccess === 'string') {
    requiredTypes = [requiredAccess];
  }
  if (Array.isArray(requiredAccess)) {
    requiredTypes = [...requiredAccess];
  }
  return requiredTypes.includes(userAccessType) ? children : null;
};
export default OnlyWhenAuthorized;
