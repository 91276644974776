import React, { useState, useCallback } from 'react';
import ActivityIndicator from '../ActivityIndicator';

import styles from './Image.module.css';

const Image = ({ alt, className = '', imageClass = '', ...otherProps }) => {
  const [isLoading, updateLoading] = useState(true);

  const onLoad = useCallback(() => {
    updateLoading(false);
  }, []);

  const onError = useCallback(() => {
    updateLoading(false);
  }, []);
  return (
    <div className={`${styles.container} ${className}`}>
      <img
        className={`${styles.image} ${imageClass}`}
        alt={alt}
        {...otherProps}
        onLoad={onLoad}
        onError={onError}
      />
      {isLoading && (
        <div className={`${styles.loader} rc`}>
          <ActivityIndicator size="s" />
        </div>
      )}
    </div>
  );
};

export default Image;
