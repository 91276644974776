import React from 'react';

import Icon from '../Icon';
import styles from './Input.module.css';
import useInput from './useInput';

const Select = ({ defaultSelectText = '', options = [], ...otherprops }) => {
  const { containerClass, inputClass, iconClass, icon, restInputProps } =
    useInput(otherprops);

  return (
    <div className={containerClass}>
      <select {...restInputProps} className={inputClass}>
        {
          <option className={styles.select} value="">
            {defaultSelectText}
          </option>
        }

        {options.map((item) => {
          return (
            <option
              className={styles.select}
              key={item.value}
              value={item.value}>
              {item.name}
            </option>
          );
        })}
      </select>
      {!!icon && <Icon className={iconClass} name={icon} />}
    </div>
  );
};

export default Select;
