export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const ACTIVATE_ALERT = 'ACTIVATE_ALERT';
export const DE_ACTIVATE_ALERT = 'DE_ACTIVATE_ALERT';

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const DELETE_USER = 'DELETE_USER';
export const SEARCH_USER_DETAILS = 'SEARCH_USER_DETAILS';
export const UPDATE_USER_DETAILS_SEARCH_PARAMS =
  'UPDATE_USER_DETAILS_SEARCH_PARAMS';
export const RESET_TO_USER_DETAILS_PREV_DATA =
  'RESET_TO_USER_DETAILS_PREV_DATA';

export const CREATE_COURSE = 'CREATE_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const FETCH_COURSES = 'FETCH_COURSES';

export const GET_STUDENTS_ENQUIRY = 'GET_STUDENTS_ENQUIRY';
export const SEARCH_STUDENT_ENQUIRY = 'SEARCH_STUDENT_ENQUIRY';
export const DOWNLOAD_ENQUIRY_REQUESTS = 'DOWNLOAD_ENQUIRY_REQUESTS';
export const CLEAR_SINGLE_STUDENT_ENQUIRY_DATA =
  'CLEAR_SINGLE_STUDENT_ENQUIRY_DATA';
export const UPDATE_ENQUIRY_SEARCH_PARAMS = 'UPDATE_ENQUIRY_SEARCH_PARAMS';
export const RESET_PREV_SEARCH_ENQUIRY_DATA = 'RESET_PREV_SEARCH_ENQUIRY_DATA';

export const GET_COURSE_SYLLABUS = 'GET_COURSE_SYLLABUS';
export const DOWNLOAD_SYLLABUS_REQUESTS = 'DOWNLOAD_SYLLABUS_REQUESTS';
export const UPDATE_SYLLABUS_REMARKS = 'UPDATE_SYLLABUS_REMARKS';
export const UPDATE_ENQUIRY_REMARKS = 'UPDATE_ENQUIRY_REMARKS';

export const CREATE_LIVE_PROJECT = 'CREATE_LIVE_PROJECT';
export const UPDATE_LIVE_PROJECT = 'UPDATE_LIVE_PROJECT';
export const GET_LIVE_PROJECTS = 'GET_LIVE_PROJECTS';

export const CREATE_COURSE_LEVEL = 'CREATE_COURSE_LEVEL';
export const GET_COURSE_LEVEL = 'GET_COURSE_LEVEL';
export const SET_COURSE_LEVEL = 'SET_COURSE_LEVEL';
export const UPDATE_COURSE_LEVEL_COURSE = 'UPDATE_COURSE_LEVEL_COURSE';

export const CREATE_MAIL = 'CREATE_MAIL';
export const UPDATE_MAIL = 'UPDATE_MAIL';
export const GET_EMAILS = 'GET_EMAILS';
export const CHANGE_EMAIL_CATEGORY = 'CHANGE_EMAIL_CATEGORY';
export const CHANGE_EDIT_EMAIL_CATEGORY = 'CHANGE_EDIT_EMAIL_CATEGORY';
export const SEND_EMAIL = 'SEND_EMAIL';
export const GET_EMAIL_REPORTS = 'GET_EMAIL_REPORTS';
export const GET_EMAILS_WITH_PAGINATION = 'GET_EMAILS_WITH_PAGINATION';
export const GET_SCHEDULE_MAILS = 'GET_SCHEDULE_MAILS';
export const CREATE_SCHEDULE_MAILS = 'CREATE_SCHEDULE_MAILS';
export const GET_DAILY_EMAIL_REPORT_COUNT = 'GET_DAILY_EMAIL_REPORT_COUNT';
export const UPDATE_FETCH_EMAIL_REPORTS_FLAG =
  'UPDATE_FETCH_EMAIL_REPORTS_FLAG';
export const SEARCH_EMAIL_REPORTS = 'SEARCH_EMAIL_REPORTS';
export const DELETE_EMAIL_REPORTS = 'DELETE_EMAIL_REPORTS';
export const DELETE_ALL_EMAIL_REPORTS = 'DELETE_ALL_EMAIL_REPORTS';
export const UPDATE_EMAIL_REPORTS_DETAILS_SEARCH_PARAMS =
  'UPDATE_EMAIL_REPORTS_DETAILS_SEARCH_PARAMS';
export const RESET_TO_EMAIL_REPORTS_DETAILS_PREV_DATA =
  'RESET_TO_EMAIL_REPORTS_DETAILS_PREV_DATA';

export const CREATE_BATCH = 'CREATE_BATCH';
export const UPDATE_BATCH = 'UPDATE_BATCH';
export const DELETE_BATCH = 'DELETE_BATCH';
export const GET_BATCH = 'GET_BATCH';
export const CREATE_BATCH_MENTOR = 'CREATE_BATCH_MENTOR';
export const GET_BATCH_MENTOR = 'GET_BATCH_MENTOR';
export const CREATE_BATCH_STUDENT = 'CREATE_BATCH_STUDENT';
export const GET_BATCH_STUDENT = 'GET_BATCH_STUDENT';
export const GET_BATCH_CLASS_DETAILS = 'GET_BATCH_CLASS_DETAILS';
export const GET_BATCH_ASSIGNMENTS = 'GET_BATCH_ASSIGNMENTS';
export const RESET_BATCH_DETAILS = 'RESET_BATCH_DETAILS';

export const UPDATE_BATCH_STUDENT = 'UPDATE_BATCH_STUDENT';
export const UPDATE_BATCH_MENTOR = 'UPDATE_BATCH_MENTOR';

export const SEARCH_BATCH_DETAILS = 'SEARCH_BATCH_DETAILS';
export const UPDATE_BATCH_DETAILS_SEARCH_PARAMS =
  'UPDATE_BATCH_DETAILS_SEARCH_PARAMS';
export const RESET_TO_BATCH_DETAILS_PREV_DATA =
  'RESET_TO_BATCH_DETAILS_PREV_DATA';

export const FETCH_BATCH_CERTIFICATE = 'FETCH_BATCH_CERTIFICATE';
export const UPDATE_BATCH_CERTIFICATION_ONCEFETCHED =
  'UPDATE_BATCH_CERTIFICATION_ONCEFETCHED';

export const FETCH_SUBSCRIPTIONS_BY_COURSE = 'FETCH_SUBSCRIPTIONS_BY_COURSE';
export const BATCH_CERTIFICATE_CREATE = 'BATCH_CERTIFICATE_CREATE';

export const DELETE_BATCH_STUDENT = 'DELETE_BATCH_STUDENT';
export const DELETE_BATCH_MENTOR = 'DELETE_BATCH_MENTOR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const FETCH_CALLBACK_REQUEST = 'FETCH_CALLBACK_REQUEST';
export const DOWNLOAD_CALLBACK_REQUESTS = 'DOWNLOAD_CALLBACK_REQUESTS';

export * from './student';
export * from './studentSubscription';
export * from './companyMentors';
export * from './studentSubscriptionDetails';
export * from './employer';
export * from './workshop';
export * from './channelPartners';
export * from './subscribedStudents';
export * from './webinar';
