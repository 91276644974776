import React from 'react';

import styles from './TimePicker.module.css';

export const SelectItem = ({ data, onClick = () => {}, type, currentData }) => {
  return (
    <span
      className={`${styles.selectItem} ${
        data === currentData ? styles.activeItem : ''
      }`}
      onClick={() => onClick(data, type)}>
      {data}
    </span>
  );
};
