import {
  RESET_TO_WORKSHOP_DETAILS_PREV_DATA,
  SEARCH_SEMINAR_AUDIENCE,
  UPDATE_ONCE_FETCHED,
  UPDATE_WORKSHOP_DETAILS_BY_SEARCH_PARAMS
} from '../../../Actions';
import { createNewStoreOnGetData } from '../../../Helper/reducer';
const initialState = {
  dataIds: [],
  data: {},
  relation: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  },
  count: null,
  currentName: '',
  currentPhone: '',
  currentEmail: '',
  currentSeminarId: '',
  currentEnrollmentId: '',
  currentPaidAmount: '',
  currentFromDate: '',
  currentToDate: '',
  currentRegistrationType: ''
};

const search = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_SEMINAR_AUDIENCE}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(payload, {
        ...store,
        count: meta?.totalCount || 0
      });
    }

    case UPDATE_ONCE_FETCHED:
      return {
        ...store,
        onceFetched: false
      };

    case UPDATE_WORKSHOP_DETAILS_BY_SEARCH_PARAMS: {
      const {
        name,
        phone,
        email,
        seminarId,
        enrollmentId,
        paidAmount,
        fromDate,
        toDate,
        registrationType
      } = payload;

      return {
        ...store,
        onceFetched: false,
        currentName: name,
        currentPhone: phone,
        currentEmail: email,
        currentSeminarId: seminarId,
        currentEnrollmentId: enrollmentId,
        currentPaidAmount: paidAmount,
        currentFromDate: fromDate,
        currentToDate: toDate,
        currentRegistrationType: registrationType
      };
    }

    case RESET_TO_WORKSHOP_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentName: '',
        currentPhone: '',
        currentEmail: '',
        currentSeminarId: '',
        currentEnrollmentId: '',
        currentPaidAmount: '',
        currentFromDate: '',
        currentToDate: '',
        currentRegistrationType: ''
      };
    }

    default:
      return store;
  }
};
export default search;
