import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect
} from 'react';

import useInput from '../useInput';

import Icon from '../../Icon';
import styles from './TimePicker.module.css';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { PickerModal } from './PickerModal';
import { timeStringToArray } from '../../../Helper/common';

const Input = ({ fixedWidth = 400, ...otherprops }) => {
  const {
    containerClass,
    inputClass,
    iconClass,
    icon,
    restInputProps
  } = useInput(otherprops);

  const { dimesnions } = useWindowDimensions();
  const [isInBottom, toggleBottomPosition] = useState(true);
  const timePickerRef = useRef(null);
  const [currentTime, updateTime] = useState(() => ({
    currentHour: '',
    currentMinute: '',
    currentMeridian: ''
  }));

  const { placeholder, value, onChange } = restInputProps;

  const [isActive, toggleActive] = useState(false);
  const btnRef = useRef();

  const updatePosition = useCallback(() => {
    if (!timePickerRef.current) {
      return;
    }
    const { height } = timePickerRef.current.getBoundingClientRect();
    const { top, height: inputHeight } = btnRef.current.getBoundingClientRect();
    if (top + inputHeight + height > dimesnions.height) {
      toggleBottomPosition(false);
    } else {
      toggleBottomPosition(true);
    }
  }, []);

  useEffect(() => {
    if (value) {
      const { hour, minute, meridian } = timeStringToArray(value);
      updateTime({
        currentHour: hour,
        currentMinute: minute,
        currentMeridian: meridian
      });
    }
  }, [value]);

  useEffect(() => {
    const { currentHour, currentMinute, currentMeridian } = currentTime;
    if (
      typeof onChange !== 'function' ||
      (!currentHour && !currentMinute && !currentMeridian)
    ) {
      return;
    }

    const val = `${currentHour}:${currentMinute} ${currentMeridian}`;

    onChange({
      target: {
        value: val
      }
    });
  }, [currentTime]);

  const setContentRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    timePickerRef.current = ref;
    updatePosition();
  }, []);

  useEffect(() => {
    updatePosition();
  }, [dimesnions]);

  const toggleTimePicker = useCallback(
    ({ target }) => {
      toggleActive((val) => {
        return !val;
      });
      if (
        !currentTime.currentHour &&
        !currentTime.currentMinute &&
        !currentTime.currentMeridian
      ) {
        updateTime({
          currentHour: '01',
          currentMinute: '00',
          currentMeridian: 'AM'
        });
      }
    },
    [currentTime]
  );

  const timeText = useMemo(() => {
    const { currentHour, currentMinute, currentMeridian } = currentTime;
    return value
      ? `${currentHour}:${currentMinute} ${currentMeridian}`
      : placeholder;
  }, [currentTime, placeholder, value]);

  const onClick = useCallback((data, type) => {
    if (type === 'minutes') {
      updateTime((prevData) => ({ ...prevData, currentMinute: data }));
    } else if (type === 'hours') {
      updateTime((prevData) => ({ ...prevData, currentHour: data }));
    } else {
      updateTime((prevData) => ({ ...prevData, currentMeridian: data }));
    }
  }, []);

  return (
    <div className={containerClass}>
      <button
        ref={btnRef}
        {...restInputProps}
        type="button"
        onClick={toggleTimePicker}
        className={`${inputClass} ${styles.timePicker}`}>
        {timeText}
      </button>
      {isActive && (
        <PickerModal
          ref={setContentRef}
          isInBottom={isInBottom}
          currentTime={currentTime}
          toggleTimePicker={toggleTimePicker}
          onClick={onClick}
        />
      )}
      {!!icon ? (
        <Icon className={iconClass} name={icon} />
      ) : (
        <Icon className={iconClass} name="far fa-clock" />
      )}
    </div>
  );
};

export default Input;
