import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect
} from 'react';

import Icon from '../../Icon';
import InsertInDom from '../../InsertInDom';
import ActivityIndicator from '../../ActivityIndicator';

import useInput from '../useInput';

import usePagination from '../../../Hooks/usePagination';

import styles from './AdvanceSelect.module.css';

const AdvanceSelect = ({
  defaultSelectText = '',
  isSearchable = false,
  options = [],
  optionStyle = {},
  optionClass = '',
  children,
  fixedWidth = 0,
  pageConfig = { api: '', extraParams: {}, tableName: '' },
  ...otherprops
}) => {
  const {
    loadingNextPage,
    getNextPage,
    currentPageNumber,
    totalPages
  } = usePagination({
    actionCreator: pageConfig.api,
    tableName: pageConfig.tableName
  });

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams: pageConfig.extraParams
    });
  }, [getNextPage, pageConfig.extraParams]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      extraParams: pageConfig.extraParams
    });
  }, [getNextPage, pageConfig.extraParams]);

  const btnRef = useRef();
  const [isActive, toggleActive] = useState(false);
  const [position, updatePosition] = useState({ x: 0, y: 0, width: 0 });
  const {
    containerClass,
    inputClass,
    iconClass,
    icon,
    restInputProps
  } = useInput(otherprops);
  const { onChange, ...rest } = restInputProps;

  useEffect(() => {
    if (isSearchable) {
      fetchFirstPage();
    }
  }, []);

  const toggleSelect = useCallback(
    ({ target }) => {
      toggleActive((val) => !val);
      const { x, y } = target.getBoundingClientRect();
      const { clientHeight, clientWidth } = target;
      updatePosition({
        x: x,
        y: y + clientHeight - 8,
        width: fixedWidth || (clientWidth < 200 ? 200 : clientWidth)
      });
      btnRef.current.focus();
    },
    [fixedWidth]
  );

  const onOptionSelect = useCallback(
    (e, item) => {
      const { value } = e.target.dataset;
      if (typeof onChange !== 'function') {
        return;
      }
      onChange({
        ...e,
        target: {
          value: item?.value || value
        }
      });

      toggleActive((val) => !val);
      btnRef.current.focus();
    },
    [onChange, rest]
  );

  const onOptionBodyScroll = useCallback(
    (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isEnd =
        scrollHeight - Math.ceil(Math.abs(scrollTop)) <= clientHeight;
      if (isEnd) {
        if (totalPages === currentPageNumber) {
          return;
        }
        if (currentPageNumber === 0) {
          fetchFirstPage(0);
        }
        fetchNextPage();
      }
    },
    [
      currentPageNumber,
      totalPages,
      fetchFirstPage,
      fetchNextPage,
      pageConfig.extraParams
    ]
  );

  const currentValue = useMemo(() => {
    const item = options.find((item) => item.value === restInputProps.value);

    return item ? item.name : null;
  }, [options, restInputProps.value]);

  return (
    <div className={containerClass}>
      <button
        ref={btnRef}
        {...rest}
        type="button"
        onClick={toggleSelect}
        className={`${inputClass} ${styles.select}`}>
        {currentValue || defaultSelectText}
      </button>

      {isActive && (
        <InsertInDom domId="" isModal={true}>
          <div onClick={toggleSelect} className={styles.overlay} />
          <div
            onScroll={isSearchable ? onOptionBodyScroll : null}
            style={{
              top: `${position.y}px`,
              left: `${position.x}px`,
              width: `${position.width}px`
            }}
            className={styles.option_box}>
            <button
              type="button"
              data-value={''}
              onClick={onOptionSelect}
              className={styles.s_option}>
              {defaultSelectText}
            </button>
            {options.map((item) => {
              return (
                <button
                  style={optionStyle}
                  type="button"
                  data-value={item.value}
                  onClick={(e) => onOptionSelect(e, item)}
                  className={`${optionClass} ${styles.s_option}`}
                  key={item.value}>
                  {!!children ? children({ ...item }) : item.name}
                </button>
              );
            })}

            {isSearchable && totalPages !== currentPageNumber && (
              <div className={styles.loader}>
                <ActivityIndicator size="s" color="#007bff" />
              </div>
            )}
          </div>
        </InsertInDom>
      )}

      {loadingNextPage && !isActive && (
        <div className={styles.on_loader}>
          <ActivityIndicator size="s" color="#007bff" />
        </div>
      )}

      {!!icon && <Icon className={iconClass} name={icon} />}
    </div>
  );
};

export default AdvanceSelect;
