import React from 'react';
import Icon from '../../Icon';
import styles from './ChipsGroup.module.css';
const Chip = ({
  name,
  disabled,
  value,
  label,
  checked = false,
  ...restProps
}) => {
  return (
    <div className={styles.chip_cont}>
      <input
        className={styles.input_check}
        {...restProps}
        type="checkbox"
        id={label}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={!disabled ? label : ''}>
        <div
          className={`${styles.chip_box} ${
            checked ? styles.active_chip_box : ''
          } ${disabled ? styles.disabled : ''}
          ${!checked ? styles.hover_box : ''}
          `}>
          {checked && <Icon name="fas fa-check" />}
          <span
            style={{
              marginLeft: checked ? '5px' : '0px'
            }}
            className={styles.chip_label}>
            {label}
          </span>
        </div>
      </label>
    </div>
  );
};
export default Chip;
