import { CREATE_DOCUMENT, FETCH_DOCUMENT, CLEAR_DOCUMENT } from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState({}, true);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${CREATE_DOCUMENT}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${FETCH_DOCUMENT}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case CLEAR_DOCUMENT:
      return initialState;

    default:
      return store;
  }
};

export default view;
