import { colors } from './colors';
import { shadow } from './shadow';
import { text } from './text';
import { zIndex } from './zIndex';
import { dimensions } from './dimensions';

export const theme = {
  colors,
  shadow,
  text,
  zIndex,
  dimensions
};
