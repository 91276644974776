import styled from 'styled-components';

export const ListContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  align-self: stretch;
`;
