export const FETCH_INACTIVE_WORKSHOPS = 'FETCH_INACTIVE_WORKSHOPS';
export const FETCH_INACTIVE_WORKSHOP_REGISTERED_AUDIENCES =
  'FETCH_INACTIVE_WORKSHOP_REGISTERED_AUDIENCES';
export const FETCH_INACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES =
  'FETCH_INACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES';

export const FETCH_ACTIVE_WORKSHOPS = 'FETCH_ACTIVE_WORKSHOPS';
export const FETCH_ACTIVE_WORKSHOP_REGISTERED_AUDIENCES =
  'FETCH_ACTIVE_WORKSHOP_REGISTERED_AUDIENCES';
export const FETCH_ACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES =
  'FETCH_ACTIVE_WORKSHOP_UNREGISTERED_AUDIENCES';

export const SEARCH_REGISTERED_AUDIENCES = 'SEARCH_REGISTERED_AUDIENCES';
export const SEARCH_UNREGISTERED_AUDIENCES = 'SEARCH_UNREGISTERED_AUDIENCES';

export const TRIGGER_EMAIL_FOR_REGISTERED_PARTICIPANTS =
  'TRIGGER_EMAIL_FOR_REGISTERED_PARTICIPANTS';

export const TRIGGER_EMAIL_FOR_UNREGISTERED_PARTICIPANTS =
  'TRIGGER_EMAIL_FOR_UNREGISTERED_PARTICIPANTS';

export const SET_ACTIVE_SLIDER_STATUS = 'SET_ACTIVE_SLIDER_STATUS';
export const SET_INACTIVE_SLIDER_STATUS = 'SET_INACTIVE_SLIDER_STATUS';
export const SET_ACTIVE_CURRENT_SEMINAR_ID = 'SET_ACTIVE_CURRENT_SEMINAR_ID';
export const SET_INACTIVE_CURRENT_SEMINAR_ID =
  'SET_INACTIVE_CURRENT_SEMINAR_ID';
export const RESET_WORKSHOP_PARTICIPANTS_DATA =
  'RESET_WORKSHOP_PARTICIPANTS_DATA';

export const CREATE_WORKSHOP = 'CREATE_WORKSHOP';

export const SEARCH_SEMINAR_AUDIENCE = 'SEARCH_SEMINAR_AUDIENCE';
export const GET_ALL_WORKSHOP = 'GET_ALL_WORKSHOP';
export const UPDATE_ONCE_FETCHED = 'UPDATE_ONCE_FETCHED';
export const CREATE_WORKSHOP_PARTICIPANT = 'CREATE_WORKSHOP_PARTICIPANT';
export const CREATE_WORKSHOP_PARTICIPANT_CSV =
  'CREATE_WORKSHOP_PARTICIPANT_CSV';

export const WORKSHOP_AUDIENCE_CSV_DOWNLOAD = 'WORKSHOP_AUDIENCE_CSV_DOWNLOAD';

export const UPDATE_REGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS =
  'UPDATE_REGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS';
export const RESET_TO_REGISTERED_PARTICIPANT_DETAILS_PREV_DATA =
  'RESET_TO_REGISTERED_PARTICIPANT_DETAILS_PREV_DATA';
export const UPDATE_UNREGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS =
  'UPDATE_UNREGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS';
export const RESET_TO_UNREGISTERED_PARTICIPANT_DETAILS_PREV_DATA =
  'RESET_TO_UNREGISTERED_PARTICIPANT_DETAILS_PREV_DATA';
export const SEARCH_REGISTERED_PARTICIPANT_DETAILS =
  'SEARCH_REGISTERED_PARTICIPANT_DETAILS';
export const SEARCH_UNREGISTERED_PARTICIPANT_DETAILS =
  'SEARCH_UNREGISTERED_PARTICIPANT_DETAILS';
export const SEARCH_SEMINAR_DETAILS = ' SEARCH_SEMINAR_DETAILS';
export const UPDATE_ACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS =
  'UPDATE_ACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS';
export const RESET_TO_ACTIVE_SEMINAR_DETAILS_PREV_DATA =
  'RESET_TO_ACTIVE_SEMINAR_DETAILS_PREV_DATA';
export const UPDATE_INACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS =
  'UPDATE_INACTIVE_SEMINAR_DETAILS_SEARCH_PARAMS';
export const RESET_TO_INACTIVE_SEMINAR_DETAILS_PREV_DATA =
  'RESET_TO_INACTIVE_SEMINAR_DETAILS_PREV_DATA';
export const UPDATE_WORKSHOP_DETAILS_BY_SEARCH_PARAMS =
  'UPDATE_WORKSHOP_DETAILS_BY_SEARCH_PARAMS';
export const RESET_TO_WORKSHOP_DETAILS_PREV_DATA =
  'RESET_TO_WORKSHOP_DETAILS_PREV_DATA';
export const CREATE_ACTIVE_WORKSHOP_CERTIFICATE =
  'CREATE_ACTIVE_WORKSHOP_CERTIFICATE';
export const FETCH_REGISTERED_AUDIENCES = 'FETCH_REGISTERED_AUDIENCES';
export const FETCH_ALL_CERTIFICATE = 'FETCH_ALL_CERTIFICATE';
export const UPDATE_CERTIFICATION_ONCEFETCHED =
  'UPDATE_CERTIFICATION_ONCEFETCHED';

export const WORKSHOP_EDIT = 'WORKSHOP_EDIT';
export const SEND_CERTIFICATE_MAIL = 'SEND_CERTIFICATE_MAIL';
export const DELETE_CERTIFICATE = 'DELETE_CERTIFICATE';
export const SEND_MAIL_REGISTERED_PARTICIPANT =
  'SEND_MAIL_REGISTERED_PARTICIPANT';

export const CREATE_REGISTRATION_PAYMENT_RECIVAL =
  'CREATE_REGISTRATION_PAYMENT_RECIVAL';
