import {
  FETCH_WEBINAR_STUDENTS,
  CREATE_WEBINAR_STUDENT,
  UPDATE_WEBINAR_STUDENT,
  DELETE_WEBINAR_STUDENTS
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
  updateDataOnDelete
} from '../../Helper/reducer';

const initialState = createInitialStoreState({ count: 0 }, true);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_WEBINAR_STUDENTS}_SUCCESS`:
      const { meta } = payload;
      return createNewStoreOnGetData(
        payload,
        {
          ...store,
          count: meta?.totalCount || 0
        },
        true
      );
    case `${CREATE_WEBINAR_STUDENT}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${UPDATE_WEBINAR_STUDENT}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${DELETE_WEBINAR_STUDENTS}_SUCCESS`: {
      const { rowId } = payload;
      return updateDataOnDelete(store, rowId);
    }

    default:
      return store;
  }
};

export default view;
