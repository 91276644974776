import React, { useEffect, useMemo } from 'react';

import Icon from '../../Icon';
import InsertInDom from '../../InsertInDom';

import Week from './Week';
import Days from './Days';
import ActionRow from './ActionRow';
import Years from './Years';
import Months from './Months';
import { useDatePicker } from './useDatePicker';

import useWindowDimensions from 'hooks/useWindowDimensions';
import useChildDimensions from '../../../Hooks/useChildDimensions';

import { Views } from './constants';
import styles from './DatePicker.module.css';

const DatePicker = ({ ...otherprops }) => {
  const [
    setRef,
    buttonPosition,
    reCalculateChildPosition
  ] = useChildDimensions();

  const { dimesnions } = useWindowDimensions();

  const {
    dateText,
    onMonthChange,
    onDayChange,
    onYearChange,
    calcPrevSetOfYears,
    calcNextSetOfYears,
    calcPrevMonth,
    toggleYearAndDayView,
    calcNextMonth,
    currentView,
    pageYear,
    containerClass,
    inputClass,
    iconClass,
    icon,
    isActive,
    toggleDatePicker,
    currentDate,
    ...rest
  } = useDatePicker(otherprops);

  useEffect(() => {
    reCalculateChildPosition();
  }, [isActive]);

  /**
   * calculates the position of datePicker popup box according to the window dimensions
   */
  const containerPosition = useMemo(() => {
    if (!buttonPosition) {
      return {};
    }
    const height = 360;
    const { top, height: inputHeight, left } = buttonPosition;
    if (top + inputHeight + height > dimesnions.height) {
      return {
        top: `${top - height}px`,
        left
      };
    } else {
      return {
        top: `${top + inputHeight}px`,
        left
      };
    }
  }, [buttonPosition, dimesnions, isActive]);

  return (
    <div className={containerClass}>
      <button
        ref={setRef}
        {...rest}
        type="button"
        onClick={toggleDatePicker}
        className={`${inputClass} ${styles.datePicker}`}>
        {dateText}
      </button>

      {isActive && (
        <InsertInDom>
          <div onClick={toggleDatePicker} className={styles.overlay}>
            <div
              style={containerPosition}
              className={styles.picker_box}
              onClick={(e) => e.stopPropagation()}>
              <ActionRow
                toggleView={toggleYearAndDayView}
                onNextYear={calcNextSetOfYears}
                onPrevYear={calcPrevSetOfYears}
                onNextMonth={calcNextMonth}
                onPrevMonth={calcPrevMonth}
                isMonth={
                  currentView === Views['MONTH'] || currentView === Views['DAY']
                }
                currentMonth={currentDate.currentMonth}
                currentYear={currentDate.currentYear}
                pageStartYear={pageYear.pageCurrentYear}
                pageEndYear={pageYear.pageLastYear}
              />
              {currentView === Views['DAY'] && <Week />}

              {currentView === Views['DAY'] && (
                <Days
                  onDayChange={onDayChange}
                  year={currentDate.currentYear}
                  month={currentDate.currentMonth}
                  day={currentDate.currentDay}
                />
              )}

              {currentView === Views['MONTH'] && (
                <Months
                  onMonthChange={onMonthChange}
                  currentMonth={currentDate.currentMonth}
                />
              )}

              {currentView === Views['YEAR'] && (
                <Years
                  currentYear={currentDate.currentYear}
                  onYearChange={onYearChange}
                  startingYear={pageYear.pageCurrentYear}
                  endingYear={pageYear.pageLastYear}
                />
              )}
            </div>
          </div>
        </InsertInDom>
      )}

      {!!icon ? (
        <Icon className={iconClass} name={icon} />
      ) : (
        <Icon className={iconClass} name="far fa-calendar-alt" />
      )}
    </div>
  );
};

export default DatePicker;
