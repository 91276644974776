import {
  GET_COURSE_LEVEL,
  SET_COURSE_LEVEL,
  UPDATE_COURSE_LEVEL_COURSE,
} from "../../Actions";

const initialState = {
  dataIds: [],
  data: {},
  relation: {},
  currentCourse: "",
  allData: { "": {} },
  allDataIds: { "": [] },
  allRelations: { "": {} },
  paginationData: {},
};

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_COURSE_LEVEL}_SUCCESS`: {
      const { dataIds, data, relation, paginationData } = payload;

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        allData: { ...store.allData, [store.currentCourse]: data },
        allDataIds: { ...store.allDataIds, [store.currentCourse]: dataIds },
        allRelations: {
          ...store.allRelations,
          [store.currentCourse]: relation,
        },
      };
    }

    case SET_COURSE_LEVEL: {
      const { currentCourse } = payload;
      return {
        ...store,
        dataIds: store.allDataIds[currentCourse],
        data: store.allData[currentCourse],
        relation: store.allRelations[currentCourse],
      };
    }

    case UPDATE_COURSE_LEVEL_COURSE: {
      return {
        ...store,
        currentCourse: payload,
      };
    }

    default:
      return store;
  }
};

export default view;
