import { useEffect, useRef } from 'react';

const usePrevValue = ({ value }) => {
  const count = useRef(0);
  const prevValue = useRef(null);

  if (count.current === 0) {
    prevValue.current = value;
    count.current = count.current + 1;
  }

  useEffect(() => {
    prevValue.current = value;
  }, [value]);

  return prevValue.current;
};

export default usePrevValue;
