import { combineReducers } from 'redux';

import ActiveWorkshopListReducer from './active/list/view';
import ActiveWorkshopRegisteredParticipantsReducer from './active/participants/registered';
import ActiveWorkshopUnregisteredParticipantsReducer from './active/participants/unregistered';

import InactiveWorkshopListReducer from './inactive/list/view';
import InactiveWorkshopRegisteredParticipantsReducer from './inactive/participants/registered';
import InactiveWorkshopUnregisteredParticipantsReducer from './inactive/participants/unregistered';
import allWorkshopReducer from './searchBy/view';
import searchWorkshopReducer from './searchBy/search';
import allParticipantsReducer from './participants';
import RegisteredParticipantsReducer from './Certificate/RegisteredParticipants';
import CertificateReducer from './Certificate/View';

const workshop = combineReducers({
  searchBy: combineReducers({
    all: allWorkshopReducer,
    search: searchWorkshopReducer
  }),
  active: combineReducers({
    list: ActiveWorkshopListReducer,
    participants: combineReducers({
      registered: ActiveWorkshopRegisteredParticipantsReducer,
      unregistered: ActiveWorkshopUnregisteredParticipantsReducer
    })
  }),
  inactive: combineReducers({
    list: InactiveWorkshopListReducer,
    participants: combineReducers({
      registered: InactiveWorkshopRegisteredParticipantsReducer,
      unregistered: InactiveWorkshopUnregisteredParticipantsReducer
    })
  }),
  certificate: combineReducers({
    allCertificate: CertificateReducer,
    registeredParticipants: RegisteredParticipantsReducer
  }),
  allSearchParticipants: allParticipantsReducer
});

export default workshop;
