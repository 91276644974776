import {
  CREATE_EMPLOYER_COURSE,
  FETCH_EMPLOYER_COURSES,
  UPDATE_EMPLOYER_COURSE,
  DELETE_EMPLOYER_COURSE
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
  updateDataOnDelete
} from '../../Helper/reducer';

const initialState = createInitialStoreState(
  { totalRegistrations: null },
  true
);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_EMPLOYER_COURSES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${CREATE_EMPLOYER_COURSE}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }

    case `${UPDATE_EMPLOYER_COURSE}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${DELETE_EMPLOYER_COURSE}_SUCCESS`: {
      const { rowId } = payload;
      return updateDataOnDelete(store, rowId);
    }

    default:
      return store;
  }
};

export default view;
