export const colors = {
  content: {
    background: '#f0f3f6'
  },
  button: {
    primary: '#007bff',
    secondary: 'white'
  },
  text: {
    primary: '#c9dbe9'
  },
  alert: {
    success: '#28a745',
    error: '#dc3545',
    warning: '#ffc107',
    info: '#195f69'
  },
  label: '#6c757d',
  input: {
    primary: {
      borderColor: '#6c757d',
      textColor: '#495057'
    },
    secondary: {
      borderColor: '#e0e5e9',
      textColor: '#222a33'
    }
  },
  chip: {
    borderColor: '#3f6ad8',
    textColor: 'white',
    background: '#3f6ad8'
  },
  radio: {
    borderColor: '#dee2e6',
    activeBorderColor: '#3f6ad8'
  },
  checkBox: {
    borderColor: '#dee2e6',
    activeBorderColor: '#3f6ad8',
    overlay: '#3f6ad8',
    unChecked: {
      background: '#f3f3f3',
      border: '#d8d8d8'
    },
    checked: {
      background: '#3f6ad8',
      color: 'white'
    }
  },
  filePicker: {
    attachment: '#495057',
    text: '#3f6ad8'
  },
  drawer: {
    title: '#3f6ad8',
    tab: {
      activeBackground: '#e0f3ff',
      text: '#343a40',
      icon: '#dee2e6'
    },
    link: {
      background: '#e0f3ff',
      text: '#495057',
      activeText: '#3f6ad8'
    }
  },
  collapse: {
    text: '#dee2e6',
    border: '#e0f3ff'
  },
  fallback: {
    text: '#495057'
  },
  popover: {
    item: {
      background: '#1a2c64',
      title: '#1a2c64',
      text: '#343a40'
    },
    heading: {
      background: '#17a2b8'
    }
  },
  grid: {
    cell: {
      activeBorder: '#3f6ad8',
      text: '#535a60',
      activeBackground: '#f7f7f7',
      objectText: '#17a2b8'
    }
  },
  paginator: {
    button: '#3f6ad8'
  },
  legend: {
    border: '#6c757d'
  },
  lightPrimaryColor: '#16aaff',
  primaryColor: '#3f6ad8',
  secondaryColor: '#6c757d',
  successColor: '#28a745',
  errorColor: '#dc3545',
  lightBlueColor: '#f9fbfc',
  mediumBlueColor: '#e0f3ff',
  warningColor: '#ffc107',
  infoColor: '#17a2b8',
  lightGrayColor: '#f8f9fa',
  lightPurpleColor: '#f7f9fa',
  mediumPurpleColor: '#f0f3f6',
  mediumGrayColor: '#dee2e6',
  tableCellColor: '#f7f7f7',
  tableTextColor: '#535a60',
  darkGrayColor: '#495057',
  textColor: '#c9dbe9',
  lightBlackColor: '#343a40',
  darkBlueColor: '#1a2c64'
};
