export const CREATE_STUDENT = 'CREATE_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const FETCH_STUDENTS = 'FETCH_STUDENTS';
export const SEARCH_STUDENTS = 'SEARCH_STUDENTS';
export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS';
export const RESET_TO_PREV_DATA = 'RESET_TO_PREV_DATA';
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const CLEAR_SUBSCRIPTION = 'CLEAR_SUBSCRIPTION';
export const SEARCH_SUBSCRIPTIONS = 'SEARCH_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTIONS_SEARCH_PARAMS =
  'UPDATE_SUBSCRIPTIONS_SEARCH_PARAMS';
export const RESET_TO_SUBSCRIPTIONS_PREV_DATA =
  'RESET_TO_SUBSCRIPTIONS_PREV_DATA';
export const FETCH_SUBSCRIPTION_DETAIL = 'FETCH_SUBSCRIPTION_DETAIL';
export const FETCH_STUDENT_MEMORENDUM = 'FETCH_STUDENT_MEMORENDUM';
export const CREATE_STUDENT_MEMORENDUM = 'CREATE_STUDENT_MEMORENDUM';
export const EDIT_STUDENT_MEMORENDUM = 'EDIT_STUDENT_MEMORENDUM';
export const UPDATE_SUBSCRIPTION_ID = 'UPDATE_SUBSCRIPTION_ID';
export const CLEAR_SUBSCRIPTION_DETAIL = 'CLEAR_SUBSCRIPTION_DETAIL';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';
export const FETCH_ALL_SUBSCRIPTION_DETAIL = 'FETCH_ALL_SUBSCRIPTION_DETAIL';
export const CREATE_INTERVIEW = 'CREATE_INTERVIEW';
export const FETCH_INTERVIEW = 'FETCH_INTERVIEW';
export const CLEAR_INTERVIEW = 'CLEAR_INTERVIEW';
export const UPDATE_INTERVIEW = 'UPDATE_INTERVIEW';

export const INTERVIEW_QUESTIONS = 'INTERVIEW_QUESTIONS';
export const SEND_INTERVIEW_MAIL = 'SEND_INTERVIEW_MAIL';
export const SEND_INVIOCE_MAIL = 'SEND_INVIOCE_MAIL';
