import {
  CREATE_COMAPNY_MENTOR,
  FETCH_COMAPNY_MENTORS,
  UPDATE_COMAPNY_MENTOR,
  UPDATE_COMPANY_MENTOR_SEARCH_PARAMS,
  RESET_PREV_COMPANY_MENTORS_DATA,
  SEARCH_COMPANY_MENTORS
} from '../../Actions';

import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate
} from '../../Helper/reducer';

const initialState = {
  totalRegistrations: null,
  dataIds: [],
  data: {},
  relation: {},
  onceFetched: false,
  currentSearchByName: '',
  currentSearchByStatus: '',

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  },
  prevData: {},
  count: null
};

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_COMAPNY_MENTORS}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(payload, {
        ...store,
        count: meta?.totalCount || 0
      });
    }

    case `${CREATE_COMAPNY_MENTOR}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${UPDATE_COMAPNY_MENTOR}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${SEARCH_COMPANY_MENTORS}_SUCCESS`: {
      const { dataIds, data, relation, paginationData, meta } = payload;

      let prevData = {};
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store };
      } else {
        prevData = { ...store.prevData };
        prevData.prevData = {};
      }

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
        prevData,
        count: meta?.totalCount || 0
      };
    }

    case UPDATE_COMPANY_MENTOR_SEARCH_PARAMS: {
      const { searchByName, searchByStatus } = payload;

      return {
        ...store,
        currentSearchByName: searchByName,
        currentSearchByStatus: searchByStatus
      };
    }

    case RESET_PREV_COMPANY_MENTORS_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        currentSearchByName: '',
        currentSearchByStatus: ''
      };
    }

    default:
      return store;
  }
};

export default view;
