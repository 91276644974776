import { GET_EMAILS_WITH_PAGINATION } from '../../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../../Helper/reducer';

const initialState = createInitialStoreState({}, true);

const mails = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_EMAILS_WITH_PAGINATION}_SUCCESS`: {
      return createNewStoreOnGetData(payload, state, true);
    }

    default: {
      return state;
    }
  }
};

export default mails;
