import { CLEAR_SUBSCRIPTION, FETCH_SUBSCRIPTIONS } from '../../Actions/student';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';
const initialState = createInitialStoreState();

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_SUBSCRIPTIONS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case CLEAR_SUBSCRIPTION:
      return initialState;

    default:
      return store;
  }
};
export default view;
