import { FETCH_COURSES, UPDATE_COURSE, CREATE_COURSE } from '../../Actions';
import {
  createRelationIterator,
  getRelationData
} from '../../Helper/middleware';
import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnCreate
} from '../../Helper/reducer';

const initialState = createInitialStoreState({}, true);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_COURSES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${UPDATE_COURSE}_SUCCESS`: {
      const { data } = payload;
      const newData = { ...store.data };
      newData[data.id] = { ...data };
      return {
        ...store,
        data: newData
        // relation: newRelation,
      };
    }

    case `${CREATE_COURSE}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }

    default:
      return store;
  }
};

export default view;
