import { combineReducers } from 'redux';

import allRegistered from './registered';
import allUnregistered from './unregistered';

const allParticipantsReducer = combineReducers({
  allRegistered,
  allUnregistered
});

export default allParticipantsReducer;
