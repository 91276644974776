import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LOGOUT_USER } from '../Actions';
import {
  CHANNEL_PARTNER_DATA,
  TOKEN_LOCAL_NAME,
  USER_LOCAL_DATA
} from '../Constants';

const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const clearStorage = useCallback(() => {
    return new Promise((res, rej) => {
      window.sessionStorage.removeItem(TOKEN_LOCAL_NAME);
      window.sessionStorage.removeItem(USER_LOCAL_DATA);
      window.sessionStorage.removeItem(CHANNEL_PARTNER_DATA);

      setTimeout(() => {
        res();
      }, 500);
    });
  }, []);

  const logoutUser = useCallback(async () => {
    if (!window.confirm('Are you sure you want to logout?')) {
      return;
    }

    /**
     * The ordet should remain the  same as
     * 1. clear storage
     * 2. dispatch
     * 3. replace route
     *
     * if you try to replace route before logging out you will end up in     moving to the user create screen
     */

    await clearStorage();
    dispatch({
      type: LOGOUT_USER
    });

    history.replace('/');
  }, [clearStorage, dispatch, history]);

  return {
    logoutUser
  };
};

export default useLogout;
