import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo
} from 'react';

import useTransition from '../../Hooks/useTransition';

import Button from '../Button';
import Icon from '../Icon';
import InsertInDom from '../InsertInDom';

import styles from './Modal.module.css';

const Modal = ({ active, children, closeModal, heading }) => {
  const childRef = useRef(null);
  const [position, updatePosition] = useState({
    top: window.innerHeight / 2,
    left: window.innerWidth / 2
  });
  const { isVisible } = useTransition({ active, maxWait: 500 });

  const [childDimensions, updateChildDimensions] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const { width, height } = childDimensions;

    updatePosition({
      top: window.innerHeight / 2 - height / 2,
      left: window.innerWidth / 2 - width / 2
    });
  }, [childDimensions]);

  const onContentClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onChildRefSet = (ref) => {
    if (!ref) {
      return;
    }
    const { width, height } = ref.getBoundingClientRect();
    childRef.current = ref;
    updateChildDimensions({ width, height });
  };

  const newChildren = useMemo(() => {
    return React.cloneElement(children, { ref: onChildRefSet });
  }, [children]);

  return (
    isVisible && (
      <InsertInDom isModal={true}>
        <div className={styles.container}>
          <div
            onClick={onContentClick}
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
              animation: `${
                active ? styles.slideIn : styles.slideOut
              } 0.3s linear 0s  forwards`
            }}
            className={styles.content}>
            <div className={styles.heading}>
              <span>{heading}</span>
              <Button
                type="button"
                className={styles.close}
                size="auto"
                onClick={closeModal}>
                <Icon name="fas fa-times" />
              </Button>
            </div>
            {newChildren}
          </div>

          <div
            style={{
              animation: `${
                active ? styles.fadeIn : styles.fadeOut
              } 0.3s ease  forwards`
            }}
            className={styles.overlay}
          />
        </div>
      </InsertInDom>
    )
  );
};

export default Modal;
