import React from 'react';

import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
const supportRuote = Routes.authorized.support;

const supportTabLinks = Object.keys(supportRuote.routes).map(
  (key) => supportRuote.routes[key].path
);

const SupportLinkComponents = Object.keys(supportRuote.routes).map((key) => {
  const { path, drawerTitle, accessType } = supportRuote.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const mailRoute = Routes.authorized.mail;

const mailTabLinks = Object.keys(mailRoute.routes).map(
  (key) => mailRoute.routes[key].path
);

const MailLinkComponents = Object.keys(mailRoute.routes).map((key) => {
  const { path, drawerTitle, accessType } = mailRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const SupportTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={supportRuote.accessType}>
      <DrawerTitle title="Support" />
      <DrawerTab icon="fas fa-headset" title="Support" links={supportTabLinks}>
        {SupportLinkComponents}
      </DrawerTab>
      <DrawerTab icon="far fa-user" title="Mail" links={mailTabLinks}>
        {MailLinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default SupportTab;
