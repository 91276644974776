import React from 'react';
import useInput from '../useInput';

import Button from '../../Button';
import Icon from '../../Icon';

const ColorPicker = ({ onGivenKeyPressed = null, ...otherprops }) => {
  const {
    containerClass,
    inputClass,
    iconClass,
    icon,
    restInputProps,
    showClearButton,
    clearButtonClass,
    onClear
  } = useInput(otherprops);

  return (
    <div className={containerClass}>
      <input
        style={{
          display: 'flex',
          alignItems: 'stretch',
          padding: '5px'
        }}
        type="color"
        className={inputClass}
        {...restInputProps}
      />
      {!!icon && <Icon className={iconClass} name={icon} />}
      {showClearButton && (
        <Button
          secondary
          type="button"
          size="auto"
          className={clearButtonClass}
          onClick={onClear}>
          <Icon name="fas fa-times" />
        </Button>
      )}
    </div>
  );
};

export default ColorPicker;
