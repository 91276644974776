import { combineReducers } from 'redux';
import { LOGOUT_USER } from '../Actions';

import AuthReducer from './auth';
import AlertReducer from './alert';
import AppDataReducer from './appData';
import UserManagementViewReducer from './userManagement/view';
import SupportEnquiryReducer from './support/enquiry';
import SupportSyllabusReducer from './support/syllabus';
import CourseManagementViewReducer from './courseManagement/view';
import CourseLevelViewReducer from './courseLevel/view';
import LiveProjectsViewReducer from './liveProjects/view';
import StudentsViewReducer from './student/view';
import SubscribedStudentsViewReducer from './subscribedStudents/view';
import StudentSubscriptionViewReducer from './studentSubscription/view';
import StudentSubscriptionStudentReducer from './studentSubscription/students';
import CompanyMentorsViewReducer from './companyMentors/view';
import SubscriptionsViewReducer from './subscriptions/view';
import StudentSubscriptionDetailsReducer from './subscriptionDetails/view';
import EmployersViewReducer from './employer/view';
import EmployerRepresentativeReducer from './employer/employerRepresentative';
import EmployerCoursesViewReducer from './employerCourse/view';
import EmployerRemarksViewReducer from './employerRemarks/view';
import EmployerStudentsViewReducer from './employerStudents/view';
import EmployerStudentsStudentReducer from './employerStudents/students';
import EmployerJobDescriptionViewReducer from './employerJobDescription/view';
import SubscriptionViewReducer from './subscription/view';
import SubscriptionDetailReducer from './subscriptionDetail/view';
import MailReducer from './mail';
import WorkshopReducer from './workshop';
import batchViewReducer from './Batch/view';
import batchMentorReducer from './Batch/mentor';
import batchStudentReducer from './Batch/student';
import channelPartnersReducer from './channelPartners/view';
import channelPartnerRepresentativeReducer from './channelPartners/channelPartnerRepresentative';
import channelPartnerStudentsViewReducer from './channelPartners/channelPartnerStudentsView';
import channelPartnerStudentsReducer from './channelPartners/channelPartnerStudents';
import channelPartnerDocumentsReducer from './channelPartners/channelPartnerDocuments';
import batchAssignmentViewReducer from './Batch/assignment';
import batchClassDetailViewReducer from './Batch/classDetails';
import mentorBatchesViewReducer from './mentorBatches/view';
import studentDocumentViewReducer from './student/document';
import { attendanceReducer } from '../Pages/Batch/View/ClassDetails/redux/Attendance/reducer';
import SubscribedStudentDocumentsReducer from './SubscribedStudentDocuments/View';

import studentMemorendumReducer from './student/memorendum';
import subscribedStudentMemorendumReducer from './subscribedStudents/memorendum';
import studentInterviewReducer from './StudentInterview/index';
import batchsubScriptionsReducer from './Batch/subscriptionbycourse';
import batchCertificateReducer from './Batch/certificate';
import webinarReducer from './Webinar/index';
import callbackRequestReducer from './CallbackRequest/view';

const appReducer = combineReducers({
  auth: AuthReducer,
  alert: AlertReducer,
  appData: AppDataReducer,
  userManagementView: UserManagementViewReducer,
  supportEnquiry: SupportEnquiryReducer,
  supportSyllabus: SupportSyllabusReducer,
  courseManagementView: CourseManagementViewReducer,
  liveProjectsView: LiveProjectsViewReducer,
  courseLevelView: CourseLevelViewReducer,
  student: StudentsViewReducer,
  subscribedStudents: SubscribedStudentsViewReducer,
  studentSubscriptionView: StudentSubscriptionViewReducer,
  subscriptionStudents: StudentSubscriptionStudentReducer,
  subscriptions: SubscriptionsViewReducer,
  subscriptionDetails: StudentSubscriptionDetailsReducer,
  subscription: SubscriptionViewReducer,
  subscriptionDetail: SubscriptionDetailReducer,
  companyMentors: CompanyMentorsViewReducer,
  employerView: EmployersViewReducer,
  employerRepresentativeView: EmployerRepresentativeReducer,
  employerCourseView: EmployerCoursesViewReducer,
  employerRemarksView: EmployerRemarksViewReducer,
  employerStudentsView: EmployerStudentsViewReducer,
  employerStudents: EmployerStudentsStudentReducer,
  employerJobDescription: EmployerJobDescriptionViewReducer,
  mail: MailReducer,
  workshop: WorkshopReducer,
  batchManagementView: batchViewReducer,
  batchMentor: batchMentorReducer,
  batchStudent: batchStudentReducer,
  batchAssignment: batchAssignmentViewReducer,
  batchClassDetails: batchClassDetailViewReducer,
  channelPartners: channelPartnersReducer,
  channelPartnerRepresentatives: channelPartnerRepresentativeReducer,
  channelPartnerStudents: channelPartnerStudentsReducer,
  channelPartnerStudentsView: channelPartnerStudentsViewReducer,
  channelPartnerDocuments: channelPartnerDocumentsReducer,
  mentorBatches: mentorBatchesViewReducer,
  studentDocument: studentDocumentViewReducer,
  batchClassAttendance: attendanceReducer,
  subscribedStudentMemorendum: subscribedStudentMemorendumReducer,
  studentMemorendum: studentMemorendumReducer,
  subscribedStudentDocuments: SubscribedStudentDocumentsReducer,
  studentInterview: studentInterviewReducer,
  batchsubScriptions: batchsubScriptionsReducer,
  batchCertificate: batchCertificateReducer,
  webinar: webinarReducer,
  callbackRequestView: callbackRequestReducer
});

const rootReducer = (store, action) => {
  const { type } = action;
  let storeObj = appReducer(store, action);
  if (type === LOGOUT_USER) {
    storeObj = appReducer(undefined, '');
  }
  return storeObj;
};

export default rootReducer;
