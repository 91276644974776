import {
  GET_EMAILS,
  UPDATE_MAIL,
  CHANGE_EMAIL_CATEGORY,
  CHANGE_EDIT_EMAIL_CATEGORY,
  CREATE_MAIL
} from '../../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData
} from '../../../Helper/reducer';

const initialState = createInitialStoreState(
  {
    selectedEmailId: '',
    selectedEditEmailId: '',
    totalCount: null
  },
  true
);

const mail = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_EMAILS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, state, true);
    }

    case `${CREATE_MAIL}_SUCCESS`: {
      return createNewStoreOnCreate(payload, state);
    }

    case CHANGE_EMAIL_CATEGORY: {
      const { id } = payload;

      return {
        ...state,
        selectedEmailId: id
      };
    }

    case CHANGE_EDIT_EMAIL_CATEGORY: {
      const { id } = payload;

      return {
        ...state,
        selectedEditEmailId: id
      };
    }

    case `${UPDATE_MAIL}_SUCCESS`: {
      const { data } = payload;
      const newData = { ...state.data };
      newData[data.id] = { ...data };
      return {
        ...state,
        data: newData
      };
    }

    default: {
      return state;
    }
  }
};

export default mail;
