import React from 'react';
import { sendExceptionToSentry } from '../../Sentry';
import ChildFallback from './FallBack/ChildFallback';
import PageFallback from './FallBack/PageFallback';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }

  componentDidCatch(err) {
    sendExceptionToSentry(err);
  }

  render() {
    const { children, type = 'page' } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      switch (type) {
        case 'page':
          return <PageFallback />;
        case 'child':
          return <ChildFallback />;
        default:
          return <PageFallback />;
      }
    }

    return children;
  }
}
export default ErrorBoundary;
