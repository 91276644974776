export const CREATE_EMPLOYER = 'CREATE_EMPLOYER';
export const FETCH_EMPLOYERS = 'FETCH_EMPLOYERS';
export const FETCH_EMPLOYER_REPRESENTATIVES = 'FETCH_EMPLOYER_REPRESENTATIVES';
export const CLEAR_EMPLOYER_REPRESENTATIVES = 'CLEAR_EMPLOYER_REPRESENTATIVES';
export const CREATE_EMPLOYER_REPRESENTATIVE = 'CREATE_EMPLOYER_REPRESENTATIVE';
export const UPDATE_EMPLOYER_REPRESENTATIVE = ' UPDATE_EMPLOYER_REPRESENTATIVE';
export const DELETE_EMPLOYER_REPRESENTATIVES =
  'DELETE_EMPLOYER_REPRESENTATIVES';
export const CREATE_EMPLOYER_COURSE = 'CREATE_EMPLOYER_COURSE';
export const FETCH_EMPLOYER_COURSES = 'FETCH_EMPLOYER_COURSES';
export const CREATE_EMPLOYER_REMARK = 'CREATE_EMPLOYER_REMARK';
export const FETCH_EMPLOYER_REMARKS = 'FETCH_EMPLOYER_REMARKS';
export const CREATE_EMPLOYER_STUDENT = 'CREATE_EMPLOYER_STUDENT';
export const FETCH_EMPLOYER_STUDENTS = 'FETCH_EMPLOYER_STUDENTS';
export const UPDATE_EMPLOYER_STUDENT = 'UPDATE_EMPLOYER_STUDENT';
export const DELETE_EMPLOYER_STUDENTS = 'DELETE_EMPLOYER_STUDENTS';
export const CREATE_EMPLOYER_JOB_DESCRIPTION =
  'CREATE_EMPLOYER_JOB_DESCRIPTION';
export const FETCH_EMPLOYER_JOB_DESCRIPTION = 'FETCH_EMPLOYER_JOB_DESCRIPTION';
export const CLEAR_EMPLOYER_ID = 'CLEAR_EMPLOYER_ID';
export const SEARCH_EMPLOYER_DETAILS = 'SEARCH_EMPLOYER_DETAILS';
export const UPDATE_EMPLOYER_DETAILS_SEARCH_PARAMS =
  'UPDATE_EMPLOYER_DETAILS_SEARCH_PARAMS';
export const RESET_TO_EMPLOYER_DETAILS_PREV_DATA =
  'RESET_TO_EMPLOYER_DETAILS_PREV_DATA';
export const UPDATE_EMPLOYER_COURSE = 'UPDATE_EMPLOYER_COURSE';
export const DELETE_EMPLOYER_COURSE = 'DELETE_EMPLOYER_COURSE';

export const UPDATE_EMPLOYER_REMARK = 'UPDATE_EMPLOYER_REMARK';
export const DELETE_EMPLOYER_REMARK = 'DELETE_EMPLOYER_REMARK';
export const EDIT_EMPLOYER = ' EDIT_EMPLOYER';
export const UPDATE_EMPLOYER_JOB_DESCRIPTION =
  'UPDATE_EMPLOYER_JOB_DESCRIPTION';
export const DELETE_EMPLOYER_JOB_DESCRIPTION =
  'DELETE_EMPLOYER_JOB_DESCRIPTION';
