import {
  FETCH_ACTIVE_WORKSHOP_REGISTERED_AUDIENCES,
  RESET_WORKSHOP_PARTICIPANTS_DATA,
  CREATE_WORKSHOP_PARTICIPANT,
  RESET_TO_REGISTERED_PARTICIPANT_DETAILS_PREV_DATA,
  UPDATE_REGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS,
  SEARCH_REGISTERED_PARTICIPANT_DETAILS,
  CREATE_REGISTRATION_PAYMENT_RECIVAL
} from '../../../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData
} from '../../../../Helper/reducer';

const initialState = createInitialStoreState(
  {
    totalRegistrations: null,
    onceFetched: false,
    currentContactData: '',
    currentCollege: '',
    currentCity: '',
    currentBranch: '',
    currentState: '',
    count: 0
  },
  true
);

const registered = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_REGISTERED_PARTICIPANT_DETAILS}_SUCCESS`:
    case `${FETCH_ACTIVE_WORKSHOP_REGISTERED_AUDIENCES}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(
        payload,
        {
          ...store,
          count: meta?.totalCount || 0
        },
        true
      );
    }
    case RESET_WORKSHOP_PARTICIPANTS_DATA: {
      return {
        ...initialState
      };
    }

    case `${CREATE_REGISTRATION_PAYMENT_RECIVAL}_SUCCESS`:
    case `${CREATE_WORKSHOP_PARTICIPANT}_SUCCESS`: {
      return createNewStoreOnCreate(
        payload,
        {
          ...store,
          count: store.count + 1 || 0
        },
        false,
        true
      );
    }

    case UPDATE_REGISTERED_PARTICIPANT_DETAILS_SEARCH_PARAMS: {
      const { contactData, college, city, branch, state } = payload;

      return {
        ...store,
        onceFetched: false,
        currentContactData: contactData,
        currentCollege: college,
        currentCity: city,
        currentBranch: branch,
        currentState: state
      };
    }

    case RESET_TO_REGISTERED_PARTICIPANT_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentContactData: '',
        currentCollege: '',
        currentCity: '',
        currentBranch: '',
        currentState: ''
      };
    }

    default:
      return store;
  }
};

export default registered;
