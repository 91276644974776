import React from 'react';
import useLogout from '../../../Hooks/useLogout';
import Avatar from '../../Avatar';
import Button from '../../Button';

import Icon from '../../Icon';

import styles from './UserDropDown.module.css';

const InfoCard = ({ active, cardWidth, position, userInfo }) => {
  const { logoutUser } = useLogout();
  return (
    <div
      style={{
        top: position.y,
        left: position.x,
        width: `${cardWidth}px`
      }}
      className={`${styles.user_card} ${
        active ? styles.animateIn : styles.animateOut
      }`}>
      <div className={styles.userHead}>
        <div className={`${styles.user_image_box} rc`}>
          {userInfo.avatarUrl ? (
            <Avatar
              alt="user profile pic"
              className={styles.user_img_cont}
              avatarUrl={userInfo.avatarUrl}
              name={userInfo.name}
            />
          ) : (
            <Icon
              className={styles.user_profile_icon}
              name="fas fa-user-circle"
            />
          )}
        </div>
        <div className={styles.user_desc}>
          <span className={styles.user_name}>{userInfo.name}</span>
          <span className={styles.user_email}>{userInfo.email}</span>
        </div>
      </div>
      <Button
        size="small"
        className={styles.logout_button}
        onClick={logoutUser}>
        Logout
      </Button>
    </div>
  );
};

export default InfoCard;
