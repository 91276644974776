import React from 'react';

import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
const subscriptionDetailsRoute =
  Routes.authorized.studentSubscriptionDetailsManagement;

const SubscriptionDetailsTabLinks = Object.keys(
  subscriptionDetailsRoute.routes
).map((key) => subscriptionDetailsRoute.routes[key].path);

const SubscriptionDetailsLinkComponents = Object.keys(
  subscriptionDetailsRoute.routes
).map((key) => {
  const { path, drawerTitle, accessType } =
    subscriptionDetailsRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const PaymentDetailsTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={subscriptionDetailsRoute.accessType}>
      <DrawerTab
        icon="fas fa-user-graduate"
        title="Payment Details"
        links={SubscriptionDetailsTabLinks}>
        {SubscriptionDetailsLinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default PaymentDetailsTab;
