import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import OnlyWhenAuthenticated from '../Components/Auth/OnlyWhenAuthenticated';
import NotAuthenticated from '../Components/Auth/NotAuthenticated';

import MainRoute from './MainRoute';
import UnAuthorizedRoutes from './UnauthorizedRoutes';

import { isUserLoggedIn } from '../Selectors/auth';
import { useSelector } from 'react-redux';
import Routes from '../Constants/Routes';

const authorizedPathNames = {};
const unAuthorizedPathNames = {};

Object.keys(Routes.authorized).forEach((key) => {
  const tab = Routes.authorized[key];
  Object.keys(tab.routes).forEach((page) => {
    const item = tab.routes[page];
    authorizedPathNames[item.path] = true;
  });
});

Object.keys(Routes.unAuthorized).map((key) => {
  const page = Routes.unAuthorized[key];
  unAuthorizedPathNames[page.path] = true;
});

const RootRoute = () => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      if (unAuthorizedPathNames[pathname]) {
        history.replace(Routes.authorized.userManagement.routes.viewRoute.path);
      }
    } else {
      if (authorizedPathNames[pathname]) {
        history.replace('/');
      }
    }
  }, [history, isLoggedIn, pathname]);

  return (
    <>
      <NotAuthenticated>
        <UnAuthorizedRoutes />
      </NotAuthenticated>
      <OnlyWhenAuthenticated>
        <MainRoute />
      </OnlyWhenAuthenticated>
    </>
  );
};

export default RootRoute;
