import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import useAlert from './useAlert';
import { getTableName } from '../Helper/reducer';

const usePagination = ({ actionCreator, tableName }) => {
  const { showAlert } = useAlert();
  const pageData = useSelector(
    (store) => getTableName(store, tableName)?.paginationData
  );
  const dispatch = useDispatch();
  const [loadingNextPage, updateLaodingNext] = useState(false);
  const [loadingPrevPage, updateLaodingPrev] = useState(false);

  const [allPagesFetched, updateAllPagesFetched] = useState(false);

  const isEmpty = useMemo(() => {
    return !pageData || Object.keys(pageData).length === 0;
  }, [pageData]);

  useEffect(() => {
    if (!pageData) {
      return;
    }
    const { currentPage, totalPages } = pageData;
    if (currentPage === totalPages) {
      updateAllPagesFetched(true);
    }
  }, [pageData]);

  const getNextPage = useCallback(
    async ({
      newActionCreator = null,
      fetchPrevPage = false,
      pageNumber = false,
      extraParams = {},
      isFirstPage = false
    } = {}) => {
      const {
        hasNextPage,
        hasPrevPage,
        prevPageNumber,
        currentPage,
        nextPageNumber
      } = pageData;

      let res = '';

      const { pageActionCreator } = bindActionCreators(
        {
          pageActionCreator: newActionCreator || actionCreator
        },
        dispatch
      );
      try {
        if (pageNumber) {
          if (pageNumber === currentPage) {
            return;
          }
          let loader = '';
          if (pageNumber < currentPage) {
            loader = updateLaodingPrev;
          } else {
            loader = updateLaodingNext;
          }
          loader(true);
          res = await pageActionCreator({ pageNumber, ...extraParams });
          loader(false);
        } else if (isFirstPage) {
          updateLaodingNext(true);
          res = await pageActionCreator({
            pageNumber: 1,
            ...extraParams
          });
          updateLaodingNext(false);
        } else if (fetchPrevPage && hasPrevPage) {
          updateLaodingPrev(true);
          res = await pageActionCreator({
            pageNumber: prevPageNumber,
            ...extraParams
          });
          updateLaodingPrev(false);
        } else if (hasNextPage) {
          updateLaodingNext(true);
          res = await pageActionCreator({
            pageNumber: nextPageNumber !== -1 ? nextPageNumber : 1,
            ...extraParams
          });
          updateLaodingNext(false);
        }
      } catch (err) {
        showAlert({ message: err, type: 'error', position: 'right' });

        updateLaodingPrev(false);
        updateLaodingNext(false);
      }
    },
    [actionCreator, dispatch, pageData, showAlert]
  );

  return {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    isEmpty,
    currentPageSize: pageData ? pageData.currentPageSize : -1,
    currentPageNumber: pageData ? pageData.currentPage : -1,
    totalPages: pageData ? pageData.totalPages : -1
  };
};

export default usePagination;
