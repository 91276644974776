import { FETCH_STUDENTS } from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnGetData
} from '../../Helper/reducer';

const initialState = createInitialStoreState({}, true);

const students = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_STUDENTS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store, true);

    default:
      return store;
  }
};

export default students;
