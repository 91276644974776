import React, { useMemo } from 'react';
import { MonthIndex } from 'constants';

import styles from './DatePicker.module.css';
import Icon from '../../Icon';

const ActionRow = ({
  isMonth = true,
  currentYear,
  currentMonth,
  pageStartYear,
  pageEndYear,
  onNextYear,
  onNextMonth,
  onPrevMonth,
  onPrevYear,
  toggleView
}) => {
  const btnText = useMemo(() => {
    if (isMonth) {
      return `${MonthIndex[currentMonth]} ${currentYear}`;
    } else {
      return `${pageStartYear}-${pageEndYear}`;
    }
  }, [currentMonth, currentYear, isMonth, pageEndYear, pageStartYear]);

  return (
    <div className={styles.action_row}>
      <button type="button" onClick={toggleView} className={styles.action_btn}>
        {btnText}
      </button>

      <div className={styles.page_box}>
        <button
          type="button"
          onClick={isMonth ? onPrevMonth : onPrevYear}
          className={styles.page_icon}>
          <Icon name="fas fa-chevron-left" />
        </button>

        <button
          type="button"
          onClick={isMonth ? onNextMonth : onNextYear}
          className={styles.page_icon}>
          <Icon name="fas fa-chevron-right" />
        </button>
      </div>
    </div>
  );
};

export default ActionRow;
