import React from 'react';
import Icon from '../Icon';

import useClipText from 'hooks/useClipText';
import styles from './Chip.module.css';

const Chip = ({
  text,
  maxChar = 10,
  iconName = '',
  className,
  ...otherProps
}) => {
  const { clippedText } = useClipText({ text, maxChar });
  return (
    <div className={`${styles.container} ${className}`} {...otherProps}>
      {iconName ? <Icon name={iconName} /> : null}
      <span>{clippedText}</span>
    </div>
  );
};
export default Chip;
