import React from 'react';
import { createLimitsArray } from '../../../Helper';
import Button from '../../Button';
import { List } from './List';

import styles from './TimePicker.module.css';

const hours = createLimitsArray(12, 1);
const minutes = createLimitsArray(60);
const format = ['AM', 'PM'];

export const PickerModal = React.forwardRef(
  ({ isInBottom, onClick, currentTime, toggleTimePicker }, ref) => {
    return (
      <>
        <div onClick={toggleTimePicker} className={styles.overlay} />
        <div
          className={`${styles.picker_box} ${
            isInBottom ? styles.top : styles.bottom
          }`}
          ref={ref}>
          <div className={styles.list_box}>
            <List
              list={hours}
              onClick={onClick}
              type="hours"
              currentData={currentTime.currentHour}
            />
            <List
              list={minutes}
              onClick={onClick}
              type="minutes"
              currentData={currentTime.currentMinute}
            />
            <List
              list={format}
              onClick={onClick}
              type="meridian"
              currentData={currentTime.currentMeridian}
            />
          </div>
          <Button className={styles.btn} onClick={toggleTimePicker}>
            Ok
          </Button>
        </div>
      </>
    );
  }
);
