import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';
import SearchSelect from '../SearchSelect';
import { ListItem } from '../../List';

import { ListContainer } from './style';

const AsyncMultipleSearchSelect = ({
  onSearch,
  onEnd,
  options,
  isLoading,
  optionChildren,
  listContainerProps = {},
  onChange,
  value,
  ...restProps
}) => {
  const [searchText, updateSearchText] = useState('');
  const tempRef = useRef({});

  const onEndReached = useCallback(() => {
    onEnd && onEnd();
  }, [onEnd]);

  useEffect(() => {
    if (tempRef.current['shouldFetchData']) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearchTextChange = useCallback((e, shouldFetchData = true) => {
    const val = e.target.value;
    tempRef.current['shouldFetchData'] = shouldFetchData;
    updateSearchText(val);
  }, []);

  const emptySearchInput = useCallback(() => {
    updateSearchText('');
  });

  const deleteUsersFromSelectedList = (userId) => {
    let newValue = { ...value };
    delete newValue[userId];
    if (Object.keys(newValue).length === 0) {
      newValue = null;
    }
    onChange({ target: { value: newValue } });
  };

  const selectedItemList = useMemo(() => {
    const values = value ? Object.values(value) : [];
    return values.map((item) => {
      return (
        <ListItem
          key={item.id}
          data={item}
          showDeselectButton={true}
          onSelect={deleteUsersFromSelectedList}
        />
      );
    });
  }, [value]);

  return (
    <>
      <SearchSelect
        data={options}
        isLoading={isLoading}
        onSearch={onSearchTextChange}
        optionChildren={optionChildren}
        searchValue={searchText}
        emptySearchInput={emptySearchInput}
        onEnd={onEndReached}
        onChange={onChange}
        useMultiSelectGroup={true}
        value={value}
        {...restProps}
      />
      <ListContainer {...listContainerProps}>{selectedItemList}</ListContainer>
    </>
  );
};

export default AsyncMultipleSearchSelect;
