import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isUserLoggedIn } from '../Selectors/auth';
let persistedValue = {};

const usePersistValue = ({ id, shouldPersist = false }) => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  useEffect(() => {
    if (!isLoggedIn) {
      persistedValue = {};
    }
  }, [id, isLoggedIn]);

  const updatePersistedValue = useCallback(
    (value) => {
      if (shouldPersist) {
        persistedValue[id] = value;
      }
    },
    [id, shouldPersist]
  );

  return {
    persistedValue: shouldPersist ? persistedValue[id] || '' : '',
    updatePersistedValue
  };
};

export default usePersistValue;
