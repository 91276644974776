export const validateEmail = (emailId) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(emailId);
};

export const validatePassword = (pass = '') => {
  if (pass.length < 6) {
    return false;
  }
  return true;
};

export const validateStringLength = (value, length) => {
  if (value.length !== length) {
    return false;
  }
  return true;
};

export const validateMobile = (phoneNo) => {
  const re = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  return re.test(phoneNo);
};

export const validateLandline = (landlineNo) => {
  const re = /^\+?([\d]{3})?[-\s]?[\d]{3,4}[-\s]?[\d]{3,4}$/;
  return re.test(landlineNo);
};

export const validateMultipleLandline = (landlineNos) => {
  const Landlines = landlineNos.split(',').map((landline) => landline.trim());
  let valid = true;

  if (Landlines.length > 10) valid = false;
  else {
    Landlines.map((landline) => {
      if (landline === '' || !validateLandline(landline)) {
        valid = false;
      }
    });
  }
  return valid;
};

export const validateMultipleMobile = (phoneNos) => {
  const phones = phoneNos.split(',').map((phone) => phone.trim());
  let valid = true;

  if (phones.length > 10) valid = false;
  else {
    phones.map((phone) => {
      if (phone === '' || !validateMobile(phone)) {
        valid = false;
      }
    });
  }
  return valid;
};

export const validateUrl = (value) => {
  if (!value) {
    return false;
  }

  try {
    new URL(value.trim());
  } catch {
    return false;
  }

  return true;
};

export const validateMultipleUrl = (values) => {
  const urls = values.split(',').map((url) => url.trim());
  let valid = true;

  if (urls.length > 10) valid = false;
  else {
    urls.map((url) => {
      if (url === '' || !validateUrl(url)) {
        valid = false;
      }
    });
  }
  return valid;
};

export const validateNumber = (value) => {
  if (!value) {
    return false;
  }
  return !isNaN(value);
};

export const isHtml = (value = '') => {
  if (!value) {
    return false;
  }

  return value.includes('<html>');
};

export const validateMultipleEmail = (value = '') => {
  if (!value) {
    return false;
  }

  if (value.toLowerCase() === 'all') {
    return true;
  }

  const recipientsArr = value.split(',');

  for (let i = 0; i < recipientsArr.length; i++) {
    const email = recipientsArr[i].trim();

    if (!email || !validateEmail(email)) {
      return false;
    }
  }

  return true;
};

export const validateCsvFile = (value = []) => {
  if (value.length !== 1) {
    return false;
  }

  if (
    value[0] &&
    (value[0].type === 'text/csv' ||
      value[0].type === 'application/vnd.ms-excel')
  ) {
    return true;
  }

  return false;
};

export const compareDates = (
  date1 = new Date(),
  date2 = new Date(),
  operator = ''
) => {
  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);
  const timestamp2 = dateObj2?.getTime();
  const timestamp1 = dateObj1?.getTime();
  switch (operator) {
    case '<':
      return timestamp1 < timestamp2;
    case '>':
      return timestamp1 > timestamp2;
    case '>=':
      return timestamp1 >= timestamp2;
    case '<=':
      return timestamp1 <= timestamp2;
    case '==':
      return timestamp1 == timestamp2;
    default:
      throw new Error('Operator not provided.');
  }
};

export const validateColorCode = (value = '') => {
  const re = /^#([0-9A-F]{3}){1,2}$/;
  return re.test(value.toUpperCase());
};

export const isDateCombinationValid = (
  startDate,
  endDate,
  stDateName = 'Start',
  endDateName = 'End'
) => {
  let message;
  let isValid;
  if (startDate.length === 0) {
    message = `Please select ${stDateName} date first`;
    isValid = false;
  } else if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
    message = `${endDateName} Date should be greater than the ${stDateName} Date`;
    isValid = false;
  } else {
    message = '';
    isValid = true;
  }
  return { isValid, message };
};

export const validateCommaSeparatedPhoneNumbers = (value) => {
  if (value.indexOf(',') > 0) {
    const phoneNumber = value.split(',');
    let valid = true;
    for (let i = 0; i < phoneNumber.length; i++) {
      if (!validateMobile(phoneNumber[i])) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  if (!validateMobile(value)) {
    return false;
  }

  return true;
};

export const validateCommaSeparatedEmails = (value) => {
  if (value.indexOf(',') > 0) {
    const emails = value.split(',');
    let valid = true;
    for (let i = 0; i < emails.length; i++) {
      if (!validateEmail(emails[i])) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  if (!validateEmail(value)) {
    return false;
  }

  return true;
};
