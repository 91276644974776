export const FETCH_WEBINAR = 'FETCH_WEBINAR';
export const FETCH_WEBINAR_STUDENTS = 'FETCH_WEBINAR_STUDENTS';
export const FETCH_WEBINAR_CERTIFICATE = 'FETCH_WEBINAR_CERTIFICATE';
export const SEND_WEBINAR_CERTIFICATE_MAIL = 'SEND_WEBINAR_CERTIFICATE_MAIL';
export const CREATE_WEBINAR_CERTIFICATE = 'CREATE_WEBINAR_CERTIFICATE';
export const FETCH_WEBINAR_REGISTERED_STUDENTS =
  'FETCH_WEBINAR_REGISTERED_STUDENTS';
export const DELETE_WEBINAR_CERTIFICATE = 'DELETE_WEBINAR_CERTIFICATE';
export const CREATE_WEBINAR_STUDENT = 'CREATE_WEBINAR_STUDENT';
export const CREATE_WEBINAR_STUDENT_BY_CSV = 'CREATE_WEBINAR_STUDENT_BY_CSV';
export const CREATE_WEBINAR = 'CREATE_WEBINAR';
export const UPDATE_WEBINAR = 'UPDATE_WEBINAR';
export const DELETE_WEBINAR_STUDENTS = 'DELETE_WEBINAR_STUDENTS';
export const UPDATE_WEBINAR_STUDENT = 'UPDATE_WEBINAR_STUDENT';
export const WEBINAR_STUDENT_CSV_DOWNLOAD = 'WEBINAR_STUDENT_CSV_DOWNLOAD';
