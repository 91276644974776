import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchSelect from '../SearchSelect';
import { debounce } from 'helper';
import useAlert from 'hooks/useAlert';

import { transformRawDataToListData } from './utils';

const AsyncSearchSelect = ({
  api,
  data,
  optionChildren,
  extraDataKey = 'email',
  ...restProps
}) => {
  const [isLoading, updateLoading] = useState(false);
  const [searchText, updateSearchText] = useState('');
  const [currentListData, updateListData] = useState([...(data || [])]);
  const { showAlert } = useAlert();
  const tempRef = useRef({});

  let searchFunction = useRef(null);

  const fetchData = async (textToSearch) => {
    try {
      updateLoading(true);
      const response = await api({
        query: textToSearch
      });

      updateListData(transformRawDataToListData(response.data, extraDataKey));
    } catch (err) {
      showAlert({
        message: err.length > 0 ? err[0] : 'Something went wrong',
        type: 'error'
      });
    } finally {
      updateLoading(false);
    }
  };

  const onSearch = useCallback((e, shouldFetchData = true) => {
    const val = e.target.value;
    tempRef.current['shouldFetchData'] = shouldFetchData;
    updateSearchText(val);
  }, []);

  const emptySearchInput = useCallback(() => {
    updateSearchText('');
  });

  if (!searchFunction.current) {
    searchFunction.current = debounce(fetchData, 200);
  }

  useEffect(() => {
    if (searchText && tempRef.current['shouldFetchData']) {
      searchFunction.current(searchText);
    }
  }, [searchText]);

  return (
    <SearchSelect
      data={currentListData}
      isLoading={isLoading}
      onSearch={onSearch}
      optionChildren={optionChildren}
      searchValue={searchText}
      emptySearchInput={emptySearchInput}
      {...restProps}
    />
  );
};

export default AsyncSearchSelect;
