import React, { useCallback, useMemo, useRef, useState } from 'react';
import EmptyResult from './EmptyResult';

import ListItem from './ListItem';
import SearchBox from './SearchBox';
import { ListGroupContainer } from './style';

const ListGroup = React.forwardRef((props, ref) => {
  const {
    data = [],
    selectedData = {},
    onChange,
    isSearchable = false,
    isMultiple,
    isInBottom,
    onEnd
  } = props;
  const [searchText, updateSearchText] = useState('');

  const onScroll = useCallback(
    (e) => {
      const { scrollTop, scrollHeight } = e.target;
      const { height } = e.target.getBoundingClientRect();
      if (scrollTop + height === scrollHeight && typeof onEnd === 'function') {
        onEnd();
      }
    },
    [onEnd]
  );

  const onSearch = useCallback((e) => {
    updateSearchText(e.target.value);
  }, []);

  const list = useMemo(() => {
    let filteredData = null;
    if (searchText) {
      filteredData = data.filter((item) => {
        if (item.name && item.name.toLowerCase) {
          return (
            item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        }
        return false;
      });
    }
    const result = filteredData || data;
    return result.map((item) => {
      return (
        <ListItem
          key={item.value}
          value={item.value}
          text={item.name}
          showCheckBox={isMultiple}
          isChecked={selectedData[item.value]}
        />
      );
    });
  }, [data, selectedData, searchText, isMultiple]);

  return (
    <ListGroupContainer
      isInBottom={isInBottom}
      onScroll={onScroll}
      ref={ref}
      onClick={onChange}>
      {isSearchable && <SearchBox onChange={onSearch} />}
      {list.length > 0 ? list : <EmptyResult />}
    </ListGroupContainer>
  );
});

export default ListGroup;
