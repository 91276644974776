import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

const LoginPage = lazy(() => import('../Pages/Login'));
const Error404Page = lazy(() => import('../Pages/Error404'));
const ForgotPasswordPage = lazy(() => import('../Pages/FogotPassword'));
const ResetPasswordPage = lazy(() => import('../Pages/ResetPassword'));

const Fallback = () => <p>Loading Page...</p>;

const UnAuthorizedRoutes = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
        <Route exact path="/resetPassword" component={ResetPasswordPage} />
        <Route component={Error404Page} />
      </Switch>
    </Suspense>
  );
};

export default UnAuthorizedRoutes;
