export const transformRawDataToListData = (data, extraDataKey) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.map((item) => {
    return {
      id: item.id,
      image: item?.attributes?.avatarUrl || null,
      name: item?.attributes?.name || item?.attributes?.mentorName,
      extraData: item?.attributes[extraDataKey]
    };
  });
};
