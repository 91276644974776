import React, { useMemo } from 'react';

import ActivityIndicator from '../../ActivityIndicator';
import Chip from '../../Chip';
import Icon from '../../Icon';

import {
  PlaceholderContainer,
  CountContainer,
  LoadingContainer,
  DropIconContainer
} from './style';

const Palceholder = React.forwardRef(
  (
    {
      data = [],
      placeholder,
      maxItems = 5,
      onClick,
      isLoading = false,
      maxCharOnChip = 5,
      moveLeft = false,
      success,
      error,
      secondary = false
    },
    ref
  ) => {
    // rendering the selected data in list
    const list = useMemo(() => {
      const result = [];
      if (data.length === 0) {
        return result;
      }
      for (let i = 0; i < Math.min(data.length, maxItems); i++) {
        result.push(
          <Chip
            maxChar={maxCharOnChip}
            key={data[i].value}
            text={data[i].name}
          />
        );
      }
      return result;
    }, [data, maxItems, maxCharOnChip]);

    // checking if we need to show extra count
    const [isOverFlow, extraItems] = useMemo(() => {
      return [data.length > maxItems, data.length - maxItems];
    }, [data, maxItems]);

    return (
      <PlaceholderContainer
        ref={ref}
        secondary={secondary}
        success={success}
        error={error}
        onClick={onClick}>
        {placeholder && list.length === 0 ? placeholder : list}

        {isOverFlow && !isLoading && (
          <CountContainer moveLeft={moveLeft}>
            <span>+{extraItems}</span>
          </CountContainer>
        )}

        {isLoading && (
          <LoadingContainer moveLeft={moveLeft}>
            <ActivityIndicator size="s" color="#3f6ad8" />
          </LoadingContainer>
        )}

        {!isOverFlow && !isLoading && (
          <DropIconContainer moveLeft={moveLeft}>
            <Icon name="fas fa-sort-down" />
          </DropIconContainer>
        )}
      </PlaceholderContainer>
    );
  }
);
export default Palceholder;
