import React from 'react';

import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
const channelPartnerRoute = Routes.authorized.channelPartner;

const tabLinks = Object.keys(channelPartnerRoute.routes).map(
  (key) => channelPartnerRoute.routes[key].path
);

const LinkComponents = Object.keys(channelPartnerRoute.routes).map((key) => {
  const { path, drawerTitle, accessType } = channelPartnerRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const ChannelPartnersTab = () => {
  return (
    <OnlyWhenAuthorized requiredAccess={channelPartnerRoute.accessType}>
      <DrawerTab
        icon="fas fa-university"
        title="Channel Partner"
        links={tabLinks}>
        {LinkComponents}
      </DrawerTab>
    </OnlyWhenAuthorized>
  );
};

export default ChannelPartnersTab;
