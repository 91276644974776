import { CREATE_WEBINAR, FETCH_WEBINAR, UPDATE_WEBINAR } from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate
} from '../../Helper/reducer';

const initialState = createInitialStoreState({ count: 0 }, true);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_WEBINAR}_SUCCESS`:
      const { meta } = payload;
      return createNewStoreOnGetData(
        payload,
        {
          ...store,
          count: meta?.totalCount || 0
        },
        true
      );

    case `${CREATE_WEBINAR}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${UPDATE_WEBINAR}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    default:
      return store;
  }
};

export default view;
