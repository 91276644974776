import {
  CREATE_CHANNEL_PARTNER,
  GET_CHANNEL_PARTNER,
  SEARCH_CHANNEL_PARTNER,
  UPDATE_CHANNEL_PARTNER_SEARCH_PARAMS,
  UPDATE_CHANNEL_PARTNER,
  RESET_PREVIOUS_CHANNEL_PARTNER_DATA
} from '../../Actions/index';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate
} from '../../Helper/reducer';

const initialState = createInitialStoreState(
  {
    count: 0,
    currentSearchByName: '',
    currentSearchByStatus: '',
    currentSearchByFirmType: ''
  },
  true
);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_CHANNEL_PARTNER}_SUCCESS`:
    case `${GET_CHANNEL_PARTNER}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(
        payload,
        {
          ...store,
          count: meta?.totalCount || 0
        },
        true
      );
    }

    case UPDATE_CHANNEL_PARTNER_SEARCH_PARAMS: {
      const { searchByName, searchByStatus, searchByFirmType } = payload;

      return {
        ...store,
        onceFetched: false,
        currentSearchByName: searchByName,
        currentSearchByStatus: searchByStatus,
        currentSearchByFirmType: searchByFirmType
      };
    }

    case RESET_PREVIOUS_CHANNEL_PARTNER_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentSearchByFirmType: '',
        currentSearchByStatus: '',
        currentSearchByName: ''
      };
    }

    case `${UPDATE_CHANNEL_PARTNER}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case `${CREATE_CHANNEL_PARTNER}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);

    default:
      return store;
  }
};
export default view;
