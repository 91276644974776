import {
  CREATE_EMPLOYER,
  EDIT_EMPLOYER,
  FETCH_EMPLOYERS,
  RESET_TO_EMPLOYER_DETAILS_PREV_DATA,
  SEARCH_EMPLOYER_DETAILS,
  UPDATE_EMPLOYER_DETAILS_SEARCH_PARAMS
} from '../../Actions';
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate
} from '../../Helper/reducer';

const initialState = createInitialStoreState(
  {
    totalRegistrations: null,
    onceFetched: false,
    currentEmployerName: '',
    currentStatus: '',
    count: 0
  },
  true
);

const view = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_EMPLOYER_DETAILS}_SUCCESS`:
    case `${FETCH_EMPLOYERS}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(
        payload,
        { ...store, count: meta?.totalCount || 0 },
        true
      );
    }

    case `${CREATE_EMPLOYER}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }

    case `${EDIT_EMPLOYER}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case UPDATE_EMPLOYER_DETAILS_SEARCH_PARAMS: {
      const { employerName, status } = payload;

      return {
        ...store,
        onceFetched: false,
        currentEmployerName: employerName,
        currentStatus: status
      };
    }

    case RESET_TO_EMPLOYER_DETAILS_PREV_DATA: {
      return {
        ...store,
        onceFetched: false,
        currentEmployerName: '',
        currentStatus: ''
      };
    }

    default:
      return store;
  }
};

export default view;
