import {
  GET_BATCH_CLASS_DETAILS,
  RESET_BATCH_DETAILS
} from '../../Actions/index';
import {
  createNewStoreOnGetData,
  createInitialStoreState
} from '../../Helper/reducer';

const initialState = createInitialStoreState();

const viewClassDetails = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_BATCH_CLASS_DETAILS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case RESET_BATCH_DETAILS:
      return initialState;

    default:
      return store;
  }
};
export default viewClassDetails;
