import React from 'react';
import { useSelector } from 'react-redux';
import DrawerTab from '../DrawerTab';
import DrawerLink from '../DrawerLink';
import Routes from '../../../Constants/Routes';
import DrawerTitle from '../DrawerTitle';

import OnlyWhenAuthorized from '../../Auth/OnlyWhenAuthorized';
import { getAccessType } from '../../../Selectors/auth';

import UserManagementTab from './UserManagement';
import StudentTab from './Student';
import EmployerTab from './Employer';
import CompanyMentorsTab from './CompanyMentors';
import ChannelPartnersTab from './ChannelPartners';
import Subscription from './Subscription';
import PaymentDetails from './PaymentDetails';

const batchRoute = Routes.authorized.batch;

const batchTabLinks = Object.keys(batchRoute.routes).map(
  (key) => batchRoute.routes[key].path
);

const BatchLinkComponents = Object.keys(batchRoute.routes).map((key) => {
  const { path, drawerTitle, accessType } = batchRoute.routes[key];
  return (
    <DrawerLink
      key={path}
      path={path}
      title={drawerTitle}
      requiredAccess={accessType}
    />
  );
});

const BatchTab = () => {
  return (
    <>
      <DrawerTitle title="Management" />
      <UserManagementTab />
      <StudentTab />
      <Subscription />
      <CompanyMentorsTab />
      <OnlyWhenAuthorized requiredAccess={batchRoute.accessType}>
        <DrawerTab icon="fas fa-university" title="Batch" links={batchTabLinks}>
          {BatchLinkComponents}
        </DrawerTab>
      </OnlyWhenAuthorized>
      <EmployerTab />
      <ChannelPartnersTab />
      <PaymentDetails />
    </>
  );
};

export default BatchTab;
