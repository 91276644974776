import { MonthIndex } from '../Constants';

export const getDateDetails = (date) => {
  let dt;
  if (!date) {
    dt = new Date();
  } else {
    dt = new Date(date);
  }
  let todayDate = dt.getDate();
  todayDate = todayDate < 10 ? `0${todayDate}` : todayDate;
  const today = dt.getDay();
  let monthNumber = dt.getMonth();
  const month = MonthIndex[monthNumber];
  // increasing by one to relate to actual world values
  monthNumber++;
  monthNumber = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
  const year = dt.getFullYear();
  return {
    todayDate,
    today,
    month,
    monthIndex: monthNumber,
    year,
    ...getTimeDetails(date)
  };
};

export const getTimeDetails = (date) => {
  let dt;
  if (!date) {
    dt = new Date();
  } else {
    dt = new Date(date);
  }
  let hour = dt.getHours();
  hour = hour < 10 ? `0${hour}` : hour;

  let min = dt.getMinutes();
  min = min < 10 ? `0${min}` : min;

  let sec = dt.getSeconds();
  sec = sec < 10 ? `0${sec}` : sec;

  return {
    hour,
    min,
    sec
  };
};

export const extractDateInYYYYMMDD = (date) => {
  date = typeof date == 'string' && new Date(date);
  if (date != 'Invalid Date') {
    const { todayDate, monthIndex, year } = getDateDetails(date);
    return year + '-' + monthIndex + '-' + todayDate;
  } else {
    return '';
  }
};
